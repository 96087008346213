import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetTransaction: ["nameWrote", "referenceWrote", "stateSelected", "dateSelected", "typeSelected"],
  getTransactionSuccess: ["filterData"],
  submitGetTransactionDetail: ["e", "listTransactionStates"],
  getTransactionDetailSuccess: ["listTransactionDetail","referenceNumber"],
  submitChangeState: ["dispersion_id", "state", "description", "email"],
  changeStateSuccess: ["stateChangeMessage"],
  eraseMessage: [""],
  changeStateFailed: ["stateChangeError"],
  submitGetTransactionStates: ["currentState", "txType"],
  getTransactionStatesSuccess: ["statesData"],
  getDetailGmf: ["id","reference_number"]
});

export const TransactionTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data:"",
  listTransactionDetail:"",
  error:"",
  state:"",
  currentState: "",
  txType: "",
  filterData:"",
  stateChangeMessage:"",
  stateChangeError:"",
  statesData: "",
  referenceNumber:"",
  arrayError: [],
  arrayMsg: []
};

const submitGetTransaction = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getTransactionSuccess = (state, {filterData}) =>
  Object.assign({}, state, {
    isFetching: false,
    filterData
  });

const submitGetTransactionDetail = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getTransactionDetailSuccess = (state, { listTransactionDetail, referenceNumber }) =>
  Object.assign({}, state, {
    isFetching: false,
    listTransactionDetail,
    referenceNumber
  });

const submitChangeState = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const changeStateSuccess = (state, { stateChangeMessage }) => {
  state.arrayMsg.push(stateChangeMessage ? stateChangeMessage : null)
  return (Object.assign({}, state, {
    isFetching: false,
    stateChangeMessage
  }));
}

const changeStateFailed = (state,  { stateChangeError } ) =>{
  state.arrayError.push(stateChangeError?.message? stateChangeError.message: null)
  return (Object.assign({}, state, {
    isFetching: false,
    stateChangeError: (stateChangeError?.message? stateChangeError.message: null)
  }))
}
  
  const eraseMessage = state =>   
  Object.assign({}, state, {
    stateChangeMessage: "",
    stateChangeError: "",
    arrayError: [],
    arrayMsg: []
  });

  const submitGetTransactionStates = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getTransactionStatesSuccess = (state, {statesData}) =>
  Object.assign({}, state, {
    isFetching: false,
    statesData
  });
  
const getDetailGmf = state =>
  Object.assign({}, state, {
  isFetching: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_TRANSACTION]: submitGetTransaction,
  [Types.GET_TRANSACTION_SUCCESS]: getTransactionSuccess,
  [Types.SUBMIT_GET_TRANSACTION_DETAIL]: submitGetTransactionDetail,
  [Types.GET_TRANSACTION_DETAIL_SUCCESS]: getTransactionDetailSuccess,
  [Types.SUBMIT_CHANGE_STATE]: submitChangeState,
  [Types.CHANGE_STATE_SUCCESS]: changeStateSuccess,
  [Types.CHANGE_STATE_FAILED]: changeStateFailed,
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.SUBMIT_GET_TRANSACTION_STATES]: submitGetTransactionStates,
  [Types.GET_TRANSACTION_STATES_SUCCESS]: getTransactionStatesSuccess,
  [Types.GET_DETAIL_GMF]: getDetailGmf 
});
