const { Serializer } = require("jsonapi-serializer");

module.exports = {
  MulticashSerializer: (meta = {}) =>
    new Serializer("multicash", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["date"]
    })
};
