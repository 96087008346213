import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import CityActions, { CityTypes } from "../reducers/city.reducer";
import LoginActions from "../reducers/login.reducer";

export const citySubmit = action$ =>
  action$.pipe(
    ofType(CityTypes.SUBMIT_GET_CITIES),
    flatMap((action) => {
      const url = {
        tables: action.data.tables
      }
      if(action.data.departmentId){
        url.groupId = action.data.departmentId
      }
      if(action.data.countryId){
        url.groupId = action.data.countryId
      }
      return Observable.from(
        App.api.a2censo.getStaticParameters({
          url
        })
      ).pipe(
        flatMap((response) => {
          if(action.data.type === "companie"){
            return Observable.of(
              CityActions.getCompanieCitiesSuccess({response, departmentId: action.data.departmentId})
            );
          }else if(action.data.type === "legal"){
            return Observable.of(
              CityActions.getLegalCitiesSuccess({response, departmentId: action.data.departmentId})
            );
          }else if(action.data.type === "associated"){
            return Observable.of(
              CityActions.getAssociatedCitiesSuccess({response, departmentId: action.data.departmentId})
            );
          }else if(action.data.type === "department"){
            return Observable.of(
              CityActions.getAssociatedDepartmentsSuccess({response, countryId: action.data.countryId})
            );
          }else if (action.data.type === "departmentCodebtor") {
            return Observable.of(
              CityActions.getCodebtorDepartmentsSuccess({response, countryId: action.data.countryId})
            );
          } else if (action.data.type === "citiesCodebtor") {
            return Observable.of(
              CityActions.getCodebtorCitiesSuccess({response, departmentId: action.data.departmentId})
            );
          }
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(
                push('/opps')
              )
            );
          }
        })
      );
    })
  );
export default combineEpics(citySubmit);
