const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ChangeStateSerializer: (meta = {}) =>
    new Serializer("changeStateSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["email", "annotation_detail_state", "observation"]
    })
};
