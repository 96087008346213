import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetVinculationList: ["stateSelected"],
  getVinculationListSuccess: ["nameList"],
  submitGetFatcaByPyme: ["pymeId"],
  getFatcaByPymeSuccess: ["fatcaData"],
  submitGetSelfAssessmentData: ["campaignId", "req_campaign_id"],
  getSelfAssessmentDataSuccess: ["SelfAssessmentData"],
  submitGetRepAssociate: ["company_id"],
  getRepAssociateSuccess: ["repAssociateList"],
  submitGetInfoPyme: ["pymeId"],
  getInfoPymeSuccess: ["infoPymeData"],
  submitGetPymeTeam: ["pymeId"],
  getPymeTeamSuccess: ["pymeTeamData"],
  submitGetCompanyBankInfo: ["companyId"],
  getCompanyBankInfoSuccess: ["bankDataInfo"],
  submitGetStaticParameters: ["tables"],
  getStaticParametersSuccess: ["staticParameters"],
  submitSaveCompanyBankInfo: [
    "company_id",
    "bank_id",
    "bank_account_type_id",
    "account_number",
    "user_email",
    "user_ip"
  ],
  saveCompanyBankInfoSuccess: ["message"],
  eraseMessage: [""],
  submitGetLogsByPyme: ["company_id"],
  getLogsByPymeSuccess: ["tracingData"]
});

export const vinculationTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: "",
  nameList: "",
  fatcaData: "",
  SelfAssessmentData: "",
  repAssociateList: "",
  infoPymeData: "",
  pymeTeamData: "",
  bankDataInfo: "",
  staticParameters:"",
  message: "",
  error: "",
  tracingData: ""
};

const submitGetVinculationList = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getVinculationListSuccess = (state, { nameList }) =>
  Object.assign({}, state, {
    isFetching: false,
    nameList
  });

/**
 * traer informacion fatca
 */
const submitGetFatcaByPyme = state =>
  Object.assign({}, state, {
    isFetching: true
  });


/**
 * llegada datos fatca
 */
const getFatcaByPymeSuccess = (state, { fatcaData }) =>
  Object.assign({}, state, {
    isFetching: false,
    fatcaData

  });

const submitGetSelfAssessmentData = state =>
  Object.assign({}, state, {
    // isFetching: true
  });

const getSelfAssessmentDataSuccess = (state, { SelfAssessmentData }) =>
  Object.assign({}, state, {
    isFetching: false,
    SelfAssessmentData
  });
const submitGetRepAssociate = state =>
  Object.assign({}, state, {
  isFetching: true
});

const getRepAssociateSuccess = (state, {repAssociateList}) =>
  Object.assign({}, state, {
    isFetching: false,
    repAssociateList
});

/**
 * INICIA PETICION INFO PYME
 */
const submitGetInfoPyme = state =>
  Object.assign({}, state, {
  isFetching: true
});

/**
 * LLEGADA  DATA INFO PYME
 */
const getInfoPymeSuccess = (state, {infoPymeData}) =>
  Object.assign({}, state, {
    isFetching: false,
    infoPymeData
});

/**
 * INICIA PETICION TEAM PYME
 */
const submitGetPymeTeam = state =>
  Object.assign({}, state, {
  isFetching: true
});

/**
 * LLEGADA  DATA TEAM PYME
 */
const getPymeTeamSuccess = (state, {pymeTeamData}) =>
  Object.assign({}, state, {
    isFetching: false,
    pymeTeamData
});

const submitGetCompanyBankInfo = state =>
  Object.assign({}, state, {
  // isFetching: true
});

const getCompanyBankInfoSuccess = (state, {bankDataInfo}) =>
  Object.assign({}, state, {
    isFetching: false,
    bankDataInfo
});

const submitGetStaticParameters = state =>
  Object.assign({}, state, {
  // isFetching: true
});

const getStaticParametersSuccess = (state, {staticParameters}) =>
  Object.assign({}, state, {
    isFetching: false,
    staticParameters
});

const submitSaveCompanyBankInfo = state =>
  Object.assign({}, state, {
  isFetching: true
});

const saveCompanyBankInfoSuccess = (state, {message}) =>
  Object.assign({}, state, {
    isFetching: false,
    message
});

const eraseMessage = state =>
  Object.assign({}, state, {
    message: ""
});

const submitGetLogsByPyme = state =>
  Object.assign({}, state, {
  // isFetching: true
});

const getLogsByPymeSuccess = (state, {tracingData}) =>
  Object.assign({}, state, {
    isFetching: false,
    tracingData
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_VINCULATION_LIST]: submitGetVinculationList,
  [Types.GET_VINCULATION_LIST_SUCCESS]: getVinculationListSuccess,
  [Types.SUBMIT_GET_FATCA_BY_PYME]: submitGetFatcaByPyme,
  [Types.GET_FATCA_BY_PYME_SUCCESS]: getFatcaByPymeSuccess,
  [Types.SUBMIT_GET_SELF_ASSESSMENT_DATA]: submitGetSelfAssessmentData,
  [Types.GET_SELF_ASSESSMENT_DATA_SUCCESS]: getSelfAssessmentDataSuccess,
  [Types.SUBMIT_GET_REP_ASSOCIATE]: submitGetRepAssociate,
  [Types.GET_REP_ASSOCIATE_SUCCESS]: getRepAssociateSuccess,
  [Types.SUBMIT_GET_INFO_PYME]: submitGetInfoPyme,
  [Types.GET_INFO_PYME_SUCCESS]: getInfoPymeSuccess,
  [Types.SUBMIT_GET_PYME_TEAM]: submitGetPymeTeam,
  [Types.GET_PYME_TEAM_SUCCESS]: getPymeTeamSuccess,
  [Types.SUBMIT_GET_COMPANY_BANK_INFO]: submitGetCompanyBankInfo,
  [Types.GET_COMPANY_BANK_INFO_SUCCESS]: getCompanyBankInfoSuccess,
  [Types.SUBMIT_GET_STATIC_PARAMETERS]: submitGetStaticParameters,
  [Types.GET_STATIC_PARAMETERS_SUCCESS]: getStaticParametersSuccess,
  [Types.SUBMIT_SAVE_COMPANY_BANK_INFO]: submitSaveCompanyBankInfo,
  [Types.SAVE_COMPANY_BANK_INFO_SUCCESS]: saveCompanyBankInfoSuccess,
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.SUBMIT_GET_LOGS_BY_PYME]: submitGetLogsByPyme,
  [Types.GET_LOGS_BY_PYME_SUCCESS]: getLogsByPymeSuccess,
});
