/* eslint no-nested-ternary: 0 */
import React, { useState } from "react";
import "./Input.scss";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const shouldShowPassword = (showPassword, setShowPassword) => {
  if (showPassword) {
    return (
      <button
        id="show-password"
        type="button"
        className="input__show-password-button"
        onClick={() => setShowPassword(false)}
      >
        <img
          src="/assets/images/show.png"
          alt="show"
          className="input__show-password"
        />
      </button>
    );
  }

  return (
    <button
      id="hide-password"
      type="button"
      className="input__show-password-button"
      onClick={() => setShowPassword(true)}
    >
      <img
        src="/assets/images/hide.png"
        alt="hide"
        className="input__hide-password"
      />
    </button>
  );
};

const shouldIcon = (url) => {
  return (
    <div
      id="show-icon"
      className="input__show-icon"
    >
      <img
        src={url}
        alt="percentage"
        className="input__show-icon-img"
      />
    </div>
  );
};

const Input = props => {
  const { error, id, type, placeholder, urlIcon, classTooltip } = props;
  const isPassword = type === "password";
  const includeIcon = urlIcon?.length > 0;
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const renderTooltip = error;
  return (
    <div className="input">
     {(type === "file" || type === "date") && placeholder ? <label className="input_label">{placeholder}:</label>:null}
      <input
        className={`input__field ${
          isPassword || includeIcon ? "input__field-icon" : ""
        }`}
        onWheel={(e) => e.target.blur()}
        {...props}
        type={isPassword ? (showPassword ? "text" : "password") : type}
      />
      {includeIcon && shouldIcon(urlIcon)}
      {isPassword && shouldShowPassword(showPassword, setShowPassword)}
      {renderTooltip && (
        <>
        <div className={`tooltip_position ${classTooltip ? classTooltip : ""}`}>
          <div
            className="input__tooltip-logo-container"
            data-tip={true}
            data-for={`errorInfo-${id}`}
          >
            <img
              className="input__tooltip_logo"
              src="/assets/images/error.png"
              alt="error"
            />
          </div>

          <ReactTooltip
            className="error-tooltip__back"
            id={`errorInfo-${id}`}
            place="bottom"
            type="error"
            effect="solid"
          >
            <div className="error-tooltip__container">
              <p className="error-tooltip__text--title">{t(error)}</p>
            </div>
          </ReactTooltip>
        </div>
        </>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string
};

Input.defaultProps = {
  label: null,
  error: null,
  type: "text"
};

export default Input;
