import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import DispersionActions, { DispersionTypes } from "../reducers/dispersion.reducer";
import LoadDispersionActions, { LoadDispersionTypes } from "../reducers/dispersion-load.reducer";
import { EmailSerializer } from "../../lib/serializers/email.serializer";
import LoginActions from "../reducers/login.reducer";

export const startDispersionShowcase = () => ({
  type: "SUBMIT_GET_DISPERSION_STATE"
});

export const dispersionShowcase = action$ =>
  action$.pipe(
      ofType("START_DISPERSION_SHOWCASE"),
      flatMap(() => {
          const url = { dateSelected: "", nameSelected: "", stateSelected: ""};
          return Observable.from(App.api.a2censo.getDispersionList({ url })
          ).pipe(
              flatMap((response) => {
                  return Observable.of(
                      push(`/dispersion`, {response})
                  )
              }),
              catchError((e) => {
                if(e.statusCode === 401){   
                  return Observable.of(LoginActions.submitLogout())
                }else {
                  return Observable.of(
                    push(`/oops`)
                  )
                }
              })
          );
      })
  );

export const getDispersion = action$ =>
action$.pipe(
    ofType("SUBMIT_GET_DISPERSION"),
    flatMap((action) => {
        const url = { dateSelected: action.dateSelected?action.dateSelected:"", 
        nameSelected: action.nameSelected?action.nameSelected:"", 
        stateSelected: action.stateSelected?action.stateSelected:""};
        //console.log(url);
        return Observable.from(App.api.a2censo.getDispersionList({ url })
        ).pipe(
            flatMap((response) => {
                //console.log("response: ", response)
                return Observable.of(
                  DispersionActions.getDispersionSuccess({ response })
                )
            }),
            catchError((e) => {
              if(e.statusCode === 401){   
                return Observable.of(LoginActions.submitLogout())
              }else {
                return Observable.of(
                  push(`/oops`)
                )
              }
            })
        );
    })
);

  export const generatePabSubmit = action$ =>
  action$.pipe(
    ofType(DispersionTypes.SUBMIT_GENERATE_PAB),
    flatMap(({ data: { email } }) => {
      return Observable.from(
        App.api.a2censo.getPab({
          body: EmailSerializer().serialize({ email })
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            DispersionActions.generatePabSuccess(response),
            push(`dispersion`)
          );
        }),
        catchError(response => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  DispersionActions.generatePabFail(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const dispersionLoadSubmit = action$ =>
  action$.pipe(
    ofType(LoadDispersionTypes.SUBMIT_LOAD_DISPERSION),
    flatMap(({ data: { files, email } }) => {
        const json = JSON.stringify({email: email});
        const blob = new Blob([json], {type: 'application/json'});
        files.set('user', blob)
        return Observable.from(
            App.api.a2censo.dispersionLoad({
            body: files
        }))
        .pipe(
            flatMap((response) => {
                return Observable.of(
                  LoadDispersionActions.registerLoadSuccess(response.message),
                  push(`dispersion`)
                );
            }),
            catchError(response => {
                if(response.data){
                    return Observable.concat(
                        Observable.of(
                          LoadDispersionActions.registerLoadFail(response.data.attributes.message)
                        )
                    );
                }else if(response.statusCode === 401){   
                  return Observable.of(LoginActions.submitLogout())
                }else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
  );


  export const getDispersionHist = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_DISPERSION_HIST"),
    flatMap(action => {
      const url = {id: action.dispHistId};
      return Observable.from(
        App.api.a2censo.getDispersionHist({ url })
      ).pipe(
        flatMap((dispHistData) => {
          return Observable.of(
            DispersionActions.getDispersionHistSuccess(dispHistData),
            push(`/dispersion-hist`)
          )
        }),
        catchError((response) => {
          if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

export const getDispersionState = action$ =>
action$.pipe(
    ofType("SUBMIT_GET_DISPERSION_STATE"),
    flatMap((action) => {
        return Observable.from(App.api.a2censo.getDispersionStates({})
        ).pipe(
            flatMap((DispersionStates) => {
                //console.log(DispersionStates);
                return Observable.of(
                  push(`/dispersion`, {DispersionStates})
                )
            }),
            catchError((e) => {
              if(e.statusCode === 401){   
                return Observable.of(LoginActions.submitLogout())
              }else {
                return Observable.of(
                  push(`/oops`)
                )
              }
            })
        );
    })
);
export default combineEpics(dispersionShowcase,getDispersion,generatePabSubmit,dispersionLoadSubmit, 
  getDispersionHist, getDispersionState);
