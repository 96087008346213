const { Serializer } = require("jsonapi-serializer");

module.exports = {
  resultInterviewSerializer: (meta = {}) =>
    new Serializer("resultInterviewSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["request_id", "action", "result", "comments", "has_codeptor", "user_email", "user_ip"]
    })
};
