const { Serializer } = require("jsonapi-serializer");

module.exports = {
  CompanySerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "document_type",
        "document_number",
        "business_name",
        "constitution_date",
        "city_id",
        "address",
        "phone",
        "ciiu",
        "second_ciiu",
        "company_code",
        "bank_id",
        "bank_account_type_id",
        "account_number",
        "amount",
        "description",
        "user_id",
        "files",
        "city_retention",
        "city_consignment",
        "user_login_ip",
        "email_login",
        "quality_tax"
      ]
    })
};
