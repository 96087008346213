import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    submitGetCertificatesByPyme: ["pyme_id"],
    getCertificatesByPymeSuccess: ["pymeData"],
    updateCertificateByPyme: ["company_id", "withholdingCity", "consignmentCity", "companyCity", "pymeAddress", "observation", "email", "user_ip"],
    updateCertificateByPymeSuccess: ["message"],
    certificatesShowcaseSuccess: ["pymeData"]
});

export const AnnotationByFilesTypes = Types;
export default Creators;

export const INITIAL_STATE = {
    isFetching: "",
    pymeData: "",
    message: ""
};

const submitGetCertificatesByPyme = state =>
    Object.assign({}, state, {
        isFetching: false
    });

const getCertificatesByPymeSuccess = (state, { pymeData }) =>
    Object.assign({}, state, {
        isFetching: false,
        pymeData
    });

const updateCertificateByPyme = (state) =>
    Object.assign({}, state, {
        isFetching: true
    });

const updateCertificateByPymeSuccess = (state, { message }) =>
    Object.assign({}, state, {
        isFetching: false,
        message
    });

const certificatesShowcaseSuccess = (state) =>
    Object.assign({}, state, {
        isFetching: false,
        pymeData: ""
    });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SUBMIT_GET_CERTIFICATES_BY_PYME]: submitGetCertificatesByPyme,
    [Types.GET_CERTIFICATES_BY_PYME_SUCCESS]: getCertificatesByPymeSuccess,
    [Types.UPDATE_CERTIFICATE_BY_PYME]: updateCertificateByPyme,
    [Types.UPDATE_CERTIFICATE_BY_PYME_SUCCESS]: updateCertificateByPymeSuccess,
    [Types.CERTIFICATES_SHOWCASE_SUCCESS]: certificatesShowcaseSuccess
});