const { Serializer } = require("jsonapi-serializer");

module.exports = {
  financialCondTermsSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "campaign_id",
        "requested_amount",
        "funding_rate",
        "term_in_months",
        "capital_payment_peridicity",
        "capital_payment_peridicity_text",
        "capital_grace_period",
        "capital_grace_period_text",
        "interest_payment_periodicity",
        "interest_payment_periodicity_text",
        "interest_grace_period",
        "interest_grace_period_text",
        "comments",
        "pyme_name",
        "change_request",
        "pyme_email",
        "fngWarrantyLine",
        "fngFeePercentage",
        "fngCoveragePercentage",
        "user_email",
        "user_ip"
      ]
    })
};
