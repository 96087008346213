import { combineEpics } from "redux-observable";
import campaign from "./campaign.js";
import register from "./register.js";
import campaignDetail from "./campaign-detail.js"
import login from "./login.js";
import company from "./company.js";
import companyAssociate from "./company-associate.js";
import companyLegalRepresentative from "./company-legal-representative.js";
import multicash from "./multicash.js";
import file from "./file.js";
import dispersion from "./dispersion";
import parameters from "./parameters";
import city from "./city";
import users from "./users"
import annotation from "./annotation";
import annotationByFile from "./annotationByFile";
import patrimonial from "./patrimonial";
import awardCampaign from "./award-campaign";
import payment from "./payment";
import transaction from "./transaction";
import pymesCampaign from "./pymesCampaign";
import alertManagement from "./alertManagement";
import vinculation from "./vinculation";
import docManagement from "./docManagement";
import responseManagement from "./responseManagement";
import certificates from "./certificates";
import investors from "./investors";
import news from "./news";
import parametersFng from "./parametersFNG.js";
import acc from "./integration/acc.js";
import codebtor from "./codebtor.js";

export default combineEpics(
  companyLegalRepresentative,
  companyAssociate,
  company,
  campaign,
  register,
  campaignDetail,
  login,
  multicash,
  file,
  dispersion,
  parameters,
  city,
  users,
  annotation,
  annotationByFile,
  patrimonial,
  awardCampaign,
  payment,
  transaction,
  pymesCampaign,
  alertManagement,
  vinculation,
  docManagement,
  responseManagement,
  certificates,
  investors,
  news,
  parametersFng,
  acc,
  codebtor
);
