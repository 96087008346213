import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import AnnotationByFileActions from "../reducers/annotationByFile.reducer"
import { forkJoin } from "rxjs";


export const getAnnotationByFile = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ANNOTATION_BY_FILE"),
    flatMap(action => {
      const url = {
        name: action.nameWrote,
        date_gen: action.dateGenSelected,
        date_upload: action.dateUploadSelected
      };
      let request;
      request = forkJoin([
        Observable.from(App.api.a2censo.getAnnotationByFile({ url })),
      ])
      return request.pipe(
          flatMap((annotationByFileList) => {
              return Observable.of(
                AnnotationByFileActions.getAnnotationByFileSuccess(annotationByFileList)
              )
          }),
          catchError((e) => {
            if(e.statusCode === 401){   
              return Observable.of(LoginActions.submitLogout())
            }else {
              return Observable.of(
                push(`/oops`)
              )
            }
          })
      );
    })
  );

  export const getAnnotationsDetail = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ANNOTATION_BY_FILE_DETAIL"),
    flatMap(action => {
      const url = {id: action.data};
      return Observable.from(
        App.api.a2censo.getAnnotationByFileDetail({ url })
      ).pipe(
        flatMap((items) => {
          return Observable.of(
            AnnotationByFileActions.getAnnotationByFileDetailSuccess(items),
            push(`/annotation-by-file-detail`)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AnnotationByFileActions.getAnnotationDetailFail(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

export default combineEpics(getAnnotationByFile, getAnnotationsDetail);
