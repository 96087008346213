const { Serializer } = require("jsonapi-serializer");

module.exports = {
    investorsSerializer: (meta = {}) =>
        new Serializer("investorsSerializer", {
            meta,
            pluralizeType: false,
            keyForAttribute: "snake_case",
            attributes: ["file", "file_name", "investor_id", "deposit_account", "city_id", "city_name", "observation", "user_email", "user_ip"]
        })
};