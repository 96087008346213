import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    startFormParameter: ["data"],
    updateParameterLoadFng: ["parameter"],
    parameterUpdateSuccess: ["response"],
    parameterUpdateFail: ["error"],
    eraseParameterData: [],
    submitUpdateFNGById: [
      "id",
      "name",
      "coverage_percentage",
      "coverage_description",
      "value",
      "type",
      "is_active",
      "user_email",
      "user_ip"
    ],
    submitCreateFNG: [
      "name",
      "coverage_percentage",
      "coverage_description",
      "value",
      "type",
      "is_active",
      "user_email",
      "user_ip"
    ],
  });
  
export const ParameterFNGLoadTypes = Types;
export default Creators;


export const INITIAL_STATE = {
    isFetching: false,
    messasge: "",
    error: "",
    formData: {
        id: "",
        name: "",
        coverage_percentage: 0,
        coverage_description: "",
        value: 0,
        type: 1,
        is_active: 1
      },
  };

const startFormParameter = state =>
  Object.assign({}, state, {
    ...INITIAL_STATE
  });

  const updateParameterLoadFng = (state, {parameter}) => 
  Object.assign({}, state, {
    parameter: parameter
  });

const parameterUpdateSuccess = (state, {response: message}) =>
  Object.assign({}, state, {
    isFetching: true,
    message: message,
    error: ""
  });

const parameterUpdateFail = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    message: ""
  });

const eraseParameterData = state =>
  Object.assign({}, state, {
    error: "",
    message: ""
  });

  const submitUpdateFNGById = state =>
  Object.assign({}, state, {
    isFetching: true
  });
  
  
export const reducer = createReducer(INITIAL_STATE, {
    [Types.START_FORM_PARAMETER]: startFormParameter,
    [Types.UPDATE_PARAMETER_LOAD_FNG]: updateParameterLoadFng,
    [Types.PARAMETER_UPDATE_SUCCESS]: parameterUpdateSuccess,
    [Types.PARAMETER_UPDATE_FAIL]: parameterUpdateFail,
    [Types.ERASE_PARAMETER_DATA]: eraseParameterData,
    [Types.SUBMIT_UPDATE_FNG_BY_ID]: submitUpdateFNGById
  });