import React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer__container container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer__items description">
              {t("footer.termAndConditions")}
            </div>
            <div className="footer__items description">
              {t("footer.dataProcessingPolicy")}
            </div>
            <div className="footer__items description">
              {t("footer.frequencyQuestions")}
            </div>
            <div className="footer__items description">
              {t("footer.resources")}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer__title small-text d-lg-none">
              {t("footer.contact")}
              {":"}
            </div>
            <div className="footer__items description">
              {t("footer.address")}
            </div>
            <div className="footer__items description">
              <a className="footer__items description" href="tel:+571`3139000">
                {t("footer.phone")}
              </a>
            </div>
            <div className="footer__items description">
              <a
                className="footer__items description"
                href="mailto:test@test.com"
              >
                {t("footer.email")}
              </a>
            </div>
            <div className="footer__items description">
              <a
                className="footer__items description"
                href="https://www.facebook.com/bvccolombia/"
              >
                <img
                  className="footer__social"
                  src="/assets/images/facebook.svg"
                  alt="facebook"
                />
              </a>
              <a
                className="footer__items description"
                href="https://twitter.com/bvcColombia?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              >
                <img
                  className="footer__social"
                  src="/assets/images/twitter.svg"
                  alt="twitter"
                />
              </a>
              <a
                className="footer__items description"
                href="https://www.linkedin.com/company/bvccolombia/?originalSubdomain=co"
              >
                <img
                  className="footer__social"
                  src="/assets/images/linkedin.svg"
                  alt="linkedin"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer__title small-text">
              {t("footer.aProduct")}
              {":"}
            </div>
            <div className="footer__items">
              <img
                className="footer__bvc footer__img-footer"
                src="/assets/images/bvc.png"
                alt="bvc"
              />
            </div>
          </div>
          <div className="footer__help col-lg-3 col-md-12 col-sm-12">
            <div className="footer__title small-text">
              {t("footer.support")}
              {":"}
            </div>
            <div className="row col-lg-12 footer__support__bid-lab-container ">
              <img
                className="footer__support--bid footer__img-footer col-lg-6 col-md-4 col-6"
                src="/assets/images/bid.png"
                alt="bvc"
              />
              <img
                className="footer__support--lab footer__img-footer col-lg-5 col-md-4 col-6"
                src="/assets/images/lab.png"
                alt="bvc"
              />
            </div>
            <img
              className="footer__support footer__img-footer col-lg-10 col-md-8 col-6"
              src="/assets/images/mincomercio.png"
              alt="bvc"
            />
            <img
              className="footer__support footer__img-footer col-lg-8 col-md-8 col-6"
              src="/assets/images/innpulsa.png"
              alt="bvc"
            />
          </div>
        </div>
      </div>
      <div className="footer__grability">
        <div className="footer__title--grability small-text">
          {t("poweredBy")}
          {":"}
        </div>
        <img className="" src="/assets/images/bvc.png" alt="bvc" />
      </div>
    </div>
  );
};

export default Footer;
