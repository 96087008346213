import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setModalState: ["modalState"],
  setIdentityValidationErrorModalState: ["identityValidationError"]
});

export const ServiceTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  modalState: false,
  identityValidationError: false
};

const setModalState = (state, { modalState }) =>
  Object.assign({}, state, {
    modalState
  });
const setIdentityValidationErrorModalState = (
  state,
  { identityValidationError }
) =>
  Object.assign({}, state, {
    identityValidationError
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_MODAL_STATE]: setModalState,
  [Types.SET_IDENTITY_VALIDATION_ERROR_MODAL_STATE]: setIdentityValidationErrorModalState
});
