import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetCampaign: ["dateSelected", "stateSelected"],
  getCampaignSuccess: ["data"],
  submitGetCampaignRequest: ["campaignId"],
  getCampaignRequestSuccess: ["campaignRequestData"],
  submitGetDocsByStage: ["campaignId", "stage_id"],
  getDocsByStageSuccess: ["documentsData", "additionalDocsList"],
  submitDocumentChangeState: ["document_id", "action", "comments", "user_email", "user_ip"],
  documentChangeStateSuccess: ["message"],
  submitGetAdditionalDocs: ["stage_id"],
  getAdditionalDocsSuccess: ["additionalDocsList"],
  submitGetInterviewValidation: ["start_date", "end_date"],
  getInterviewValidationSuccess: ["interviewAvailable"],
  submitGetInterviewData: ["req_campaign_id"],
  getInterviewDataSuccess: ["interviewData", "req_campaign_id"],
  submitScheduleInterview: ["start_date", "end_date", "virtual", "place", "user_email", "user_ip", "req_campaign_id"],
  scheduleInterviewSuccess: ["message"],
  submitUpdateOrAddDocs: ["action_req", "stage", "request_id", "docs_id", "comments", "pyme_name", "pyme_email", "user_email", "user_ip", "checkCondFinancial"],
  updateOrAddDocsSuccess: ["message"],
  submitResultByStage: ["request_id", "action_req", "stage", "justification", "comments", "pyme_name", "pyme_email", "user_email", "user_ip", "has_codeptor", "docs_id", "functionality_id", "msg_id", "reject_html"],
  resultByStageSuccess: ["message"],
  submitResultInterview: ["request_id", "action", "result", "comments", "has_codeptor", "user_email", "user_ip"],
  resultInterviewSuccess: ["message"],
  submitDeleteInterview: ["request_id", "user_email", "user_ip"],
  deleteInterviewSuccess: ["message"],
  submitUploadDoc: ["data","user_email","user_ip", "withURL"],
  uploadDocSuccess: ["message"],
  loadDocFail: ["error"],
  getUpCampaignId: ["req_campaign_id"],
  submitGetRiskClassification: [""],
  getRiskClassificationSuccess: ["risk_classification_data"],
  submitGetCompanyClassification: [""],
  getCompanyClassificationSuccess: ["company_classification_data"],
  submitGetRequestCampaignInfo: ["request_campaign_id"],
  getRequestCampaignInfoSuccess: ["campaignInfo"],
  submitUpdateRequestCampaignClass: [
    "request_campaign_id",
    "company_classification",
    "project_classification",
    "update",
    "user_email",
    "user_ip"
  ],
  updateRequestCampaignClassSuccess: ["message"],
  submitGetFngInfo: ["company_id"],
  getFngInfoSuccess: ["fngInfo"],
  submitAddFngInfo: [
    "request_campaign_id",
    "fng_fee_percentage",
    "fng_coverage_percentage",
    "fng_warranty_line",
    "user_email",
    "user_ip"
  ],
  addFngInfoSuccess: ["message"],
  submitGetComiteCompanyInfo: ["company_id"],
  getComiteCompanyInfoSuccess: ["committeeInfo"],
  submitAddComiteCompanyInfo: [
    "company_id",
    "total_assets",
    "total_liabilities",
    "total_operational_income",
    "total_operational_outcome",
    "net_profit"
  ],
  addComiteCompanyInfoSuccess: ["message"],
  submitGetComiteFinancialConditions: ["request_campaign_id"],
  getComiteFinancialConditionsSuccess: ["financialConditions"],
  submitFinancialCondTerms: [
    "campaign_id",
    "requested_amount",
    "funding_rate",
    "term_in_months",
    "capital_payment_peridicity",
    "capital_payment_peridicity_text",
    "capital_grace_period",
    "capital_grace_period_text",
    "interest_payment_periodicity",
    "interest_payment_periodicity_text",
    "interest_grace_period",
    "interest_grace_period_text",
    "comments",
    "pyme_name",
    "change_request",
    "pyme_email",
    "fngWarrantyLine",
    "fngFeePercentage",
    "fngCoveragePercentage",
    "user_email",
    "user_ip"
  ],
  financialCondTermsSuccess: ["message"],
  submitGetStageListByRequestCampaign: ["request_campaign_id"],
  getStageListByRequestCampaignSuccess: ["stageList"],
  submitMarkDocumentsToPublish: [
    "request_campaign_id",
    "document_list",
    "user_email",
    "user_ip"
  ],
  markDocumentsToPublishSuccess: ["message"],
  submitGetDocsForPublish: ["campaignId", "stage_id"],
  getDocsForPublishSuccess: ["all_docs"],
  submitPublicationResult: [
    "request_id",
    "action_req",
    "simulator",
    "featured",
    "com_percentage",
    "justification",
    "comments",
    "bvc_share",
    "pyme_name",
    "pyme_email",
    "user_email",
    "user_ip",
    "financial_doc_name",
    "functionality_id", 
    "msg_id",
    "reject_html",
    "reject_msg",
    "close_date",
    "available_investment_date"
  ],
  publicationResultSuccess: ["message"],
  submitGetSimulatorFeatureComPercentage: ["request_campaign_id"],
  getSimulatorFeatureComPercentageSuccess: ["simulatorFeatureComPercentage"],
  submitGetLogsByReqCampaign: ["request_campaign_id"],
  getLogsByReqCampaignSuccess: ["tracingData"],
  submitGetAllMsgReject: ["stage"],
  getAllMsgRejectSuccess: ["rejectOptions"],
  submitGetCodebtors: ["campaign_Id"],
  getCodebtorsSuccess: ["codebtorData"],
  submitUpdateCodebtorState: [
    "codebtor_id",
    "action",
    "user_email",
    "user_ip",
    "comments"  
  ],
  updateCodebtorStateSuccess: ["codebtorData"],
  resultByStageFailed: ["error"],
  eraseData: [""],
  getProductsFNGListSuccess: ["productsFNGList"],
  getProductsFNGList:["user_email","user_ip"]
});

export const pymesCampaignTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  gettingData: false,
  data: "",
  campaignRequestData: "",
  documentsData: "",
  message: "",
  additionalDocsList: [],
  interviewAvailable: false,
  interviewData: {},
  req_campaign_id: {},
  error: "",
  showMessage: false,
  campaignInfo: "",
  fngInfo: [],
  committeeInfo: "",
  financialConditions: "",
  stageList: "",
  all_docs: "",
  simulatorFeatureComPercentage: [],
  tracingData: "",
  rejectOptions: "",
  codebtorData: ""
};

const submitGetCampaign = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getCampaignSuccess = (state, { data }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    data
  });

const submitGetCampaignRequest = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getCampaignRequestSuccess = (state, { campaignRequestData }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    campaignRequestData
  });

const submitGetDocsByStage = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    // gettingData: true
  });

const getDocsByStageSuccess = (state, { documentsData, additionalDocsList }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    documentsData: documentsData.documentsData,
    additionalDocsList: additionalDocsList.additionalDocsList
  });

const submitDocumentChangeState = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true
  });

const documentChangeStateSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitGetAdditionalDocs = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    // gettingData: true
  });

const getAdditionalDocsSuccess = (state, { additionalDocsList }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    additionalDocsList
  });

const submitGetInterviewValidation = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    // gettingData: true
  });

const getInterviewValidationSuccess = (state, { interviewAvailable }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    interviewAvailable: interviewAvailable.valid
  });

const submitGetInterviewData = state =>
  Object.assign({}, state, {
    interviewData: "",
    // isFetching: true, 
    gettingData: true
  });

const getInterviewDataSuccess = (state, { interviewData, req_campaign_id }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    interviewData,
    req_campaign_id
  });

const submitScheduleInterview = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const scheduleInterviewSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitUpdateOrAddDocs = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const updateOrAddDocsSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitResultByStage = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const resultByStageSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitResultInterview = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true
  });

const resultInterviewSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitDeleteInterview = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const deleteInterviewSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitUploadDoc = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    error: "",
    message: ""
  });

const uploadDocSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message: message,
    error: "",
    showMessage: true,
  });

const loadDocFail = (state, { error: responseError }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    error: responseError,
    message: ""
  });

const getUpCampaignId = (state, req_campaign_id) =>
  Object.assign({}, state, {
    req_campaign_id
  });

const submitGetRiskClassification = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getRiskClassificationSuccess = (state, { risk_classification_data }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    risk_classification_data
  });

const submitGetCompanyClassification = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getCompanyClassificationSuccess = (state, { company_classification_data }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    company_classification_data
  });

const submitGetRequestCampaignInfo = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getRequestCampaignInfoSuccess = (state, { campaignInfo }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    campaignInfo
  });

const submitUpdateRequestCampaignClass = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const updateRequestCampaignClassSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitGetFngInfo = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getFngInfoSuccess = (state, { fngInfo }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    fngInfo
  });

const submitAddFngInfo = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const addFngInfoSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitGetComiteCompanyInfo = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true
  });

const getComiteCompanyInfoSuccess = (state, { committeeInfo }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    committeeInfo
  });

const submitAddComiteCompanyInfo = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const addComiteCompanyInfoSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitGetComiteFinancialConditions = state =>
  Object.assign({}, state, {
    // isFetching: true, 
    gettingData: true,
    financialConditions: ""
  });

const getComiteFinancialConditionsSuccess = (state, { financialConditions }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    financialConditions
  });

const submitFinancialCondTerms = state =>
  Object.assign({}, state, {
    isFetching: true, 
    gettingData: true,
    message: ''
  });

const financialCondTermsSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    message
  });

const submitGetStageListByRequestCampaign = state =>
  Object.assign({}, state, {
    isFetching: false
  });

const getStageListByRequestCampaignSuccess = (state, { stageList }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    stageList
  });

const submitMarkDocumentsToPublish = state =>
  Object.assign({}, state, {
    isFetching: false
  });

const markDocumentsToPublishSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    message
  });

const submitGetDocsForPublish = state =>
  Object.assign({}, state, {
    isFetching: false
  });

const getDocsForPublishSuccess = (state, { all_docs }) =>
  Object.assign({}, state, {
    isFetching: false,
    all_docs
  });

const submitPublicationResult = state =>
  Object.assign({}, state, {
    isFetching: true,
    gettingData: true,
    message: ''
  });

const publicationResultSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });

const submitGetSimulatorFeatureComPercentage = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true
  });

const getSimulatorFeatureComPercentageSuccess = (state, { simulatorFeatureComPercentage }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    simulatorFeatureComPercentage
  });

const submitGetLogsByReqCampaign = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true
  });

const getLogsByReqCampaignSuccess = (state, { tracingData }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    tracingData
  });

const submitGetAllMsgReject = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true
  });

const getAllMsgRejectSuccess = (state, { rejectOptions }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    rejectOptions
  });

const submitGetCodebtors = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true
  });

const getCodebtorsSuccess = (state, { codebtorData }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    codebtorData
  });

const submitUpdateCodebtorState = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true
  });

const updateCodebtorStateSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });
const resultByStageFailed = (state,  { error } ) =>
  Object.assign({}, state, {
    isFetching: false,
    error: error.message
  })
  
const eraseData = ( state ) =>
  Object.assign({}, state, {
    isFetching: false,
    error: "",
    message: ""
  })

const getProductsFNGListSuccess = (state, { productsFNGList }) =>
  Object.assign({}, state, {
    isFetching: false, 
    gettingData: false,
    productsFNGList
  });

const getProductsFNGList = state =>
  Object.assign({}, state, {
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_CAMPAIGN]: submitGetCampaign,
  [Types.GET_CAMPAIGN_SUCCESS]: getCampaignSuccess,
  [Types.SUBMIT_GET_CAMPAIGN_REQUEST]: submitGetCampaignRequest,
  [Types.GET_CAMPAIGN_REQUEST_SUCCESS]: getCampaignRequestSuccess,
  [Types.SUBMIT_GET_DOCS_BY_STAGE]: submitGetDocsByStage,
  [Types.GET_DOCS_BY_STAGE_SUCCESS]: getDocsByStageSuccess,
  [Types.SUBMIT_DOCUMENT_CHANGE_STATE]: submitDocumentChangeState,
  [Types.DOCUMENT_CHANGE_STATE_SUCCESS]: documentChangeStateSuccess,
  [Types.SUBMIT_GET_ADDITIONAL_DOCS]: submitGetAdditionalDocs,
  [Types.GET_ADDITIONAL_DOCS_SUCCESS]: getAdditionalDocsSuccess,
  [Types.SUBMIT_GET_INTERVIEW_VALIDATION]: submitGetInterviewValidation,
  [Types.GET_INTERVIEW_VALIDATION_SUCCESS]: getInterviewValidationSuccess,
  [Types.SUBMIT_GET_INTERVIEW_DATA]: submitGetInterviewData,
  [Types.GET_INTERVIEW_DATA_SUCCESS]: getInterviewDataSuccess,
  [Types.SUBMIT_SCHEDULE_INTERVIEW]: submitScheduleInterview,
  [Types.SCHEDULE_INTERVIEW_SUCCESS]: scheduleInterviewSuccess,
  [Types.SUBMIT_UPDATE_OR_ADD_DOCS]: submitUpdateOrAddDocs,
  [Types.UPDATE_OR_ADD_DOCS_SUCCESS]: updateOrAddDocsSuccess,
  [Types.SUBMIT_RESULT_BY_STAGE]: submitResultByStage,
  [Types.RESULT_BY_STAGE_SUCCESS]: resultByStageSuccess,
  [Types.SUBMIT_RESULT_INTERVIEW]: submitResultInterview,
  [Types.RESULT_INTERVIEW_SUCCESS]: resultInterviewSuccess,
  [Types.SUBMIT_DELETE_INTERVIEW]: submitDeleteInterview,
  [Types.DELETE_INTERVIEW_SUCCESS]: deleteInterviewSuccess,
  [Types.SUBMIT_UPLOAD_DOC]: submitUploadDoc,
  [Types.UPLOAD_DOC_SUCCESS]: uploadDocSuccess,
  [Types.LOAD_DOC_FAIL]: loadDocFail,
  [Types.GET_UP_CAMPAIGN_ID]: getUpCampaignId,
  [Types.SUBMIT_GET_RISK_CLASSIFICATION]: submitGetRiskClassification,
  [Types.GET_RISK_CLASSIFICATION_SUCCESS]: getRiskClassificationSuccess,
  [Types.SUBMIT_GET_COMPANY_CLASSIFICATION]: submitGetCompanyClassification,
  [Types.GET_COMPANY_CLASSIFICATION_SUCCESS]: getCompanyClassificationSuccess,
  [Types.SUBMIT_GET_REQUEST_CAMPAIGN_INFO]: submitGetRequestCampaignInfo,
  [Types.GET_REQUEST_CAMPAIGN_INFO_SUCCESS]: getRequestCampaignInfoSuccess,
  [Types.SUBMIT_UPDATE_REQUEST_CAMPAIGN_CLASS]: submitUpdateRequestCampaignClass,
  [Types.UPDATE_REQUEST_CAMPAIGN_CLASS_SUCCESS]: updateRequestCampaignClassSuccess,
  [Types.SUBMIT_GET_FNG_INFO]: submitGetFngInfo,
  [Types.GET_FNG_INFO_SUCCESS]: getFngInfoSuccess,
  [Types.SUBMIT_ADD_FNG_INFO]: submitAddFngInfo,
  [Types.ADD_FNG_INFO_SUCCESS]: addFngInfoSuccess,
  [Types.SUBMIT_GET_COMITE_COMPANY_INFO]: submitGetComiteCompanyInfo,
  [Types.GET_COMITE_COMPANY_INFO_SUCCESS]: getComiteCompanyInfoSuccess,
  [Types.SUBMIT_ADD_COMITE_COMPANY_INFO]: submitAddComiteCompanyInfo,
  [Types.ADD_COMITE_COMPANY_INFO_SUCCESS]: addComiteCompanyInfoSuccess,
  [Types.SUBMIT_GET_COMITE_FINANCIAL_CONDITIONS]: submitGetComiteFinancialConditions,
  [Types.GET_COMITE_FINANCIAL_CONDITIONS_SUCCESS]: getComiteFinancialConditionsSuccess,
  [Types.SUBMIT_FINANCIAL_COND_TERMS]: submitFinancialCondTerms,
  [Types.FINANCIAL_COND_TERMS_SUCCESS]: financialCondTermsSuccess,
  [Types.SUBMIT_GET_STAGE_LIST_BY_REQUEST_CAMPAIGN]: submitGetStageListByRequestCampaign,
  [Types.GET_STAGE_LIST_BY_REQUEST_CAMPAIGN_SUCCESS]: getStageListByRequestCampaignSuccess,
  [Types.SUBMIT_MARK_DOCUMENTS_TO_PUBLISH]: submitMarkDocumentsToPublish,
  [Types.MARK_DOCUMENTS_TO_PUBLISH_SUCCESS]: markDocumentsToPublishSuccess,
  [Types.SUBMIT_GET_DOCS_FOR_PUBLISH]: submitGetDocsForPublish,
  [Types.GET_DOCS_FOR_PUBLISH_SUCCESS]: getDocsForPublishSuccess,
  [Types.SUBMIT_PUBLICATION_RESULT]: submitPublicationResult,
  [Types.PUBLICATION_RESULT_SUCCESS]: publicationResultSuccess,
  [Types.SUBMIT_GET_SIMULATOR_FEATURE_COM_PERCENTAGE]: submitGetSimulatorFeatureComPercentage,
  [Types.GET_SIMULATOR_FEATURE_COM_PERCENTAGE_SUCCESS]: getSimulatorFeatureComPercentageSuccess,
  [Types.SUBMIT_GET_LOGS_BY_REQ_CAMPAIGN]: submitGetLogsByReqCampaign,
  [Types.GET_LOGS_BY_REQ_CAMPAIGN_SUCCESS]: getLogsByReqCampaignSuccess,
  [Types.SUBMIT_GET_ALL_MSG_REJECT]: submitGetAllMsgReject,
  [Types.GET_ALL_MSG_REJECT_SUCCESS]: getAllMsgRejectSuccess,
  [Types.SUBMIT_GET_CODEBTORS]: submitGetCodebtors,
  [Types.GET_CODEBTORS_SUCCESS]: getCodebtorsSuccess,
  [Types.SUBMIT_UPDATE_CODEBTOR_STATE]: submitUpdateCodebtorState,
  [Types.UPDATE_CODEBTOR_STATE_SUCCESS]: updateCodebtorStateSuccess,
  [Types.RESULT_BY_STAGE_FAILED]: resultByStageFailed,
  [Types.ERASE_DATA]: eraseData,
  [Types.GET_PRODUCTS_FNG_LIST_SUCCESS]: getProductsFNGListSuccess,
  [Types.GET_PRODUCTS_FNG_LIST]: getProductsFNGList
});