const { Serializer } = require("jsonapi-serializer");

module.exports = {
  updateDocumentSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "document_id",
        "name",
        "description",
        "old_path",
        "file",
        "file_name",
        "user_email",
        "user_ip"
      ]
    })
};
