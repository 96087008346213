import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import CertificatesActions from "../reducers/certificates.reducer";
import { certificatesSerializer } from "../../lib/serializers/certificates.serializer";


export const StartCertificatesShowcase = () => ({
    type: "START_CERTIFICATES_SHOWCASE"
});

export const certificatesShowcase = action$ => action$.pipe(
    ofType("START_CERTIFICATES_SHOWCASE"),
    flatMap(action => {
        return Observable.from(
            App.api.a2censo.getPymesWithCertificates()
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    push(`/certificates`, { response }),
                    CertificatesActions.certificatesShowcaseSuccess()
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export const submitGetCertificatesByPyme = action$ => action$.pipe(
    ofType("SUBMIT_GET_CERTIFICATES_BY_PYME"),
    flatMap(action => {
        const url = {
            pyme_id: action.pyme_id ? action.pyme_id : ""
        };
        return Observable.from(
            App.api.a2censo.getCertificatesByPyme({ url })
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    CertificatesActions.getCertificatesByPymeSuccess(response)
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export const updateCertificateByPyme = action$ => action$.pipe(
    ofType("UPDATE_CERTIFICATE_BY_PYME"),
    flatMap(action => {
        const company_id = action.company_id;
        const withholding_city = action.withholdingCity;
        const consignment_city = action.consignmentCity;
        const company_city = action.companyCity;
        const pyme_address = action.pymeAddress;
        const observation = action.observation;
        const user_email = action.email;
        const user_ip = action.user_ip;
        const data = {
            body: certificatesSerializer().serialize({ company_id, withholding_city, consignment_city, company_city, pyme_address, observation, user_email, user_ip }),
        }
        return Observable.from(
            App.api.a2censo.updateCertificateByPyme({ ...data })
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    CertificatesActions.updateCertificateByPymeSuccess(response)
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export default combineEpics(certificatesShowcase, submitGetCertificatesByPyme, updateCertificateByPyme);