import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitRegisterAssociate: ["data"],
  updateRegisterAssociate: ["data"],
  deleteCompanyAssociate: ["data"],
  registerAssociateSuccess: ["response"],
  registerAssociateFail: ["error"],
  cleanRegisterAssociate: [""],
  createAssociate: ["msj"],
  editAssociate: ["msj"],
  deleteAssociate: ["msj"],
  eraseAssociate: [],
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetchingAssociate: false,
  associateId: "",
  error: "",
  create: "",
  edit: "",
  delete: ""
};

const cleanRegisterAssociate = state =>
  Object.assign({}, state, {
    associateId: ""
  });

const submitRegisterAssociate = state =>
  Object.assign({}, state, {
    isFetchingAssociate: true
  });

const updateRegisterAssociate = state =>
  Object.assign({}, state, {
    isFetchingAssociate: true
  });

const deleteCompanyAssociate = state =>
  Object.assign({}, state, {
    isFetchingAssociate: true
  });

const registerAssociateSuccess = state =>
  Object.assign({}, state, {
    isFetchingAssociate: false,
  });
const registerAssociateFail = state =>
  Object.assign({}, state, {
    isFetchingAssociate: false,
    error: registerAssociateFail
  });

const createAssociate = (state, { msj: message }) =>
  Object.assign({}, state, {
    create: message
  });

const editAssociate = (state, { msj: message }) =>
  Object.assign({}, state, {
    edit: message
  });

const deleteAssociate = (state, { msj: message }) =>
  Object.assign({}, state, {
    delete: message
  });

const eraseAssociate = state =>
  Object.assign({}, state, {
    create: "",
    edit: "",
    delete: ""
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_REGISTER_ASSOCIATE]: submitRegisterAssociate,
  [Types.UPDATE_REGISTER_ASSOCIATE]: updateRegisterAssociate,
  [Types.DELETE_COMPANY_ASSOCIATE]: deleteCompanyAssociate,
  [Types.REGISTER_ASSOCIATE_SUCCESS]: registerAssociateSuccess,
  [Types.REGISTER_ASSOCIATE_FAIL]: registerAssociateFail,
  [Types.CLEAN_REGISTER_ASSOCIATE]: cleanRegisterAssociate,
  [Types.CREATE_ASSOCIATE]: createAssociate,
  [Types.EDIT_ASSOCIATE]: editAssociate,
  [Types.DELETE_ASSOCIATE]: deleteAssociate,
  [Types.ERASE_ASSOCIATE]: eraseAssociate
});
