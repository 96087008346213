import "../../translations/i18n";
import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components";
import Loader from "../Loader/Loader";
import Light from "../../styles/LightTheme";
import { Route, Switch } from "react-router-dom";
import GlobalStyle from "../../styles/Global";
import { ModalContainer, ModalRoute } from "react-router-modal";
import "react-router-modal/css/react-router-modal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ProtectedRoute from "./ProtectedRoute";

const Layout = lazy(() => import("../Layout/Layout"));
const Campaigns = lazy(() => import("../Campaigns/Campaigns"));
const Oops = lazy(() => import("../Oops/Oops"));
const CampaignForm = lazy(() => import("../CampaignForm/CampaignForm"));
const Company = lazy(() => import("../Company/Company"));
const Login = lazy(() => import("../Login/Login"));
const Companies = lazy(() => import("../Companies/Companies"));
const Multicash = lazy(() => import("../Multicash/Multicash"));
const Dispersion = lazy(() => import("../Dispersion/Dispersion"));
const Parameters = lazy(() => import("../Parameters/Parameters"));
const ParametersFNG = lazy(() => import("../ParametersFNG/ParametersFNG"));
const Home = lazy(() => import("../Home/Home"));
const Users = lazy(() => import("../Users/Users"));
const Annotations = lazy(() => import("../Annotation/Annotation"));
const AnnotationDetail = lazy(() => import("../AnnotationDetail/AnnotationDetail"));
const Patrimonial = lazy(() => import("../Patrimonial/Patrimonial"));
const PatrimonialDetail = lazy(() => import("../PatrimonialDetail/PatrimonialDetail"));
const Payment = lazy(() => import("../Payment/Payment"));
const PaymentDetail = lazy(() => import("../PaymentDetail/PaymentDetail"));
const AnnotationByFile = lazy(() => import("../AnnotationByFile/AnnotationByFile"));
const AnnotationByFileDetail = lazy(() => import("../AnnotationByFileDetail/AnnotationByFileDetail"));
const AnnotationHist = lazy(() => import("../AnnotationHist/AnnotationHist"));
const Transaction = lazy(() => import("../Transaction/Transaction"));
const TransactionDetail = lazy(() => import("../TransactionDetail/TransactionDetail"));
const DispersionHist = lazy(() => import("../DispersionHist/DispersionHist"));
const PymesCampaign = lazy(() => import("../PymesCampaign/PymesCampaign"));
const PymesCampaignDetail = lazy(() => import("../PymesCampaignDetail/PymesCampaignDetail"));
const AlertManagement = lazy(() => import("../AlertManagement/AlertManagement"));
const Vinculation = lazy(() => import("../Vinculation/Vinculation"));
const VinculationDetail = lazy(() => import("../VinculationDetail/VinculationDetail"));
const DocManagement = lazy(() => import("../DocManagement/DocManagement"));
const DocManagementDetail = lazy(() => import("../DocManagementDetail/DocManagementDetail"));
const ResponseManagement = lazy(() => import("../ResponseManagement/ResponseManagement"));
const Certificates = lazy(() => import("../Certificates/Certificates"));
const Investors = lazy(() => import("../Investors/Investors"));
const News = lazy(() => import("../News/News"));
const CampaignCreateForm = lazy(() => import("../CampaignCreateForm/CampaignCreateForm"));
const CampaignEditForm = lazy(() => import("../CampaignEditForm/CampaignEditForm"));
const CampaignTrackingACC = lazy(() => import("../CampaignTrackingACC/CampaignTrackingACC"));
const PymesCoDebtors  = lazy(() => import("../PymesCoDebtors/PymesCoDebtors"));

const Functionalities = {
    Campaign: [
        { id: 1, name: "Crear Campañas" }, { id: 2, name: "Consultar Campañas" },
        { id: 3, name: "Modificar Campañas" },
        { id: 63, name: "Modificar detalle de campaña"},
        { id: 46, name: "Dispersar campaña" },
        { id: 64, name: "Ver histórico de estados de campaña" },
        { id: 66, name: "Rechazo inversiones en una campaña" }
    ],
    CampaignForm: [
        { id: 1, name: "Crear Campañas" },
        { id: 3, name: "Modificar Campañas" },
        { id: 63, name: "Modificar detalle de campaña"}
    ],
    Company: [
        { id: 4, name: "Crear Pyme" },
        { id: 6, name: "Modificar Pyme" },
        { id: 5, name: "Consultar Pyme" }
    ],
    CompanyForm: [
        { id: 4, name: "Crear Pyme" },
        { id: 6, name: "Modificar Pyme" }
    ],
    Multicash: [
        { id: 10, name: "Consultar y descargar archivos de confirmación de pagos" },
        { id: 11, name: "Cargar archivos multicash" },
        { id: 12, name: "Descargar archivos multicash" }
    ],
    Dispersion: [
        { id: 7, name: "Generar Archivo de dispersion" },
        { id: 8, name: "Consultar y descargar archivos de dispersion (plano y encriptado)" },
        { id: 9, name: "Cargar archivo de confirmación de pagos" },
        { id: 10, name: "Consultar y descargar archivos de confirmación de pagos" }
    ],
    Parameters: [
        { id: 15, name: "Crear cuenta" }, { id: 16, name: "Editar cuenta" },
        { id: 17, name: "Consultar cuenta" }
    ],
    Users: [
        { id: 18, name: "Consultar Usuarios" },
        { id: 19, name: "Editar Usuarios" }
    ],
    Annotation: [
        { id: 20, name: "Consultar anotaciones" },
        { id: 21, name: "Descargar detalles de anotación" },
        { id: 30, name: "Consultar datos de emisión" },
        { id: 31, name: "Editar datos de emisión" },
        { id: 32, name: "Generar macrotítulo" },
        { id: 33, name: "Descargar macrotítulo" },
    ],
    AnnotationDetail: [
        { id: 22, name: "Ver detalle de anotación" },
        { id: 29, name: "Anotar" },
        { id: 37, name: "Cambiar estado de anotación" },
    ],
    AnnotationHist: [
        { id: 34, name: "Consultar histórico de anotación" },
    ],
    AnnotationByFile: [
        { id: 35, name: "Consultar anotaciones por archivo" },
    ],
    AnnotationByFileDetail: [
        { id: 36, name: "Consultar detalle de anotaciones por archivo" },
    ],
    Patrimonial: [
        { id: 23, name: "Consultar derechos patrimoniales" },
        { id: 24, name: "Descargar detalle de derechos patrimoniales" },
        { id: 26, name: "Generar transacciones" },
        { id: 68, name: "Acreditar pago en mora" }
    ],
    PatrimonialDetail: [
        { id: 25, name: "Ver detalle de derechos patrimoniales" }
    ],
    Payment: [
        { id: 27, name: "Consultar pagos de pymes" }
    ],
    PaymentDetail: [
        { id: 28, name: "Consultar detalle de pagos de pymes" }
    ],
    Transaction: [
        { id: 41, name: "Consultar transacciones" },
        { id: 42, name: "Acceder al detalle de una transacción" },
        { id: 37, name: "Cambiar estado de anotación" },
        { id: 47, name: "Ver histórico de transacción" },
    ],
    TransactionDetail: [
        { id: 42, name: "Acceder al detalle de una transacción" },
        { id: 43, name: "Cambiar estado de una transacción" }
    ],
    DispersionHist: [
        { id: 7, name: "Generar Archivo de dispersion" },
        { id: 8, name: "Consultar y descargar archivos de dispersion (plano y encriptado)" },
        { id: 9, name: "Cargar archivo de confirmación de pagos" },
        { id: 10, name: "Consultar y descargar archivos de confirmación de pagos" }
    ],
    AlertManagement: [
        { id: 38, name: "Acceder al módulo gestión de alertas" }
    ],
    Vinculation: [
        { id: 39, name: "Acceder al módulo de vinculación" },
        { id: 67, name: "Vinculación - Editar datos bancarios" }
    ],
    PymesCampaign: [
        { id: 40, name: "Acceder al módulo solicitud de campañas" },
        { id: 80, name: "Consultar codeudoras" }
    ],
    DocManagement: [
        { id: 48, name: "Acceder al módulo gestión de documentación" }
    ],
    DocManagementDetail: [
        { id: 49, name: "Acceder a parametrizar un documento" }
    ],
    PymesCampaignDetail: [
        { id: 50, name: 'Acceder a pestaña - Solicitud de campaña'},
        { id: 51, name: 'Acceder a pestaña - Filtros iniciales'},
        { id: 52, name: 'Filtros iniciales - editar y aprobar etapa'},
        { id: 53, name: 'Acceder a pestaña - Entrevista'},
        { id: 54, name: 'Entrevista - aprobar etapa'},
        { id: 55, name: 'Acceder a pestaña - Análisis integral'},
        { id: 56, name: 'Análisis integral - editar y aprobar etapa'},
        { id: 57, name: 'Acceder a pestaña - Comité'},
        { id: 58, name: 'Comité - editar y aprobar etapa'},
        { id: 59, name: 'Acceder a pestaña - Análisis Final'},
        { id: 60, name: 'Acceder a pestaña - Publicación'},
        { id: 61, name: 'Publicación - aprobar etapa'}
    ],
    ResponseManagement: [
        { id: 65, name: 'Parametrización de respuestas de rechazo'}
    ],
    Certificates: [
        { id: 62, name: 'Ver novedades de certificados'}
    ],
    Investors: [
        { id: 69, name: 'Entrar al módulo inversionistas'}
    ],
    News: [
        { id: 70, name: 'Entrar al módulo novedades - Home'},
        { id: 71, name: 'Entrar al módulo novedades - Campañas'}
    ],
    CampaignCreateFormFuntional: [
        { id: 1, name: "Crear Campañas" },
        { id: 72, name: "Crear Campañas desde cargue de archivo excel" }
    ],
    CampaignEditFormFuntional: [
        { id: 3, name: "Modificar Campañas" },
        { id: 44, name: "Consultar detalle de campaña" },
        { id: 74, name: "Añadir Detalle Campaña" },
        { id: 75, name: "Editar Detalle Campaña" },
        { id: 76, name: "Eliminar Detalle Campaña" },
        { id: 77, name: "Crear, Editar y Elimar detalle tipo Documentos" }
    ],
    CampaignTrackingACCFuntional: [
        {id: 78, name: "Seguimiento ACC"}
    ],
    ParametersFNG: [
        { id: 79, name: "Crear, Editar y Elimar Comisión de garantia FNG" }
    ],
    PymesCoDebtors: [
        { id: 81, name: "Agregar codeudoras" },
        { id: 82, name: "Editar codeudoras" },
        { id: 83, name: "Eliminar codeudoras" },
    ],
}
console.log("APP")
const App = ({ app }) => (
    <Provider store={app.redux.store}>
        <PersistGate loading={<Loader />} persistor={app.redux.persistor}>
            <ConnectedRouter history={app.redux.history}>
                <Suspense fallback={<Loader />}>
                    <ThemeProvider theme={Light}>
                        <Switch>
                            <Layout>
                                <div>
                                    <ModalRoute component={Login} path="/login" className="modal__container" backdropClassName="modal__backdrop" />
                                    <ProtectedRoute exact path='/campaigns' component={Campaigns} pageFunctionalities={[Functionalities.Campaign]} />
                                    <ProtectedRoute exact path='/companies' component={Companies} pageFunctionalities={[Functionalities.Company]} />
                                    <ProtectedRoute path="/campaignInvestors" component={CampaignForm} pageFunctionalities={[Functionalities.CampaignForm]} />
                                    <ProtectedRoute path="/company" component={Company} pageFunctionalities={[Functionalities.CompanyForm]} />
                                    <ProtectedRoute path="/dispersion" component={Dispersion} pageFunctionalities={[Functionalities.Dispersion]} />
                                    <ProtectedRoute path='/multicash' component={Multicash} pageFunctionalities={[Functionalities.Multicash]} />
                                    <ProtectedRoute path="/parameters" component={Parameters} pageFunctionalities={[Functionalities.Parameters]} />
                                    <ProtectedRoute path="/pyme_simulator_parameters" component={ParametersFNG} pageFunctionalities={[Functionalities.ParametersFNG]} />
                                    <ProtectedRoute path="/users" component={Users} pageFunctionalities={[Functionalities.Users]} />
                                    <ProtectedRoute path="/annotation" component={Annotations} pageFunctionalities={[Functionalities.Annotation]} />
                                    <ProtectedRoute path="/annotation-detail" component={AnnotationDetail} pageFunctionalities={[Functionalities.AnnotationDetail]} />
                                    <ProtectedRoute path="/annotation-hist" component={AnnotationHist} pageFunctionalities={[Functionalities.AnnotationHist]} />
                                    <ProtectedRoute path="/annotation-by-file" component={AnnotationByFile} pageFunctionalities={[Functionalities.AnnotationByFile]} />
                                    <ProtectedRoute path="/annotation-by-file-detail" component={AnnotationByFileDetail} pageFunctionalities={[Functionalities.AnnotationByFileDetail]} />
                                    <ProtectedRoute path="/patrimonial" component={Patrimonial} pageFunctionalities={[Functionalities.Patrimonial]} />
                                    <ProtectedRoute path="/patrimonial-detail" component={PatrimonialDetail} pageFunctionalities={[Functionalities.PatrimonialDetail]} />
                                    <ProtectedRoute path="/payment" component={Payment} pageFunctionalities={[Functionalities.Payment]} />
                                    <ProtectedRoute path="/payment-detail" component={PaymentDetail} pageFunctionalities={[Functionalities.PaymentDetail]} />
                                    <ProtectedRoute path="/transaction" component={Transaction} pageFunctionalities={[Functionalities.Transaction]} />
                                    <ProtectedRoute path="/transaction-detail" component={TransactionDetail} pageFunctionalities={[Functionalities.TransactionDetail]} />
                                    <ProtectedRoute path="/dispersion-hist" component={DispersionHist}  pageFunctionalities={[Functionalities.DispersionHist]}/>
                                    <ProtectedRoute path="/pymes-campaign" component={PymesCampaign}  pageFunctionalities={[Functionalities.PymesCampaign]}/>
                                    <ProtectedRoute path="/pymes-campaign-detail" component={PymesCampaignDetail}  pageFunctionalities={[Functionalities.PymesCampaignDetail]}/>
                                    <ProtectedRoute path="/alert-management" component={AlertManagement}  pageFunctionalities={[Functionalities.AlertManagement]}/>
                                    <ProtectedRoute path="/vinculation" component={Vinculation}  pageFunctionalities={[Functionalities.Vinculation]}/>
                                    <ProtectedRoute path="/vinculation-detail" component={VinculationDetail}  pageFunctionalities={[Functionalities.Vinculation]}/>
                                    <ProtectedRoute path="/doc-management" component={DocManagement}  pageFunctionalities={[Functionalities.DocManagement]}/>
                                    <ProtectedRoute path="/doc-management-detail" component={DocManagementDetail}  pageFunctionalities={[Functionalities.DocManagementDetail]}/>
                                    <ProtectedRoute path="/response-management" component={ResponseManagement}  pageFunctionalities={[Functionalities.ResponseManagement]}/>
                                    <ProtectedRoute path="/certificates" component={Certificates}  pageFunctionalities={[Functionalities.Certificates]}/>
                                    <ProtectedRoute path="/investors" component={Investors}  pageFunctionalities={[Functionalities.Investors]}/>
                                    <ProtectedRoute path="/news" component={News}  pageFunctionalities={[Functionalities.News]}/>
                                    <ProtectedRoute path="/CampaignCreateForm" component={CampaignCreateForm} pageFunctionalities={[Functionalities.CampaignCreateFormFuntional]} />
                                    <ProtectedRoute path="/CampaignEditForm/:id" component={CampaignEditForm} pageFunctionalities={[Functionalities.CampaignEditFormFuntional]} />
                                    <ProtectedRoute path="/CampaignTrackingACC/:id" component={CampaignTrackingACC} pageFunctionalities={[Functionalities.CampaignTrackingACCFuntional]} />
                                    <ProtectedRoute path="/pymes-co-debtors/:id" component={PymesCoDebtors}  pageFunctionalities={[Functionalities.PymesCoDebtors]}/>
                                    <Route path="/oops" component={Oops} />
                                    <Route path="/" component={Home} />
                                </div>
                            </Layout>
                        </Switch>
                        <ModalContainer bodyModalOpenClassName="modal__container--open" />
                        <GlobalStyle />
                    </ThemeProvider>
                </Suspense>
            </ConnectedRouter>
        </PersistGate>
    </Provider>
);

App.propTypes = {
    app: PropTypes.shape({
        redux: PropTypes.shape({
            store: PropTypes.shape({}),
            persistor: PropTypes.shape({}),
            history: PropTypes.shape({})
        })
    }).isRequired
};

export default App;