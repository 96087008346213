const { Serializer } = require("jsonapi-serializer");

module.exports = {
  AssociateSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "document_type",
                "document_number",
                "name",
                "last_name",
                "second_name",
                "second_last_name",
                "document_expedition_date",
                "expedition_place",
                "expedition_country",
                "manage_public_resources",
                "send_usa_resources",
                "publicly_exposed_person",
                "company_id",
                "percentage_shareholding",
                "ciuu_code",
                "city_id",
                "person_type",
                "created_at",
                "updated_at",
                "dv",
                "user_login_ip",
                "email_login"
      ]
    })
};
