const { Serializer } = require("jsonapi-serializer");

module.exports = {
  dispersionSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "fng_fee",
        "origination_fee",
        "expedition_date",
        "due_date"
      ]
    })
};
