import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  eraseFileData: [],
  submitGetFile: ["path", "bucket_id", "base_64", "downloadFunction", "errorFunction", "withURL"],
  getFileSuccess: ["data"],
  getFileFail: ["message"]
});

export const FileTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  data: '',
  error: '',
  isFetching: false
};

const eraseFileData = state =>   
  Object.assign({}, state, {
    data: '',
    error:''
  });

const submitGetFile = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getFileSuccess = (state, {data}) =>
  Object.assign({}, state, {
    isFetching: false,
    data
  });

const getFileFail = (state, {message}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: message
  });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ERASE_FILE_DATA]: eraseFileData,
    [Types.SUBMIT_GET_FILE]: submitGetFile,
    [Types.GET_FILE_SUCCESS]: getFileSuccess,
    [Types.GET_FILE_FAIL]: getFileFail
  });
  