import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import PatrimonialActions from "../reducers/patrimonial.reducer";
import { forkJoin } from "rxjs";
import { EmailSerializer } from "../../lib/serializers/email.serializer";
import { PatrimonialApprovedSerializer } from "../../lib/serializers/patrimonial.serializer";
import { AccreditSerializer } from "../../lib/serializers/accredit.serializer";


export const startPatrimonialShowcase = () => ({
  type: "START_PATRIMONIAL_SHOWCASE"
});

export const patrimonialShowcase = action$ =>
  action$.pipe(
      ofType("START_PATRIMONIAL_SHOWCASE"),
      flatMap(() => {
        const { patrimonial } = App.redux.store.getState();
        const url = {id: patrimonial.campaignSelected};
        let request;
        if(patrimonial.campaignSelected){
          request =  forkJoin([
            Observable.from(App.api.a2censo.getPatrimonialCampaigns()),
            Observable.from(App.api.a2censo.getPatrimonialCampaign({url}))
          ])
        }else {
          request =  forkJoin([
            Observable.from(App.api.a2censo.getPatrimonialCampaigns())
          ])
        }
        return request.pipe(
              flatMap((response) => {
                if(patrimonial.campaignSelected){
                  return Observable.of(
                    push(`/patrimonial`, {response: response[0], items: response[1]})
                  )
                }else{
                  return Observable.of(
                    push(`/patrimonial`, {response: response[0]})
                  )
                }
              }),
              catchError((e) => {
                if(e.statusCode === 401){   
                  return Observable.of(LoginActions.submitLogout())
                }else {
                  return Observable.of(
                    push(`/oops`)
                  )
                }
              })
          );
      })
  );

  export const getPatrimonialCampaign = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_PATRIMONIAL_CAMPAIGN"),
    flatMap((action) => {
      const url = {id: action.campaignData};
      let request = Observable.from(App.api.a2censo.getPatrimonialCampaign({url}));
      return request.pipe(
        flatMap((response) => {
          return Observable.of(
            PatrimonialActions.getPatrimonialCampaignSuccess({id: action.campaignData, patrimonial: response})
          )
        }),
        catchError((e) => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

  export const getPatrimonialsDetail = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_PATRIMONIAL_DETAIL"),
    flatMap(action => {
      const url = {id: action.data};
      return Observable.from(
        App.api.a2censo.getPatrimonialDetail({
          url
        })
      ).pipe(
        flatMap((items) => {
          return Observable.of(
            PatrimonialActions.getPatrimonialDetailSuccess(items),
            push(`patrimonial-detail`)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  PatrimonialActions.actionFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const generateTransactions = action$ =>
  action$.pipe(
    ofType("SUBMIT_GENERATE_TRANSACTIONS"),
    flatMap(action => {
      const data = {
        body: EmailSerializer().serialize(action.data),
        url: {id: action.data.id}
      }
      return Observable.from(
        App.api.a2censo.approvedPatrimonial({
          ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PatrimonialActions.generateTransactionsSuccess(response.message),
            push(`patrimonial`)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  PatrimonialActions.actionFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const approvedPatrimonial = action$ =>
  action$.pipe(
    ofType("APPROVED_PATRIMONIAL_BATCH"),
    flatMap(action => {
      const data = {
        body: PatrimonialApprovedSerializer().serialize(action.data),
        url: {id: action.data.id}
      }
      return Observable.from(
        App.api.a2censo.approvedPatrimonial({
          ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PatrimonialActions.approvedPatrimonialBatchSuccess(response.message),
            push(`patrimonial`)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  PatrimonialActions.actionFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const submitAccreditPatrimonial = action$ => action$.pipe(
    ofType("SUBMIT_ACCREDIT_PATRIMONIAL"),
    flatMap(action => {
        const fng = action.fng;
        const payment_file = action.payment_file;
        const payment_file_name = action.payment_file_name;
        const support_file = action.support_file;
        const support_file_name = action.support_file_name;
        const observation = action.observation;
        const id_patrimonial = action.id_patrimonial;
        const user_email = action.user_email;
        const user_ip = action.user_ip;
        const data = {
            body: AccreditSerializer().serialize({ fng, payment_file, payment_file_name, support_file, support_file_name, observation, id_patrimonial, user_email, user_ip }),
        }
        return Observable.from(
            App.api.a2censo.accreditPatrimonial({ ...data })
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    PatrimonialActions.accreditPatrimonialSuccess(response)
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                          PatrimonialActions.accreditPatrimonialFailed(response.data.attributes.message)
                        )
                    );
                }
            })
        );
    })
);

export default combineEpics(patrimonialShowcase, getPatrimonialsDetail, getPatrimonialCampaign, generateTransactions, approvedPatrimonial, submitAccreditPatrimonial);
