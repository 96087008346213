const { Serializer } = require("jsonapi-serializer");

module.exports = {
  resultByStageSerializer: (meta = {}) =>
    new Serializer("resultByStageSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_id", 
        "action_req", 
        "stage", 
        "justification", 
        "comments", 
        "pyme_name", 
        "pyme_email", 
        "user_email", 
        "user_ip", 
        "has_codeptor", 
        "docs_id",
        "functionality_id",
        "msg_id",
        "reject_html"
      ], 
    })
};
