import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setCreditData: ["creditData"],
  startCreditCreate: ["data"],
  getTrackingACC: ["id"],
});

export const AccTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data: "",
  campaingData: "",
  response: "",
};

const setCreditData = (state, { creditData }) =>
  Object.assign({}, state, {
    isFetching: false,
    creditData,
  });

const startCreditCreate = (state) =>
  Object.assign({}, state, {
    isFetching: true,
    response: "",
  });

const getTrackingACC = (state) =>
  Object.assign({}, state, {
    isFetching: true,
    response: "",
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CREDIT_DATA]: setCreditData,
  [Types.START_CREDIT_CREATE]: startCreditCreate,
  [Types.GET_TRACKING_ACC]: getTrackingACC,
});
