const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ParameterFNGSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "id",
        "name",
        "coverage_percentage",
        "coverage_description",
        "value",
        "type",
        "is_active",
        "user_email",
        "user_ip"
      ]
    })
};
