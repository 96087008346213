import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import AlertManagementActions from "../reducers/alertManagement.reducer";
import { alertManagementSerializer } from "../../lib/serializers/alertManagement.serializer";
import { forkJoin } from "rxjs";

export const StartAlertManagementShowcase = (type_reported) => ({
  type: "START_ALERT_MANAGEMENT_SHOWCASE",
  type_reported
});

export const alertManagementShowcase = action$ => action$.pipe(
  ofType("START_ALERT_MANAGEMENT_SHOWCASE"),
  flatMap(action => {
      const url = {type_reported : action.type_reported}
      let request;
      request = forkJoin([
      Observable.from(App.api.a2censo.getAlertManagementStates()),
      Observable.from(App.api.a2censo.getPymesByTypeReported({ url }))
    ])
    return request.pipe(
      flatMap((response) => {
        return Observable.of(
          push(`/alert-management`, { response })
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitGetNameList = action$ => action$.pipe(
    ofType("SUBMIT_GET_NAME_LIST"),
    flatMap(action => {
      const url = { 
        stateSelected: action.stateSelected? action.stateSelected: "",
        validationDate: action.validationDate? action.validationDate: "",
        companySelected: action.companySelected? action.companySelected: "",
        reported: action.reported? action.reported: 1
      };
      return Observable.from(
        App.api.a2censo.getNameList({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            AlertManagementActions.getNameListSuccess(response)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(
                push(`oops`)
              )
            );
          }
        })
      );
    })
  );

  export const submitReported = action$ => action$.pipe(
    ofType("SUBMIT_REPORTED"),
    flatMap(action => {
      const report_id = action.report_id;
      const action_rep = action.action_rep;
      const justification = action.justification;
      const user_email = action.email;
      const user_ip = action.user_ip;
      const type_reported = action.type_reported;
      const file = action.file;
      const file_name = action.file_name;
      const data = {
        body: alertManagementSerializer().serialize({report_id, action_rep, justification, user_email, user_ip, type_reported, file, file_name})
      }
      return Observable.from(
        App.api.a2censo.updateReported({ ...data })
      ).pipe(
        flatMap((responseUpdate) => {
          return Observable.of(
            AlertManagementActions.updateReportedSuccess(responseUpdate)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              AlertManagementActions.updateReportedFailed({ e })
            )
          };
        })
      );
    })
  );

  export const submitGetPymeList = action$ => action$.pipe(
    ofType("SUBMIT_GET_PYME_LIST"),
    flatMap(action => {
      const url = { type_reported : action.type_reported }
      let request;
      request = forkJoin([
      Observable.from(App.api.a2censo.getAlertManagementStates()),
      Observable.from(App.api.a2censo.getPymesByTypeReported({ url }))
      ])
      return request.pipe(
        flatMap((response) => {
          return Observable.of(
            push(`/alert-management`, { response })
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              AlertManagementActions.updateReportedFailed({ e })
            )
          };
        })
      );
    })
  );

export default combineEpics(alertManagementShowcase, submitGetNameList, submitReported, submitGetPymeList);
