import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCampaignDetails: ["getCampaignDetails"],
  startGetCampaignDetails: ["data"],
  annotationCampaignFail: ["error"],
  annotationCampaignSuccess: ["data"],
  annotateCampaign: ["data"],
  loadFileZFaild: ["error"],
  loadFileZSuccess: ["response"],
  loadFileZ: ["data"],
  dispersionCampaign: ["campaign", "item"],
  dispersionCampaignSucces: ["message"],
  dispersionCampaignFail: ["error"],
  validateCampaingFaild: ["error"],
  validateCampaignSuccess: ["response"],
  startValidateCampaign: ["data"],
  eraseData: [""],
  eraseDataLoad: [""],
  eraseAnnotationData: [""],
  getCampaignStateHistory: ["campaign_id"],
  getCampaignStateHistorySuccess: ["historyData"],
  startGetCampaignInvestors: ["campaign_id"],
  getCampaignInvestorsSuccess: ["investorsData"],
  getCampaignInvestorsFailed: ["error"],
  startRemoveInvestor: ["data"],
  removeInvestorSuccess: ["response"],
  removeInvestorFailed: ["error"],
  submitInactivateCampaign: ["campaign_id", "user_email", "user_ip"],
  inactivateCampaignSuccess: ["message"],
  inactivateCampaignFailed: ["error"],
  getAllCampaigns: ["state", "business_line_id"],
  getAllCampaignsSuccess: ["campaigns"],
  campaignStates: ["states"],
  campaignBusinessLine: ["businessLine"],
  getCampaigns: [""],
  getCampaignsSuccess: ["campaigns"],
  uploadZipSuccess: ["messageZipLoader"],
  unZipSuccess: ["files"],
  getDataExcel: ["dataExcel"],
  getSectionExcel: ["sectionExcel"],
  postLoad: ["load"],
  getCampaingData: ["campaignId", "tables"],
  setCampaingData: ["campaingData"],
  startGetCampaign: ["data"]
});

export const CampaignDetailsTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  response: "",
  error: "",
  message: "",
  data: "",
  showMessage: false,
  historyData: "",
  investorsData: "",
  campaigns: "",
  states: "",
  businessLine: "",
  messageZipLoader: "",
  files: "",
  dataExcel: "",
  sectionExcel: "",
  load: "",
  campaingData: "",
};

const startGetCampaignDetails = state =>
  Object.assign({}, state, {
    isFetching: true,
    response: ""
  })

const getCampaignDetails = state =>
  Object.assign({}, state, {
    isFetching: false,
  });

const dispersionCampaign = state =>
  Object.assign({}, state, {
    isFetching: true,
  });

const dispersionCampaignSucces = (state, { message: message }) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message,
    error: "",
    showMessage: true
  });

const dispersionCampaignFail = (state, { error: responseError }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    message: "",
    showMessage: true
  });

const annotateCampaign = state =>
  Object.assign({}, state, {
    isFetching: true,
  });

const annotationCampaignFail = (state, { error: responseError }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    message: "",
    showMessage: true
  });

const annotationCampaignSuccess = (state, { data }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: "",
    message: "Generación exitosa",
    data,
    showMessage: true
  });

const eraseData = state =>
  Object.assign({}, state, {
    isFetching: false,
    error: "",
    message: "",
    showMessage: false,
    messageZipLoader: "",
    files: "",
    dataExcel: "",
    sectionExcel: ""
  })

const eraseDataLoad = state =>
  Object.assign({}, state, {
    isFetching: false,
    load: ""
  })

const eraseAnnotationData = state =>
  Object.assign({}, state, {
    data: ""
  })

const loadFileZ = state =>
  Object.assign({}, state, {
    isFetching: true,
  });

const loadFileZFaild = (state, { error: responseError }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    message: "",
    showMessage: true
  });

const loadFileZSuccess = (state, { data }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: "",
    message: "Lectura exitosa",
    data,
    showMessage: true
  });

const getCampaignStateHistory = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const getCampaignStateHistorySuccess = (state, { historyData }) =>
  Object.assign({}, state, {
    isFetching: false,
    historyData: historyData
  });

const startGetCampaignInvestors = state =>
  Object.assign({}, state, {
    isFetching: true,
  });

const getCampaignInvestorsSuccess = (state, { investorsData }) =>
  Object.assign({}, state, {
    isFetching: false,
    investorsData,
    error: ""
  });

const getCampaignInvestorsFailed = (state, { error }) =>
  Object.assign({}, state, {
    isFetching: false,
    error,
    message: "",
    showMessage: true
  });

const startRemoveInvestor = state =>
  Object.assign({}, state, {
    isFetching: true,
  });

const removeInvestorSuccess = (state, message) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message,
    error: ""
  });

const removeInvestorFailed = (state, { error }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: error.data.attributes.message,
    message: "",
    showMessage: true
  });

const submitInactivateCampaign = state =>
  Object.assign({}, state, {
    isFetching: true,
  });

const inactivateCampaignSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message.message,
    showMessage: true
  })

const inactivateCampaignFailed = (state, { error }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: error.data.attributes.message,
    message: "",
    showMessage: true
  });

const getAllCampaigns = state =>
  Object.assign({}, state, {
    isFetching: true,
    response: ""
  });

const getAllCampaignsSuccess = (state, { campaigns }) =>
  Object.assign({}, state, {
    isFetching: false,
    campaigns
  })

const getCampaigns = (state) =>
  Object.assign({}, state, {
    isFetching: true
  });

const getCampaignsSuccess = (state, { campaigns }) =>
  Object.assign({}, state, {
    isFetching: false,
    campaigns
  })

const campaignStates = (state, { states }) => {
  return (
    Object.assign({}, state, {
      isFetching: false,
      states
    })
  )
}

const campaignBusinessLine = (state, { businessLine }) => {
  return (
    Object.assign({}, state, {
      isFetching: false,
      businessLine
    })
  )
}
const uploadZipSuccess = (state, { messageZipLoader }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    messageZipLoader: messageZipLoader,
    error: "",
    showMessage: true,
  });

const unZipSuccess = (state, { files }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    files: files,
    error: "",
    showMessage: true,
  });

const getDataExcel = (state, { dataExcel }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    dataExcel: dataExcel,
    error: "",
    showMessage: true,
  });

const getSectionExcel = (state, { sectionExcel }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    sectionExcel: sectionExcel,
    error: "",
    showMessage: true,
  });

const postLoad = (state, { load }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    load: load,
    error: "",
    showMessage: true,
  });

const getCampaingData = (state) =>
  Object.assign({}, state, {
    isFetching: true
  });

  const setCampaingData = (state, { campaingData }) =>
  Object.assign({}, state, {
    isFetching: false,
    campaingData
  });

  const startGetCampaign = state =>
    Object.assign({}, state, {
        isFetching: true,
        response: ""
    });

  export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_CAMPAIGN_DETAILS]: getCampaignDetails,
    [Types.START_GET_CAMPAIGN_DETAILS]: startGetCampaignDetails,
    [Types.ANNOTATE_CAMPAIGN]: annotateCampaign,
    [Types.ANNOTATION_CAMPAIGN_FAIL]: annotationCampaignFail,
    [Types.ANNOTATION_CAMPAIGN_SUCCESS]: annotationCampaignSuccess,
    [Types.DISPERSION_CAMPAIGN]: dispersionCampaign,
    [Types.DISPERSION_CAMPAIGN_SUCCES]: dispersionCampaignSucces,
    [Types.DISPERSION_CAMPAIGN_FAIL]: dispersionCampaignFail,
    [Types.ERASE_ANNOTATION_DATA]: eraseAnnotationData,
    [Types.ERASE_DATA]: eraseData,
    [Types.ERASE_DATA_LOAD]: eraseDataLoad,
    [Types.LOAD_FILE_Z]: loadFileZ,
    [Types.LOAD_FILE_Z_SUCCESS]: loadFileZSuccess,
    [Types.LOAD_FILE_Z_FAILD]: loadFileZFaild,
    [Types.GET_CAMPAIGN_STATE_HISTORY]: getCampaignStateHistory,
    [Types.GET_CAMPAIGN_STATE_HISTORY_SUCCESS]: getCampaignStateHistorySuccess,
    [Types.START_GET_CAMPAIGN_INVESTORS]: startGetCampaignInvestors,
    [Types.GET_CAMPAIGN_INVESTORS_SUCCESS]: getCampaignInvestorsSuccess,
    [Types.GET_CAMPAIGN_INVESTORS_FAILED]: getCampaignInvestorsFailed,
    [Types.START_REMOVE_INVESTOR]: startRemoveInvestor,
    [Types.REMOVE_INVESTOR_SUCCESS]: removeInvestorSuccess,
    [Types.REMOVE_INVESTOR_FAILED]: removeInvestorFailed,
    [Types.SUBMIT_INACTIVATE_CAMPAIGN]: submitInactivateCampaign,
    [Types.INACTIVATE_CAMPAIGN_SUCCESS]: inactivateCampaignSuccess,
    [Types.INACTIVATE_CAMPAIGN_FAILED]: inactivateCampaignFailed,
    [Types.GET_ALL_CAMPAIGNS]: getAllCampaigns,
    [Types.GET_ALL_CAMPAIGNS_SUCCESS]: getAllCampaignsSuccess,
    [Types.CAMPAIGN_STATES]: campaignStates,
    [Types.CAMPAIGN_BUSINESS_LINE]: campaignBusinessLine,
    [Types.GET_CAMPAIGNS]: getCampaigns,
    [Types.GET_CAMPAIGNS_SUCCESS]: getCampaignsSuccess,
    [Types.UPLOAD_ZIP_SUCCESS]: uploadZipSuccess,
    [Types.UN_ZIP_SUCCESS]: unZipSuccess,
    [Types.GET_DATA_EXCEL]: getDataExcel,
    [Types.GET_SECTION_EXCEL]: getSectionExcel,
    [Types.POST_LOAD]: postLoad,
    [Types.GET_CAMPAING_DATA]: getCampaingData,
    [Types.SET_CAMPAING_DATA]: setCampaingData,
    [Types.START_GET_CAMPAIGN]: startGetCampaign
  });