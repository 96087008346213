import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import AnnotationActions from "../reducers/annotation.reducer"
import { forkJoin } from "rxjs";
import { AnnotationSerializer } from "../../lib/serializers/annotation.serializer";
import { AnnotateSerializer } from "../../lib/serializers/annotate.serializer";
import { ChangeStateSerializer } from "../../lib/serializers/changeState.serializer";

export const startAnnotationShowcase = () => ({
  type: "START_ANNOTATION_SHOWCASE"
});

export const annotationShowcase = action$ =>
  action$.pipe(
      ofType("START_ANNOTATION_SHOWCASE"),
      flatMap(() => {
          let request;
          request = forkJoin([
            Observable.from(App.api.a2censo.getAnnotationCampaigns()),
            Observable.from(App.api.a2censo.getAnnotationStates()),
            Observable.from(App.api.a2censo.getBusinessLine()),
          ])
          return request.pipe(
              flatMap((response) => {
                  return Observable.of(
                    AnnotationActions.getAnnotationCampaignsSuccess(response[0]),
                    AnnotationActions.getAnnotationStatesSuccess(response[1]),
                    AnnotationActions.getAnnotationBusinessLine(response[2]),
                    push(`/annotation`, { campaigns: response[0], states: response[1] , businessLine:response[2]})
                  )
              }),
              catchError((e) => {
                if(e.statusCode === 401){   
                  return Observable.of(LoginActions.submitLogout())
                }else {
                  return Observable.of(
                    push(`/oops`)
                  )
                }
              })
          );
      })
  );

  export const getAnnotationsDetail = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ANNOTATION_DETAIL"),
    flatMap(action => {
      const annotation_campaign_id = action.annotation_campaign_id;
      const campaign_id = action.campaign_id;
      const url = { 
        id: annotation_campaign_id,
        annotationState: "",
        startSelection: "",
        endSelection: "",
      };
      return Observable.from(
        App.api.a2censo.getAnnotationDetail({ url })
      ).pipe(
        flatMap((items) => {
          return Observable.of(
            AnnotationActions.getAnnotationDetailSuccess(items, annotation_campaign_id, campaign_id),
            push(`/annotation-detail`)
          )
        }),
        catchError((response) => {
          if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const getAnnotationsDetailFilteredData = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ANNOTATION_DETAIL_FILTERED_DATA"),
    flatMap(action => {
      const annotation_campaign_id = action.annotation_campaign_id;
      const campaign_id = action.campaign_id;
      const url = { 
        id: annotation_campaign_id,
        annotationState: action.annotationStateFilter? action.annotationStateFilter: "",
        startSelection: action.fromFilter? action.fromFilter: "",
        endSelection: action.toFilter? action.toFilter: "",
      };
      return Observable.from(
        App.api.a2censo.getAnnotationDetail({ url })
      ).pipe(
        flatMap((items) => {
          return Observable.of(
            AnnotationActions.getAnnotationDetailSuccess(items, annotation_campaign_id, campaign_id)
          )
        }),
        catchError((response) => {
          if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const getEmissionData = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_EMISSION_DATA"),
    flatMap(action => {
      const url = {id: action.emissionData};
      return Observable.from(
        App.api.a2censo.getEmissionData({ url })
      ).pipe(
        flatMap((emissionData) => {
          return Observable.of(
            AnnotationActions.getEmissionDataSuccess(emissionData)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AnnotationActions.getEmissionDataFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const getStatesForChange = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_STATES_FOR_CHANGE"),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.getStatesForChange()
      ).pipe(
        flatMap((statesForChange) => {
          return Observable.of(
            AnnotationActions.getStatesForChangeSuccess(statesForChange)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  //AnnotationActions.getAnnotationDetailFail(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const getAnnotationsHist = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ANNOTATION_HIST"),
    flatMap(action => {
      const url = {id: action.hist};
      return Observable.from(
        App.api.a2censo.getAnnotationHist({ url })
      ).pipe(
        flatMap((hist) => {
          return Observable.of(
            AnnotationActions.getAnnotationHistSuccess(hist),
            push(`/annotation-hist`)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AnnotationActions.getAnnotationDetailFail(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const getAnnotationCampaign = action$ =>
    action$.pipe(
        ofType("SUBMIT_GET_ANNOTATIONS"),
        flatMap((action) => {
            const url = { 
              dateAnnotationSelected: action.dateAnnotationSelected? action.dateAnnotationSelected: "",
              dateCampaignSelected: action.dateCampaignSelected? action.dateCampaignSelected: "",
              campaignState: action.campaignState? action.campaignState: "", 
              businessLine: action.businessLine? action.businessLine: "", 
              annotationState: action.annotationState? action.annotationState: "",
              
            };
             console.log("url", url);
            let request = Observable.from(App.api.a2censo.getAnnotations({ url }));
            return request.pipe(
                flatMap((response) => {
                    return Observable.of(
                      AnnotationActions.getAnnotationsSuccess({ response })
                    )
                }),
                catchError((e) => {
                    if (e.statusCode === 401) {
                        return Observable.of(LoginActions.submitLogout())
                    } else {
                      return Observable.of(
                          push(`/oops`)
                      )
                    }
                })
            );
        })
    );

  export const saveEmissionData = action$ =>
  action$.pipe(
    ofType("SAVE_EMISSION_DATA_BATCH"),
    flatMap(action => {
      const data = {
        body: AnnotationSerializer().serialize(action.data),
        url: {id: action.data.id}
      }
      return Observable.from(
        App.api.a2censo.saveEmissionData({
          ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            AnnotationActions.saveEmissionDataBatchSuccess(response.message),
            startGetAnnotationStates()
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AnnotationActions.actionFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );


  export const annotate = action$ =>
  action$.pipe(
    ofType("ANNOTATE_BATCH"),
    flatMap(action => {
      const data = {
        body: AnnotateSerializer().serialize(action.data),
        url: {id: action.data.campaign_id}
      }
      console.log("data", data);
      return Observable.from(
        App.api.a2censo.annotate({
          ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            AnnotationActions.annotateBatchSuccess(response)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AnnotationActions.actionFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const changeState = action$ =>
  action$.pipe(
    ofType("SAVE_NEW_STATE"),
    flatMap(action => {
      const data = {
        body: ChangeStateSerializer().serialize(action.data),
        url: {annotation_detail_id: action.annotation_detail_id}
      }
      return Observable.from(
        App.api.a2censo.changeState({
          ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            AnnotationActions.saveNewStateSuccess(response.message)
          )
        }),
        catchError((response) => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AnnotationActions.actionFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

const startGetAnnotationStates = () => ({
  type: "GET_ANNOTATION_STATES"
});

export const getAnnotationStates = action$ =>
  action$.pipe(
      ofType("GET_ANNOTATION_STATES"),
      flatMap(() => {
          let request;
          request = forkJoin([
            Observable.from(App.api.a2censo.getAnnotationCampaigns()),
            Observable.from(App.api.a2censo.getAnnotationStates())
          ])
          return request.pipe(
              flatMap((response) => {
                  return Observable.of(
                    AnnotationActions.getAnnotationCampaignsSuccess(response[0]),
                    AnnotationActions.getAnnotationStatesSuccess(response[1])
                  )
              }),
              catchError((e) => {
                if(e.statusCode === 401){   
                  return Observable.of(LoginActions.submitLogout())
                }else {
                  return Observable.of(
                    push(`/oops`)
                  )
                }
              })
          );
      })
  );


export default combineEpics(annotationShowcase, getAnnotationsDetail, getAnnotationsDetailFilteredData, getAnnotationCampaign, getAnnotationsHist, getEmissionData, saveEmissionData, annotate, getStatesForChange, changeState, getAnnotationStates);
