const { Serializer } = require("jsonapi-serializer");

module.exports = {
  fileChargeSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "file_name",
        "document_name",
        "detail_type",
        "document_stage_id",
        "document_id",
        "request_campaign_id",
        "user_email",
        "user_ip",
        "withURL"
      ]
    })
};
