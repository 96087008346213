import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import RegisterActions, { RegisterTypes } from "../reducers/campaign-detail.reducer";
import { RegisterSerializer } from "../../lib/serializers/registerDetail.serializer";
import LoginActions from "../reducers/login.reducer";
import CampaignActions from "../reducers/campaign.reducer";
import axios from "axios";


export const registerDetailSubmit = action$ =>
  action$.pipe(
    ofType(RegisterTypes.SUBMIT_REGISTER_DETAIL),
    flatMap(action => {
      let detail_id = "";
      let file_path = [];
      return Observable.from(
        App.api.a2censo.registerDetail({
          body: RegisterSerializer().serialize(action.data.detail)
        })
      ).pipe(
        action.data.detail.files.length > 0 ?
        flatMap((response) => {
          detail_id = response.details.id;
          file_path = response.temp_file_url;
          return Observable.from(
            action.data.detail.files.map((file, index) => {
              axios.put(response.temp_file_url[index], file.file, {
                headers: { "Content-Type": file.file.type },
              })
            })
          ).pipe(
            flatMap(() => {
              return Observable.concat(
                Observable.of(RegisterActions.registerDetailSuccess()),
                Observable.of(CampaignActions.postLoad({
                  status: "OK",
                  createDetail: true
                })),
                Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign_id}`))
              )
            })
          )
        }) : 
        flatMap(() => {
          return Observable.concat(
            Observable.of(RegisterActions.registerDetailSuccess()),
            Observable.of(CampaignActions.postLoad({
              status: "OK",
              createDetail: true
            })),
            Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign_id}`))
          )
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            if(detail_id && file_path.length > 0){
              const data = {
                url: { id: detail_id, user_email: action.data.detail.user_email, user_ip: action.data.detail.user_ip }
              }
              Observable.from(
                App.api.a2censo.deleteDetail({
                  ...data
                })
              )
            }
            return Observable.concat(
              Observable.of(CampaignActions.postLoad({
                status: "ERROR",
                createDetail: true
              })),
              Observable.of(RegisterActions.registerDetailFail(response)),
              Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign_id}`))
            );
          }
        })
      );
    })
  );

export const updateSubmitDetail = action$ =>
  action$.pipe(
    ofType(RegisterTypes.UPDATE_REGISTER_DETAIL),
    flatMap(action => {
      let lastUrl = ""
      let lastColum = ""
      const idCampaign = parseInt(action.data.detail.id);
      const data = {
        body: RegisterSerializer().serialize(action.data.detail),
        url: { id: idCampaign }
      }
      return Observable.from(
        App.api.a2censo.updateDetail({
          ...data
        })
      ).pipe(
        action.data.detail.files.length > 0 ?
        flatMap((response) => {
          lastUrl = response.last_path?.path;
          lastColum = response.last_path?.colum;
          return Observable.from(
            action.data.detail.files.map((file, index) => {
              axios.put(response.temp_file_url[index], file.file, {
                headers: { "Content-Type": file.file.type },
              })
            })
          ).pipe(
            flatMap(() => {
              return Observable.concat(
                Observable.of(RegisterActions.registerDetailSuccess()),
                Observable.of(CampaignActions.postLoad({
                  status: "OK",
                  editDetail: true
                })),
                Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign_id}`))
              )
            }),
          )
        }) :
        flatMap(() => {
          return Observable.concat(
            Observable.of(RegisterActions.registerDetailSuccess()),
            Observable.of(CampaignActions.postLoad({
              status: "OK",
              editDetail: true
            })),
            Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign_id}`))
          )
        }),
        catchError(e => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            if(lastUrl){
              lastColum == "url_image" ? data.body.data.attributes.url_image = lastUrl : data.body.data.attributes.url = lastUrl;
              lastColum == "url_image" ? data.body.data.attributes.url = "" : data.body.data.attributes.url_image = "";
              data.body.data.attributes.files = [];
              const updateData = data;
              Observable.from(
                App.api.a2censo.updateDetail({
                  ...updateData
                })
              )
            }
            return Observable.concat(
              Observable.of(CampaignActions.postLoad({
                status: "ERROR",
                editDetail: true
              })),
              Observable.of(RegisterActions.registerDetailFail(e)),
              Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign_id}`))
            );
          }
        })
      );
    })
  );

export const deleteCampaignDetail = action$ =>
  action$.pipe(
    ofType(RegisterTypes.DELETE_CAMPAIGN_DETAIL),
    flatMap(action => {
      const idCampaign = parseInt(action.data.detail.id);
      const data = {
        url: { id: idCampaign, user_email: action.data.detail.user_email, user_ip: action.data.detail.user_ip }
      }
      return Observable.from(
        App.api.a2censo.deleteDetail({
          ...data
        })
      ).pipe(
        flatMap(() => {
          return Observable.concat(
            Observable.of(RegisterActions.registerDetailSuccess()),
            Observable.of(CampaignActions.postLoad({
              status: "OK",
              deleteDetail: true
            })),
            Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign}`))
          )
        }),
        catchError(e => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.postLoad({
                status: "ERROR",
                deleteDetail: true
              })),
              Observable.of(RegisterActions.registerDetailFail(e)),
              Observable.of(push(`/CampaignEditForm/${action.data.detail.campaign}`))
            );
          }
        })
      );
    })
  );

export default combineEpics(registerDetailSubmit, updateSubmitDetail, deleteCampaignDetail);
