import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  startCompanyForm: ["data"],
  eraseRegisterCompanyData: [],
  eraseCompanyData: [""],
  updateCompanyAction: ["action"],
  updateValidateData: ["action"],
  updateAssociates: ["associates"],
  updateLegalRepresentative: ["legalRepresentative"],
  updateCompany: ["company"],
  eraseLegalRepresentative: [],
  startValidateCompany: ["data"]
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  action: "create",
  companyId: null,
  formData: {
    document_type: "",
    document_number: "",
    business_name: "",
    constitution_date: "",
    city_id: "",
    address: "",
    ciiu: "",
    logo_path: "",
    description: "",
    second_ciiu: "",
    company_code: "",
    amount: "",
    bank_id: "",
    bank_account_type_id: "",
    account_number: "",
    created_at: "",
    updated_at: "",
    user_id: "",
    department_id: "",
    dv_number: "",
    phone_number: "",
    city_retention: "",
    city_consignment: "",
  },
  associates: [],
  formDataLegalRepresentative: {
    document_type:"",
    document_number:"",
    name:"",
    last_name:"",
    second_name:"",
    second_last_name:"",
    document_expedition_date:"",
    expedition_place:"",
    expedition_country:"",
    manage_public_resources:"",
    send_usa_resources:"",
    publicly_exposed_person:"",
    company_id:"",
    percentage_shareholding:"",
    ciuu_code:"",
    city_id:"",
    person_type:"",
    created_at:"",
    updated_at:"",
    dv:"",
    department_id:"",
    quality_tax:""
  },
  validateData: ""
};

const startCompanyForm = state =>
  Object.assign({}, state, {
    ...INITIAL_STATE
  });

const startValidateCompany = state =>
  Object.assign({}, state, {
    ...INITIAL_STATE
  });

const updateCompanyAction = (state, { action: {action, companyId, associates, legalRepresentative , item}}) => 
  Object.assign({}, state, {
    action: action,
    companyId: companyId,
    associates: associates,
    formDataLegalRepresentative: legalRepresentative,
    formData: item
  });

const updateValidateData = (state, { action: {data}}) => 
  Object.assign({}, state, {
    validateData: data
  });
  
const eraseRegisterCompanyData = state =>
  Object.assign({}, state, {
    action: "create",
    companyId: null,
    formData: INITIAL_STATE.formData,
    associates: [],
    formDataLegalRepresentative: INITIAL_STATE.formDataLegalRepresentative
  });
  
const eraseCompanyData = state =>
  Object.assign({}, state, {
    validateData: ""
  });

  const updateAssociates = (state, {associates}) => 
    Object.assign({}, state, {
      associates: associates
    });

  const updateLegalRepresentative = (state, {legalRepresentative}) => 
    Object.assign({}, state, {
      formDataLegalRepresentative: legalRepresentative
    });
  
  const updateCompany = (state, {company}) =>
    Object.assign({}, state, {
      formData: company
    });
  
  const eraseLegalRepresentative = state =>
    Object.assign({}, state, {
      formDataLegalRepresentative: INITIAL_STATE.formDataLegalRepresentative
    });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ERASE_REGISTER_COMPANY_DATA]: eraseRegisterCompanyData,
  [Types.ERASE_COMPANY_DATA]: eraseCompanyData,
  [Types.UPDATE_COMPANY_ACTION]: updateCompanyAction,
  [Types.UPDATE_VALIDATE_DATA]: updateValidateData,
  [Types.UPDATE_ASSOCIATES]: updateAssociates,
  [Types.UPDATE_LEGAL_REPRESENTATIVE]: updateLegalRepresentative,
  [Types.UPDATE_COMPANY]: updateCompany,
  [Types.START_COMPANY_FORM]: startCompanyForm,
  [Types.ERASE_LEGAL_REPRESENTATIVE]: eraseLegalRepresentative,
  [Types.START_VALIDATE_COMPANY]: startValidateCompany
});
