import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  eraseMessage: [],
  submitCreateMessage: [
    "message_text",
    "stages",
    "user_email",
    "user_ip"
  ],
  createMessageSuccess: ["message"],
  submitEditMessage: [
    "message_id",
    "message_text",
    "stages",
    "active",
    "user_email",
    "user_ip"
  ],
  editMessageSuccess: ["message"],
  submitGetResponseManagementList: ["stage"],
  getResponseManagementListSuccess: ["responseManagementList"],
  errorActionResponseManagement: ["error"]
});

export const responseManagementTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: "",
  message: "",
  gettingData: false,
  responseManagementList: ""
};

const eraseMessage = state =>
  Object.assign({}, state, {
    message: '',
    error: ''
  });

const submitCreateMessage = state =>
  Object.assign({}, state, {
    isFetching: true,
    gettingData: true,
  });

const createMessageSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });

const submitEditMessage = state =>
  Object.assign({}, state, {
    isFetching: true,
    gettingData: true,
  });

const editMessageSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });

const submitGetResponseManagementList = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true,
  });

const getResponseManagementListSuccess = (state, { responseManagementList }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    responseManagementList
  });

  
const errorActionResponseManagement = (state,  { error } ) =>
  Object.assign({}, state, {
    isFetching: false,
    error: error.message
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.SUBMIT_CREATE_MESSAGE]: submitCreateMessage,
  [Types.CREATE_MESSAGE_SUCCESS]: createMessageSuccess,
  [Types.SUBMIT_EDIT_MESSAGE]: submitEditMessage,
  [Types.EDIT_MESSAGE_SUCCESS]: editMessageSuccess,
  [Types.SUBMIT_GET_RESPONSE_MANAGEMENT_LIST]: submitGetResponseManagementList,
  [Types.GET_RESPONSE_MANAGEMENT_LIST_SUCCESS]: getResponseManagementListSuccess,
  [Types.ERROR_ACTION_RESPONSE_MANAGEMENT]: errorActionResponseManagement
});
