import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetPaymentCampaign: ["campaignSelectedId", "stateSelectedId"],
  getPaymentCampaignSuccess: ["campaignData"],
  submitGetPaymentDetail: ["data"],
  getPaymentDetailSuccess: ["data"],
  setCampaignId: ["id"],
  setStateId: ["id"],
  eraseMessage: [""],
  actionFailed: ["error"],
});

export const PaymentTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data: "",
  message: "",
  campaignData: "",
  error: "",
  campaignSelected: "",
  stateSelected: ""
};

const eraseMessage = state =>
  Object.assign({}, state, {
    message: "",
    error: ""
  });

const setCampaignId = (state, { id }) =>
  Object.assign({}, state, {
    campaignSelected: id
  });

const setStateId = (state, { id }) =>
  Object.assign({}, state, {
    stateSelected: id
  });

const submitGetPaymentCampaign = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getPaymentCampaignSuccess = (state, { campaignData }) =>
  Object.assign({}, state, {
    isFetching: false,
    campaignSelected: campaignData.id,
    stateSelected: campaignData.id,
    campaignData: campaignData.payment
  });

const submitGetPaymentDetail = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getPaymentDetailSuccess = (state, { data }) =>
  Object.assign({}, state, {
    isFetching: false,
    data
  });


const actionFailed = (state, { error: responseError }) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_PAYMENT_CAMPAIGN]: submitGetPaymentCampaign,
  [Types.GET_PAYMENT_CAMPAIGN_SUCCESS]: getPaymentCampaignSuccess,
  [Types.SUBMIT_GET_PAYMENT_DETAIL]: submitGetPaymentDetail,
  [Types.GET_PAYMENT_DETAIL_SUCCESS]: getPaymentDetailSuccess,
  [Types.ACTION_FAILED]: actionFailed,
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.SET_CAMPAIGN_ID]: setCampaignId,
  [Types.SET_STATE_ID]: setStateId
});
