import React, { useState , useRef } from "react";
import IdletTimer from 'react-idle-timer';

const IdleTimerContainer = props =>{

  const idleTimerRef = useRef(null)

  const onIdle = () =>{
    console.log("Sesión cerrada por inactividad!");
    props.action(true);
  }

  return(
    <div>
      <IdletTimer 
        ref={idleTimerRef}
        timeout={props.minuts*60*1000}
        onIdle={onIdle}
      />
    </div>
  );
}


export default IdleTimerContainer;