const { Serializer } = require("jsonapi-serializer");

module.exports = {
  publicationResultSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_id",
        "action_req",
        "simulator",
        "featured",
        "com_percentage",
        "justification",
        "comments",
        "bvc_share",
        "pyme_name",
        "pyme_email",
        "user_email",
        "user_ip",
        "financial_doc_name",
        "functionality_id", 
        "msg_id",
        "reject_html",
        "reject_msg",
        "close_date",
        "available_investment_date"
      ]
    })
};
