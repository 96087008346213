export const fileToBase64 = (e) => {
    e.preventDefault();
    return new Promise(resolve => {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

export const fileToBinary = (file) =>
  new Promise((resolve) => {
    const r = new FileReader();
    r.onload = () => {
      resolve(r.result);
    };
    r.readAsArrayBuffer(file);
  });
