import React, { Fragment } from "react";
import LoaderSVG from "../../components/LoaderSVG/LoaderSVG";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../Loader/Loader.scss";

const Loader = (location) => (
  <Fragment>
  {location.headerHidden?
  <div className="loader-headerHidden">
    <LoaderSVG />
    <p className="loader__copy card-title">Cargando contenido</p>
  </div> :
  <div>
    <Header />
    <div className="container loader">
      <LoaderSVG />
      <p className="loader__copy card-title">Cargando contenido</p>
    </div>
    <Footer />
  </div> }
  </Fragment>
);

export default Loader;
