const { Serializer } = require("jsonapi-serializer");

module.exports = {
  AwardCampaignSerializer: (meta = {}) =>
    new Serializer("awardCampaignSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "type",
        "data",
        "email"
      ]
    })
};
