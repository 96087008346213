const { Serializer } = require("jsonapi-serializer");

module.exports = {
  createOrUpdateNovelty: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "novelty_id",
        "novelty_type",
        "title",
        "short_description",
        "multimedia_type",
        "multimedia",
        "multimedia_name",
        "multimedia_external_type",
        "ext_multimedia",
        "ext_multimedia_name",
        "is_active",
        "campaign_id",
        "publish_at",
        "user_email",
        "user_ip"
        
      ]
    })
};
