import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  eraseRegisterData: [],
  updateAction: ["action"],
  updateDetails: ["details"],
  updateCampaign: ["campaign"],
  updateCompanies: ['companies', 'avaliableDateAndCloseDate'],
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  action: "create",
  campaignId: null,
  formData: {
    name: "",
		image: "",
		description: "",
		video_path: "",
		close_date: "",
		guaranteed_investment_percentage: "",
		risk_classification_id: "",
    interest_rate: "",
    requested_amount: "",
    interest_grace_period: "",
    capital_grace_period: "",
    interest_payment: "",
    capital_payment: "",
		sector_id: "",
    featured: "",
    campaign_serie:"",
    origination_fee:"",
    fng_fee:"",
    _campaign_state_id:"1",
    minimal_amount:"",
    company_id: "",
    state: "1",
    rate_type_id: "",
    term_in_months: "",
    campaign_simulator: "",
  },
  details: [],
  companies: null,
  avaliableDateAndCloseDate: null
};

const updateAction = (state, { action: {action, campaignId, details, item, listPymes}}) => 
  Object.assign({}, state, {
    action: action,
    campaignId: campaignId,
    details: details,
    formData: item,
    listPymes: listPymes
  });

const eraseRegisterData = state =>   
  Object.assign({}, state, {
    action: "create",
    campaignId: null,
    formData: INITIAL_STATE.formData,
    details: []
  });

const updateDetails = (state, {details}) => 
  Object.assign({}, state, {
    details: details
  });

const updateCompanies = (state, {companies, avaliableDateAndCloseDate}) => 
  Object.assign({}, state, {
    companies: companies,
    avaliableDateAndCloseDate: avaliableDateAndCloseDate
  });
  
const updateCampaign = (state, {campaign}) =>
  Object.assign({}, state, {
    formData: campaign
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ERASE_REGISTER_DATA]: eraseRegisterData,
  [Types.UPDATE_ACTION]: updateAction,
  [Types.UPDATE_DETAILS]: updateDetails,
  [Types.UPDATE_CAMPAIGN]: updateCampaign,
  [Types.UPDATE_COMPANIES]: updateCompanies
});
