const { Serializer } = require("jsonapi-serializer");

module.exports = {
    certificatesSerializer: (meta = {}) =>
        new Serializer("certificatesSerializer", {
            meta,
            pluralizeType: false,
            keyForAttribute: "snake_case",
            attributes: ["company_id", "withholding_city", "consignment_city", "company_city", "pyme_address", "observation", "user_email", "user_ip"]
        })
};