import { catchError, flatMap, map } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import RegisterFormActions from "../reducers/campaign-form.reducer";
import CampaignActions from "../reducers/campaign.reducer";
import LoginActions from "../reducers/login.reducer";
import { EmailSerializer } from "../../lib/serializers/email.serializer";
import { dispersionSerializer } from "../../lib/serializers/dispersion.serializer";
import { removeInvestorSerializer } from "../../lib/serializers/removeInvestor.serializer";
import { inactivateCampaignSerializer } from "../../lib/serializers/inactivateCampaign.serializer";
import { fileChargeSerializer } from "../../lib/serializers/fileCharge.serializer";
import { requestFileWithURL } from "../../components/LoadDocument/LoadDocument";
import { forkJoin } from "rxjs";
import { LoadSerializer } from "../../lib/serializers/loadCampaign.serializer";

export const startCampaignsShowcase = () => ({
  type: "START_CAMPAIGNS_SHOWCASE"
});

export const submitUploadZipCampaing = (data, user_email, user_ip) => ({
  type: "SUBMIT_UPLOAD_ZIP_CAMPAIGNS",
  data,
  user_email,
  user_ip
});

export const startUnZipCampaing = (file_name) => ({
  type: "GET_UN_ZIP_CAMPAIGNS",
  file_name
});

export const startDataExcelCampaign = (file, arrFile) => ({
  type: "GET_DATA_EXCEL_CAMPAIGNS",
  file,
  arrFile
});

export const startSectionExcelCampaign = (file, arrFile) => ({
  type: "GET_SECTION_EXCEL_CAMPAIGNS",
  file,
  arrFile
});

export const loadCampaign = (body) => ({
  type: "POST_LOAD_CAMPAIGNS",
  body
});

export const startGetCompanies = () => ({
  type: "GET_COMPANIES"
});

export const startParametersCampaign = (tables) => ({
  type: "START_PARAMETERS_CAMPAIGN",
  tables
});

export const getCampaingData = (campaignId, tables) => ({
  type: "GET_CAMPAING_DATA",
  campaignId,
  tables
});

export const campaignsShowcase = action$ =>
  action$.pipe(
    ofType("START_CAMPAIGNS_SHOWCASE"),
    flatMap(() => {
      let request;
      request = forkJoin([
        Observable.from(App.api.a2censo.getBusinessLine()),
        Observable.from(App.api.a2censo.getCampaignStates())
      ])
      return request.pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.campaignBusinessLine(response[0]),
            CampaignActions.campaignStates(response[1]),
            push(`campaigns`, { response })
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const editCampaign = action$ =>
  action$.pipe(
    ofType("START_GET_CAMPAIGN_DETAILS"),
    flatMap(action => {
      const url = { id: action.data };
      return Observable.from(
        App.api.a2censo.getCampaign({
          url
        })
      ).pipe(
        flatMap((item) => {
          return Observable.of(
            CampaignActions.getCampaignDetails(),
            RegisterFormActions.updateAction({ action: 'edit', campaignId: item.id, details: item.details, item }),
            startGetCompanies(),
            push(`campaignInvestors`)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(
              CampaignActions.getCampaignDetails(),
              LoginActions.submitLogout()
            )
          } else {
            return Observable.of(
              CampaignActions.getCampaignDetails(),
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const createCampaign = action$ =>
  action$.pipe(
    ofType("ERASE_REGISTER_DATA"),
    flatMap(() => {
      return Observable.of(
        startGetCompanies(),
        push(`campaignForm`)
      )
    })
  );

export const getCompanies = action$ =>
  action$.pipe(
    ofType("GET_COMPANIES"),
    flatMap(() => {
      let request;
      request = forkJoin([
        Observable.from(App.api.a2censo.getAvaliableDateAndCloseDate()),
        Observable.from(App.api.a2censo.getPymeList())
      ])
      return request.pipe(
        flatMap((response) => {
          const companies = [];
          const avaliableDateAndCloseDate = response[0];
          if (response[1].length) {
            response[1].forEach((item) => {
              companies.push({ value: item.id, text: item.business_name })
            })
          }
          return Observable.of(
            RegisterFormActions.updateCompanies(companies, avaliableDateAndCloseDate)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      )
    })
  )

export const startParameters = action$ =>
  action$.pipe(
    ofType("START_PARAMETERS_CAMPAIGN"),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: action.tables }
        })
      ).pipe(
        map(response => {
          RegisterFormActions.eraseRegisterData()
          return push(`campaignInvestors`, { response })
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      )
    })
  );

export const dispersionCampaign = action$ =>
  action$.pipe(
    ofType("DISPERSION_CAMPAIGN"),
    flatMap(action => {
      const fng_fee = action.item.fngFee
      const origination_fee = action.item.successFee
      const expedition_date = action.item.emissionExpeditionDate
      const due_date = action.item.emissionDueDate
      const data = {
        body: dispersionSerializer().serialize({
          fng_fee,
          origination_fee,
          expedition_date,
          due_date
        }),
        url: {
          id: action.campaign.id
        }
      }
      return Observable.from(
        App.api.a2censo.dispersionPyme({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.dispersionCampaignSucces(response.message),
            _getCampaigns()
          )
        }),
        catchError((response) => {
          if (response.data) {
            return Observable.concat(
              Observable.of(
                CampaignActions.dispersionCampaignFail(response.data.attributes.message),
                push(`campaigns`)
              )
            );
          } else if (response.statusCode === 401) {
            return Observable.of(
              CampaignActions.eraseData(),
              LoginActions.submitLogout()
            )
          } else {
            return Observable.concat(
              Observable.of(
                CampaignActions.eraseData(),
                push(`oops`)
              )
            );
          }
        })
      )
    })
  );

export const annotateCampaign = action$ =>
  action$.pipe(
    ofType("ANNOTATE_CAMPAIGN"),
    flatMap(action => {
      const email = action.data.email
      const url = { id: action.data.item.id }
      return Observable.from(
        App.api.a2censo.annotateCampaign({
          body: EmailSerializer().serialize({ email }),
          url: url
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.annotationCampaignSuccess(response),
            push(`campaigns`)
          )
        }),
        catchError((response) => {
          if (response.data) {
            return Observable.concat(
              Observable.of(
                CampaignActions.annotationCampaignFail(response.data.attributes.message),
                push(`campaigns`)
              )
            );
          } else if (response.statusCode === 401) {
            return Observable.of(
              CampaignActions.eraseData(),
              LoginActions.submitLogout()
            )
          } else {
            return Observable.concat(
              Observable.of(
                CampaignActions.eraseData(),
                push(`oops`)
              )
            );
          }
        })
      )
    })
  );

export const loadFileZ = action$ =>
  action$.pipe(
    ofType("LOAD_FILE_Z"),
    flatMap(({ data: { files, email, itemChanging } }) => {
      const json = JSON.stringify({ email: email, campaignId: itemChanging });
      const blob = new Blob([json], { type: 'application/json' });
      files.set('data', blob)
      return Observable.from(
        App.api.a2censo.loadFileZ({
          body: files
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.loadFileZSuccess(response),
            push(`campaigns`)
          )
        }),
        catchError((response) => {
          if (response.data) {
            return Observable.concat(
              Observable.of(
                CampaignActions.loadFileZFaild(response.data.attributes.message)
              )
            );
          } else if (response.statusCode === 401) {
            return Observable.of(
              CampaignActions.eraseData(),
              LoginActions.submitLogout()
            )
          } else {
            return Observable.concat(
              Observable.of(
                CampaignActions.eraseData(),
                push(`oops`)
              )
            );
          }
        })
      )
    })
  );

export const getCampaignTypeList = action$ =>
  action$.pipe(
    ofType("START_CAMPAIGNS_SHOWCASE"),
    flatMap(() => {
      return Observable.from(App.api.a2censo.getCampaignTypeList()
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            push(`campaigns`, { response })
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getCampaignStateHistory = action$ =>
  action$.pipe(
    ofType("GET_CAMPAIGN_STATE_HISTORY"),
    flatMap((action) => {
      const url = { campaign_id: action.campaign_id }
      return Observable.from(App.api.a2censo.getCampaignStateHistory({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.getCampaignStateHistorySuccess(response)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const startGetCampaignInvestors = action$ =>
  action$.pipe(
    ofType("START_GET_CAMPAIGN_INVESTORS"),
    flatMap((action) => {
      const url = { campaign_id: action.campaign_id }
      return Observable.from(App.api.a2censo.getCampaignInvestors({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.getCampaignInvestorsSuccess(response)
          )
        }), catchError((response) => {
          return Observable.of(
            CampaignActions.getCampaignInvestorsFailed(response)
          )
        })
      );
    })
  );


export const startRemoveInvestor = action$ =>
  action$.pipe(
    ofType("START_REMOVE_INVESTOR"),
    flatMap((action) => {
      const investment_id = action.data.investment_id;
      const justification = action.data.justification;
      const user_email = action.data.user_email;
      const user_ip = action.data.user_ip;
      const data = {
        body: removeInvestorSerializer().serialize({
          investment_id,
          justification,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.removeInvestor(data)
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.removeInvestorSuccess(response.message)
          )
        }), catchError((response) => {
          return Observable.of(
            CampaignActions.removeInvestorFailed(response)
          )
        })
      );
    })
  );


export const inactivateCampaign = action$ =>
  action$.pipe(
    ofType("SUBMIT_INACTIVATE_CAMPAIGN"),
    flatMap(action => {
      const campaign_id = action.campaign_id
      const user_email = action.user_email
      const user_ip = action.user_ip
      const data = {
        body: inactivateCampaignSerializer().serialize({
          campaign_id,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.inactivateCampaign({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.inactivateCampaignSuccess(response),
            startCampaignsShowcase()
          )
        }), catchError((response) => {
          return Observable.of(
            CampaignActions.inactivateCampaignFailed(response)
          )
        })
      );
    })
  );

export const getAllCampaigns = action$ =>
  action$.pipe(
    ofType("GET_ALL_CAMPAIGNS"),
    flatMap(action => {
      const state = action.state
      const business_line_id = action.business_line_id
      const url = { state, business_line_id }
      // console.log(url);
      return Observable.from(App.api.a2censo.getCampaigns({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.getAllCampaignsSuccess(response)
          )
        }), catchError((response) => {
          return Observable.of(
            CampaignActions.getAllCampaignsFailed(response)
          )
        })
      );
    })
  );
const _getCampaigns = () => ({
  type: "GET_CAMPAIGNS"
});

export const getCampaigns = action$ =>
  action$.pipe(
    ofType("GET_CAMPAIGNS"),
    flatMap(() => {
      return Observable.from(App.api.a2censo.getCampaignList()
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            CampaignActions.getCampaignsSuccess(response)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const uploadZipCampaing = action$ =>
  action$.pipe(
    ofType("SUBMIT_UPLOAD_ZIP_CAMPAIGNS"),
    flatMap(action => {
      const file = action.data.file;
      const file_name = action.data.file.name;
      const document_name = file_name.split(".")[0];
      const detail_type = file_name.split(".")[1];
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      return Observable.from(
        App.api.a2censo.uploadZip({
          body: fileChargeSerializer().serialize({ file_name, detail_type, document_name, user_email, user_ip })
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CampaignActions.uploadZipSuccess(response));
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.uploadZipSuccess({ errorUpZip: true }))
            );
          }
        })
      );
    })
  );

export const getUnZipCampaing = action$ =>
  action$.pipe(
    ofType("GET_UN_ZIP_CAMPAIGNS"),
    flatMap(action => {
      const url = {
        file: action?.file_name
      };
      return Observable.from(
        App.api.a2censo.unZip({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CampaignActions.unZipSuccess(response));
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.unZipSuccess({ errorUpZip: true }))
            );
          }
        })
      );
    })
  );

export const getDataExcelCampaign = action$ =>
  action$.pipe(
    ofType("GET_DATA_EXCEL_CAMPAIGNS"),
    flatMap(action => {
      const url = {
        file: action?.file,
        arrFile: action?.arrFile,
      };
      return Observable.from(
        App.api.a2censo.dataExcelCampaign({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CampaignActions.getDataExcel(response));
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.getDataExcel({ errorDataExcel: true }))
            );
          }
        })
      );
    })
  );

export const getSectionExcelCampaign = action$ =>
  action$.pipe(
    ofType("GET_SECTION_EXCEL_CAMPAIGNS"),
    flatMap(action => {
      const url = {
        file: action?.file,
        arrFile: action?.arrFile,
      };
      return Observable.from(
        App.api.a2censo.sectionExcelCampaign({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CampaignActions.getSectionExcel(response));
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.getSectionExcel({ errorSectionExcel: true }))
            );
          }
        })
      );
    })
  );

export const postLoadCampaign = action$ =>
  action$.pipe(
    ofType("POST_LOAD_CAMPAIGNS"),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.register({
          body: LoadSerializer().serialize(action?.body)
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CampaignActions.postLoad(response));
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.postLoad(response))
            );
          }
        })
      );
    })
  );

export const campaingData = action$ =>
  action$.pipe(
    ofType("GET_CAMPAING_DATA"),
    flatMap((action) => {
      let request;
      request = forkJoin([
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: action.tables }
          })
        ),
        Observable.from(
          App.api.a2censo.getNewCampaign({
            url: { id: action.campaignId }
          })
        ),
        Observable.from(
          App.api.a2censo.nitbyPymes()
        ),
        Observable.from(
          App.api.a2censo.subsectionDetailType()
        ),
        Observable.from(
          App.api.a2censo.subsectionDetail()
        ),
        Observable.from(
          App.api.a2censo.campaignSection()
        ),
      ]);
      return request.pipe(
        flatMap((response) => {
          return Observable.concat(
            Observable.of(CampaignActions.setCampaingData(
              response
            )),
            Observable.of(push(`/CampaignEditForm/${action.campaignId}`, {
              response
            }))
          );
        }),
        catchError((response) => {
          console.log("response",response);
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getCampaign = (action$) =>
  action$.pipe(
    ofType("START_GET_CAMPAIGN"),
    flatMap((action) => {
      const url = { id: action.data };
      return Observable.from(
        App.api.a2censo.getCampaign({
          url,
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CampaignActions.setCampaingData(response));
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(push(`/oops`));
          }
        })
      );
    })
  );

export default combineEpics(startParameters, editCampaign, createCampaign, getCompanies, getCampaigns, getAllCampaigns,
  campaignsShowcase, dispersionCampaign, annotateCampaign, loadFileZ, getCampaignStateHistory, startGetCampaignInvestors,
  startRemoveInvestor, inactivateCampaign, uploadZipCampaing, getUnZipCampaing, getDataExcelCampaign, getSectionExcelCampaign,
  postLoadCampaign, campaingData, getCampaign);
