import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    startFormParameter: ["data"],
    updateParameterLoad: ["parameter"],
    parameterUpdateSuccess: ["response"],
    parameterUpdateFail: ["error"],
    eraseParameterData: [],
  });
  
export const ParameterLoadTypes = Types;
export default Creators;


export const INITIAL_STATE = {
    isFetching: false,
    messasge: "",
    error: "",
    formData: {
        id: "",
        name: "",
        bank_id:"",
        bank_account_type_id:"",
        account_number:"",
        value: "",
        description: ""
      },
  };

const startFormParameter = state =>
  Object.assign({}, state, {
    ...INITIAL_STATE
  });

  const updateParameterLoad = (state, {parameter}) => 
  Object.assign({}, state, {
    parameter: parameter
  });

const parameterUpdateSuccess = (state, {response: message}) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message,
    error: ""
  });

const parameterUpdateFail = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    message: ""
  });

const eraseParameterData = state =>
  Object.assign({}, state, {
    error: "",
    message: ""
  });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.START_FORM_PARAMETER]: startFormParameter,
    [Types.UPDATE_PARAMETER_LOAD]: updateParameterLoad,
    [Types.PARAMETER_UPDATE_SUCCESS]: parameterUpdateSuccess,
    [Types.PARAMETER_UPDATE_FAIL]: parameterUpdateFail,
    [Types.ERASE_PARAMETER_DATA]: eraseParameterData
});