const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ParameterSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "id",
        "name",
        "description",
        "bank_id",
        "bank_account_type_id",
        "account_number"
      ]
    })
};
