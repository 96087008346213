import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
//import * as serviceWorker from './serviceWorker';
import AppBooter from "./pages/App/AppBooter";

import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap-grid.min.css";

ReactDOM.render(<AppBooter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
