import * as Sentry from "@sentry/browser";

export default async function loadSentry(app) {
  if (app.config.isProduction && app.config.sentry.dsn) {
    const { sentry } = app.config;
    Sentry.init({ dsn: sentry.dsn });
    return Promise.resolve(Sentry);
  }
  return Promise.resolve();
}
