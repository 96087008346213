const { Serializer } = require("jsonapi-serializer");

module.exports = {
  LoadSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name_file",
        "nit_pyme",
        "business_line",
        "name",
        "image",
        "description",
        "risk_classification",
        "state",
        "requested_amount",
        "sector",
        "featured",
        "rate_type",
        "campaign_simulator",
        "available_investment_date",
        "close_date",
        "type",
        "_campaign_state_id",
        "capital_grace_period",
        "capital_payment",
        "interest_grace_period",
        "interest_payment",
        "interest_rate",
        "term_in_months",
        "guaranteed_investment_percentage",
        "fng_warranty_line",
        "national_guarantee_fund_commission",
        "expedition_date",
        "due_date",
        "code_isin",
        "user_closed",
        "annotation_succeed_date",
        "dispersal_succeed_date",
        "request_campaign_id",
        "company_classification",
        "request_campaign_percentage",
        "remaining_financing_value",
        "campaign_serie",
        "logo_path",
        "description_company",
        "origination_fee",
        "fng_fee",
        "minimal_amount",
        "emission_year",
        "commission_percentage",
        "campaign_subsection_detail",
        "user_email",
        "user_ip"
      ]
    })
};
