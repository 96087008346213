const { Serializer } = require("jsonapi-serializer");

module.exports = {
  documentChangeStateSerializer: (meta = {}) =>
    new Serializer("documentChangeStateSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["comments", "user_email", "user_ip"]
    })
};
