const { Serializer } = require("jsonapi-serializer");

module.exports = {
  editMessageSerializer: (meta = {}) =>
    new Serializer("editMessageSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "message_id",
        "message_text",
        "stages",
        "active",
        "user_email",
        "user_ip"
      ]
    })
};
