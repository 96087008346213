import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } =  createActions({
    userStateUpdate:["data"],
    userStateUpdateSuccess: ["response"],
    userStateUpdateFail: ["error"],
    eraseData: [""]
})

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = {
    isFetching: false,
    message: "",
    error: "",
    showMessage: false
};

const eraseData = state => 
  Object.assign({}, state, INITIAL_STATE);

const userStateUpdate = state => 
  Object.assign({}, state, {
    isFetching: true,
  });

const userStateUpdateSuccess = (state, {response: message}) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message,
    error: "",
    showMessage: true
  });

const userStateUpdateFail = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    showMessage: true,
    message: ""
  });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.USER_STATE_UPDATE]: userStateUpdate,
    [Types.USER_STATE_UPDATE_SUCCESS]: userStateUpdateSuccess,
    [Types.USER_STATE_UPDATE_FAIL]: userStateUpdateFail,
    [Types.ERASE_DATA]: eraseData
});