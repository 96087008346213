const { Serializer } = require("jsonapi-serializer");

module.exports = {
  CreatePartnerCoDebtorSerializer: (meta = {}) =>
    new Serializer("co_debtors", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_campaign_id",
        "name_pyme",
        "nit_pyme",
        "request_name",
        "company_id",
        "associates",
        "ip",
      ],
    }),
  CreateExternalCoDebtorSerializer: (meta = {}) =>
    new Serializer("co_debtors", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_campaign_id",
        "expedition_country",
        "second_name",
        "expedition_date",
        "expedition_place",
        "name",
        "last_name",
        "second_last_name",
        "document_type",
        "document_number",
        "dv",
        "is_external",
        "name_pyme",
        "nit_pyme",
        "request_name",
        "company_id",
        "ip",
      ],
    }),
  EditExternalCoDebtorSerializer: (meta = {}) =>
    new Serializer("co_debtors", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_campaign_id",
        "expedition_country",
        "second_name",
        "expedition_date",
        "expedition_place",
        "name",
        "last_name",
        "second_last_name",
        "document_type",
        "old_document_type",
        "document_number",
        "old_document_number",
        "dv",
        "name_pyme",
        "nit_pyme",
        "request_name",
        "company_id",
        "ip",
      ],
    }),
};
