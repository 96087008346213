import { catchError, flatMap, map } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import CompanyFormActions from "../reducers/company-form.reducer";
import RegisterActions, { RegisterTypes } from "../reducers/company.reducer";
import CityActions from "../reducers/city.reducer";
import { CompanySerializer } from "../../lib/serializers/company.serializer";
import RegisterFormActions from "../reducers/company-form.reducer";
import LoginActions from "../reducers/login.reducer";
import CompanyActions from "../../redux/reducers/company.reducer";
import { approvedPatrimonial } from "./patrimonial.js";

export const startCompaniesShowcase = () => ({
  type: "START_COMPANIES_SHOWCASE"
});

export const startParametersCompanie = (tables) => ({
  type: "START_PARAMETERS_COMPANIE",
  tables
});

export const companiesShowcase = action$ =>
  action$.pipe(
    ofType("START_COMPANIES_SHOWCASE"),
    flatMap(() => {
      return Observable.from(App.api.a2censo.getPymeList()
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            push(`/companies`, {response})
          )
        }),
        catchError((e) => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const editCompany = action$ =>
  action$.pipe(
    ofType("START_COMPANY_FORM"),
    flatMap(action => {
      const url = {id: action.data.id};
      return Observable.from(
        App.api.a2censo.getCompany({
          url
        })
      ).pipe(
        flatMap((item) => {
          action.data.department_id = item.department_id;
          action.data.phone_number = item.phone_number;
          action.data.city_retention = item.city_retention;
          action.data.city_consignment = item.city_consignment;
          console.log('action.data:',action.data);
          console.log('item:',item);
          return Observable.of(
            CityActions.eraseCities(),
            CompanyFormActions.updateCompanyAction({action:'edit', companyId: action.data.id, associates: item.associates, legalRepresentative: item.legal_representatives[0], item: action.data}),
            push(`company`)
          )
        }),
        catchError((e) => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const validateCompany = action$ =>
  action$.pipe(
    ofType("START_VALIDATE_COMPANY"),
    flatMap(action => {
      const url = {document: action.data.document};
      return Observable.from(
        App.api.a2censo.dataValidatePyme({
          url
        })
      ).pipe(
        flatMap((item) => {
          return Observable.of(
            CityActions.eraseCities(),
            CompanyFormActions.updateValidateData({data: item}),
          )
        }),
        catchError((e) => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const createCompany = action$ =>
  action$.pipe(
    ofType("ERASE_REGISTER_COMPANY_DATA"),
    flatMap(() => {
      return Observable.of(
        CityActions.eraseCities(),
        push(`company`)
      )
    })
  );

  export const startParameters = action$ =>
  action$.pipe(
    ofType("START_PARAMETERS_COMPANIE"),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: action.tables }
        })
      ).pipe(
        map(response => {
          CompanyFormActions.eraseRegisterCompanyData()
          return push(`company`, {response})
        }),
        catchError((e) => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      )
    })
  );

export const registerSubmit = action$ =>
  action$.pipe(
    ofType(RegisterTypes.SUBMIT_REGISTER_COMPANY),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.registerCompany({
          body: CompanySerializer().serialize(action.company)
        })
      ).pipe(
        flatMap((response) => {
          action.company["id"] = response.details.id;
          action.company["logo_path"] = response.details.logo_path;
          action.company["user_id"] = response.details.user_id;
          return Observable.concat(
            Observable.of(RegisterActions.registerSuccessCompany()),
            Observable.of(RegisterFormActions.updateCompanyAction({action:'edit', companyId: response.details.id, associates: [], item: action.company})),
            Observable.of(push(`/company`))
          )
        }),
        catchError(e => {
          if(e.data){
            return Observable.concat(
              Observable.of(
                RegisterActions.registerFailCompany(e.data.attributes.message)
              )
            );
          }else if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerFailCompany(e)),
              Observable.of(
                push(`/oops`)
              )
            );
          }
        })
      );
    })
  );

export const getValidateInfo = action$ =>
  action$.pipe(
    ofType('SUBMIT_GET_VALIDATE_INFO'),
    flatMap((action) => {
      let preChargedData = undefined;
      const url = {document_type: action.data.document_type, document_number: action.data.document_number};
      let request = Observable.from(App.api.a2censo.getValidateInfo({url}));
      return request.pipe(
        flatMap((response) => {
          preChargedData = response[0];
          return Observable.of(
            CompanyActions.getValidateInfoSuccess({preChargedData})
          )
        }),
        catchError((e) => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

  export const updateSubmit = action$ =>
    action$.pipe(
      ofType(RegisterTypes.UPDATE_REGISTER_COMPANY),
      flatMap(action => {
        const idCampaign = parseInt(action.company.id);
        const data = {
          body: CompanySerializer().serialize(action.company),
          url: {id: idCampaign}
        }  
        return Observable.from(
          App.api.a2censo.updateCompany({
            ...data
          })
        ).pipe(
          flatMap((response) => {
            if(response.logo_path){
              action.company.logo_path = response.logo_path;
            }
            return Observable.concat(
              Observable.of(RegisterActions.registerFailCompany("OK")),
              Observable.of(RegisterActions.registerSuccessCompany()),
              Observable.of(RegisterFormActions.updateCompany(action.company))
            )
          }),
          catchError(e => {
            if(e.data){
              return Observable.concat(
                Observable.of(
                  RegisterActions.registerFailCompany(e.data.attributes.message)
                )
              );
            }else if(e.statusCode === 401){   
              return Observable.of(LoginActions.submitLogout())
            }else {
              return Observable.concat(
                Observable.of(RegisterActions.registerFailCompany(e)),
                Observable.of(
                  push(`/oops`)
                )
              );
            }
          })
        );
      })
    );

export default combineEpics (companiesShowcase, getValidateInfo, startParameters, editCompany, createCompany, registerSubmit, updateSubmit, validateCompany);
