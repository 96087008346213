import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitLogout: ["logout"],
  submitLogin: ["credentials"],
  loginSuccess: ["tokens"],
  loginFail: ["errorAttempt"],
  prueba: []
});

export const LoginTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: "",
  lastEmailAttempted: "",
  accessToken: "",
  idToken: "",
  email: "",
  groups: [],
  funcionalitiesRole:[],
  secretKey: "",
  accessKeyId: "",
  sessionToken: ""
};

const submitLogout = state => 
  Object.assign({}, state, {
    isFetching: false,
    accessToken: "",
    idToken: "",
    error: "",
    email: "",
    groups: [],
    funcionalitiesRole:[],
    secretKey: "",
    accessKeyId: "",
    sessionToken: ""
  });

const submitLogin = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const loginSuccess = (state, { tokens: { accessToken, idToken, groups, email, awsCredentials, funcionalitiesRole } }) =>
  Object.assign({}, state, {
    isFetching: false,
    email,
    accessToken,
    idToken,
    error: "",
    groups,
    funcionalitiesRole,
    secretKey: awsCredentials.secret_key,
    accessKeyId: awsCredentials.access_key_id,
    sessionToken: awsCredentials.session_token
  });

const loginFail = (state,{ errorAttempt: { error } }) =>
  Object.assign({}, state, {
    isFetching: false,
    error
  });

const prueba = state =>
  Object.assign({}, state, {
    isFetching: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_LOGOUT]: submitLogout,
  [Types.SUBMIT_LOGIN]: submitLogin,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAIL]: loginFail,
  [Types.PRUEBA]: prueba
});
