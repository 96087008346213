import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import NewsActions from "../reducers/news.reducer";
import { createOrUpdateNovelty } from "../../lib/serializers/createOrUpdateNovelty.serializer";
import { requestFile, requestFileWithURL } from "../../components/LoadDocument/LoadDocument";

export const submitGetNoveltiesByType = action$ => action$.pipe(
  ofType("SUBMIT_GET_NOVELTIES_BY_TYPE"),
  flatMap(action => {
    const url = {
      novelty_type: action?.novelty_type
    };
    return Observable.from(
      App.api.a2censo.getNoveltiesByType({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          NewsActions.getNoveltiesByTypeSuccess(response.news_list)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              // NewsActions.getNoveltiesByTypeFailed(response)
            )
          );
        }
      })
    );
  })
);

export const submitDeleteNoveltieById = action$ => action$.pipe(
  ofType("SUBMIT_DELETE_NOVELTIE_BY_ID"),
  flatMap(action => {
    const url = {
      novelty_id: action?.novelty_id,
      novelty_type: action?.novelty_type,
      user_email: action?.user_email,
      user_ip: action?.user_ip
    };
    return Observable.from(
      App.api.a2censo.deleteNoveltieById({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          NewsActions.deleteNoveltieByIdSuccess(response.message)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              NewsActions.deleteNoveltieByIdFailed(response.data.attributes)
            )
          );
        }
      })
    );
  })
);

export const getTypesMultimedia = action$ => action$.pipe(
  ofType("SUBMIT_GET_TYPES_MULTIMEDIA"),
  flatMap(action => {
    return Observable.from(
      App.api.a2censo.getTypesMultimedia()
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          NewsActions.getTypesMultimediaSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              // NewsActions.deleteNoveltieByIdFailed(response)
            )
          );
        }
      })
    );
  })
);

export const getAllCampaigns = action$ => action$.pipe(
  ofType("SUBMIT_GET_ALL_CAMPAIGNS"),
  flatMap(action => {
    return Observable.from(
      App.api.a2censo.getAllCampaigns()
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          NewsActions.getAllCampaignsSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              // NewsActions.deleteNoveltieByIdFailed(response)
            )
          );
        }
      })
    );
  })
);

export const addNewNovelty = action$ =>
  action$.pipe(
    ofType("SUBMIT_ADD_NEW_NOVELTY"),
    flatMap((act) => {
      const novelty_type = act.novelty_type;
      const title = act.title;
      const short_description = act.short_description;
      const multimedia_type = act.multimedia_type;
      const multimedia = multimedia_type === "image"? "" : act.multimedia;
      const multimedia_name = act.multimedia_name;
      const multimedia_external_type = act.multimedia_external_type;
      const ext_multimedia = multimedia_external_type === "file"? "" : act.ext_multimedia;
      const ext_multimedia_name = act.ext_multimedia_name;
      const is_active = act.is_active;
      const campaign_id = act.campaign_id;
      const publish_at = act.publish_at;
      const user_email = act.user_email;
      const user_ip = act.user_ip;
      const fileMul = act.fileMul;
      const fileMulExt = act.fileMulExt;

      const data = {
        body: createOrUpdateNovelty().serialize({
          novelty_type,
          title,
          short_description,
          multimedia_type,
          multimedia,
          multimedia_name,
          multimedia_external_type,
          ext_multimedia,
          ext_multimedia_name,
          is_active,
          campaign_id,
          publish_at,
          user_email,
          user_ip
        })
      }
      return Observable.concat(
        Observable.from(App.api.a2censo.addNewNoveltie({ ...data }))
        .pipe(
          flatMap(async (response) => {
            return Observable.concat(
              Observable.of(await requestFile(fileMul, "put", response.url_image)),
              Observable.from(await requestFile(fileMulExt, "put", response.url_docs))
              .pipe(
                flatMap((res) => {
                  return Observable.of( NewsActions.addNewNoveltySuccess(response.message))
                }),
                catchError((e) => Observable.of(NewsActions.addNewNoveltyFailed(e)))
              ),
              catchError((e) => Observable.of(NewsActions.addNewNoveltyFailed(e)))
            )
          }),
          catchError((e) => {
            if (e.statusCode === 401) {
              return Observable.of(LoginActions.submitLogout())
            } else {
              return Observable.of(
                NewsActions.addNewNoveltyFailed("e.data.attributes")
              )
            }
          })
        )
      );
    })
  );

export const updateNoveltyById = action$ =>
  action$.pipe(
    ofType("SUBMIT_UPDATE_NOVELTY_BY_ID"),
    flatMap((act) => {
      const novelty_id = act.novelty_id;
      const novelty_type = act.novelty_type;
      const title = act.title;
      const short_description = act.short_description;
      const multimedia_type = act.multimedia_type;
      const multimedia_name = act.multimedia_name;
      const multimedia_external_type = act.multimedia_external_type;
      const multimedia = multimedia_type === "image"? "" : act.multimedia;
      const ext_multimedia = multimedia_external_type === "file"? "" : act.ext_multimedia;
      const ext_multimedia_name = act.ext_multimedia_name;
      const is_active = act.is_active;
      const campaign_id = act.campaign_id;
      const publish_at = act.publish_at;
      const user_email = act.user_email;
      const user_ip = act.user_ip;
      const fileMul = act.fileMul;
      const fileMulExt = act.fileMulExt;

      const data = {
        body: createOrUpdateNovelty().serialize({
          novelty_id,
          novelty_type,
          title,
          short_description,
          multimedia_type,
          multimedia,
          multimedia_name,
          multimedia_external_type,
          ext_multimedia,
          ext_multimedia_name,
          is_active,
          campaign_id,
          publish_at,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.updateNoveltieById({ ...data })
      ).pipe(
        flatMap(async (response) => {
          return Observable.concat(
            Observable.of(await requestFile(fileMulExt, "put", response.url_image)),
            Observable.from(await requestFile(fileMul, "put", response.url_docs))
            .pipe(
              flatMap((res) => {
                return Observable.of( NewsActions.updateNoveltyByIdSuccess(response.message))
              }),
              catchError((e) => Observable.of(NewsActions.updateNoveltyByIdFailed(e)))
            ),
            catchError((e) => Observable.of(NewsActions.updateNoveltyByIdFailed(e)))
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              NewsActions.updateNoveltyByIdFailed("e.data.attributes")
            )
          }
        })
      );
    })
  );

export default combineEpics(submitGetNoveltiesByType, submitDeleteNoveltieById, getTypesMultimedia, getAllCampaigns, addNewNovelty, updateNoveltyById);
