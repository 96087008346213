const { Serializer } = require("jsonapi-serializer");

module.exports = {
  AccreditSerializer: (meta = {}) =>
    new Serializer("AccreditSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "fng",
        "payment_file",
        "payment_file_name",
        "support_file",
        "support_file_name",
        "observation",
        "id_patrimonial",
        "user_email",
        "user_ip"
      ]
    })
};
