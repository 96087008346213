export default {
    Campaign: [
        { id: 1, name: "Crear Campañas" }, { id: 2, name: "Consultar Campañas" },
        { id: 3, name: "Modificar Campañas" },
        { id: 63, name: "Modificar detalle de campaña"},
        { id: 46, name: "Dispersar campaña" },
        { id: 64, name: "Ver histórico de estados de campaña" }
    ],
    CampaignForm: [
        { id: 1, name: "Crear Campañas" },
        { id: 3, name: "Modificar Campañas" },
        { id: 63, name: "Modificar detalle de campaña"}
    ],
    Company: [
        { id: 4, name: "Crear Pyme" },
        { id: 6, name: "Modificar Pyme" },
        { id: 5, name: "Consultar Pyme" }
    ],
    CompanyForm: [
        { id: 4, name: "Crear Pyme" },
        { id: 6, name: "Modificar Pyme" }
    ],
    Multicash: [
        { id: 10, name: "Consultar y descargar archivos de confirmación de pagos" },
        { id: 11, name: "Cargar archivos multicash" },
        { id: 12, name: "Descargar archivos multicash" }
    ],
    Dispersion: [
        { id: 7, name: "Generar Archivo de dispersion" },
        { id: 8, name: "Consultar y descargar archivos de dispersion (plano y encriptado)" },
        { id: 9, name: "Cargar archivo de confirmación de pagos" },
        { id: 10, name: "Consultar y descargar archivos de confirmación de pagos" }
    ],
    Parameters: [
        { id: 15, name: "Crear cuenta" }, { id: 16, name: "Editar cuenta" },
        { id: 17, name: "Consultar cuenta" }
    ],
    Users: [
        { id: 18, name: "Consultar Usuarios" },
        { id: 19, name: "Editar Usuarios" }
    ],
    Annotation: [
        { id: 20, name: "Consultar anotaciones" },
        { id: 21, name: "Descargar detalles de anotación" },
        { id: 30, name: "Consultar datos de emisión" },
        { id: 31, name: "Editar datos de emisión" },
        { id: 32, name: "Generar macrotítulo" },
        { id: 33, name: "Descargar macrotítulo" },
    ],
    AnnotationDetail: [
        { id: 22, name: "Ver detalle de anotación" },
        { id: 29, name: "Anotar" },
        { id: 37, name: "Cambiar estado de anotación" },
    ],
    AnnotationHist: [
        { id: 34, name: "Consultar histórico de anotación" },
    ],
    AnnotationByFile: [
        { id: 35, name: "Consultar anotaciones por archivo" },
    ],
    AnnotationByFileDetail: [
        { id: 36, name: "Consultar detalle de anotaciones por archivo" },
    ],
    Patrimonial: [
        { id: 23, name: "Consultar derechos patrimoniales" },
        { id: 24, name: "Descargar detalle de derechos patrimoniales" },
        { id: 26, name: "Generar transacciones" },
        { id: 68, name: "Acreditar pago en mora" }
    ],
    PatrimonialDetail: [
        { id: 25, name: "Ver detalle de derechos patrimoniales" }
    ],
    Payment: [
        { id: 27, name: "Consultar pagos de pymes" }
    ],
    PaymentDetail: [
        { id: 28, name: "Consultar detalle de pagos de pymes" }
    ],
    Transaction: [
        { id: 41, name: "Consultar transacciones" },
        { id: 42, name: "Acceder al detalle de una transacción" },
        { id: 37, name: "Cambiar estado de anotación" },
        { id: 47, name: "Ver histórico de transacción" },
    ],
    TransactionDetail: [
        { id: 42, name: "Acceder al detalle de una transacción" },
        { id: 43, name: "Cambiar estado de una transacción" }
    ],
    DispersionHist: [
        { id: 7, name: "Generar Archivo de dispersion" },
        { id: 8, name: "Consultar y descargar archivos de dispersion (plano y encriptado)" },
        { id: 9, name: "Cargar archivo de confirmación de pagos" },
        { id: 10, name: "Consultar y descargar archivos de confirmación de pagos" }
    ],
    AlertManagement: [
        { id: 38, name: "Acceder al módulo gestión de alertas" }
    ],
    Vinculation: [
        { id: 39, name: "Acceder al módulo de vinculación" },
        { id: 67, name: "Vinculación - Editar datos bancarios" }
    ],
    PymesCampaign: [
        { id: 40, name: "Acceder al módulo solicitud de campañas" }
    ],
    DocManagement: [
        { id: 48, name: "Acceder al módulo gestión de documentación" }
    ],
    PymesCampaignDetail: [
        { id: 50, name: 'Acceder a pestaña - Solicitud de campaña'},
        { id: 51, name: 'Acceder a pestaña - Filtros iniciales'},
        { id: 52, name: 'Filtros iniciales - editar y aprobar etapa'},
        { id: 53, name: 'Acceder a pestaña - Entrevista'},
        { id: 54, name: 'Entrevista - aprobar etapa'},
        { id: 55, name: 'Acceder a pestaña - Análisis integral'},
        { id: 56, name: 'Análisis integral - editar y aprobar etapa'},
        { id: 57, name: 'Acceder a pestaña - Comité'},
        { id: 58, name: 'Comité - editar y aprobar etapa'},
        { id: 59, name: 'Acceder a pestaña - Análisis Final'},
        { id: 60, name: 'Acceder a pestaña - Publicación'},
        { id: 61, name: 'Publicación - aprobar etapa'}
    ],
    ResponseManagement: [
        { id: 65, name: 'Parametrización de respuestas de rechazo'}
    ],
    Certificates: [
        { id: 62, name: 'Ver novedades de certificados'}
    ],
    Investors: [
        { id: 69, name: 'Entrar al módulo inversionistas'}
    ],
    News: [
        { id: 70, name: 'Entrar al módulo novedades - Home'},
        { id: 71, name: 'Entrar al módulo novedades - Campañas'}
    ],
    ParametersFNG: [
        { id: 79, name: "Crear, Editar y Elimar Comisión de garantia FNG" }
    ],
};