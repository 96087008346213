import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import VinculationActions from "../reducers/vinculation.reducer"
import { saveCompanyBankInfoSerializer } from "../../lib/serializers/saveCompanyBankInfo.serializer";

export const StartVinculationShowcase = () => ({
  type: "START_VINCULATION_SHOWCASE"
});

export const vinculationShowcase = action$ => action$.pipe(
  ofType("START_VINCULATION_SHOWCASE"),
  flatMap(action => {
    return Observable.from(
      App.api.a2censo.getVinculationStates()
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          push(`/vinculation`, { response })
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitGetVinculationList = action$ => action$.pipe(
  ofType("SUBMIT_GET_VINCULATION_LIST"),
  flatMap(action => {
    const url = {
      stateSelected: action.stateSelected ? action.stateSelected : "",
    };
    return Observable.from(
      App.api.a2censo.getVinculationList({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getVinculationListSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitGetSelfAssessmentData = action$ => action$.pipe(
    ofType("SUBMIT_GET_SELF_ASSESSMENT_DATA"),
    flatMap(action => {
      const url = { 
        campaignId: action.campaignId,
        req_campaign_id: action.req_campaign_id
      };
      return Observable.from(
        App.api.a2censo.getSelfAssessmentData({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            VinculationActions.getSelfAssessmentDataSuccess(response)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(
                push(`oops`)
              )
            );
          }
        })
      );
    })
  );

/**
 * trae los datos de respuestas fatca por pyme id
 */
export const submitGetFatcaByPyme = action$ => action$.pipe(
  ofType("SUBMIT_GET_FATCA_BY_PYME"),
  flatMap(action => {
    const url = {
      pymeId: action.pymeId,
    };
    return Observable.from(
      App.api.a2censo.getFatcaByPyme({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getFatcaByPymeSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

/**
 * Método para obtener data de detalle de los representantes y socios
 */
export const submitGetRepAssociate = action$ => action$.pipe(
  ofType("SUBMIT_GET_REP_ASSOCIATE"),
  flatMap(action => {
    const url = {
      company_id: action.company_id ? action.company_id : ""
    };
    return Observable.from(
      App.api.a2censo.getRepAssociate({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getRepAssociateSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

/**
* trae los datos de informacion pyme por pyme id
*/
export const submitGetInfoPyme = action$ => action$.pipe(
  ofType("SUBMIT_GET_INFO_PYME"),
  flatMap(action => {
    const url = {
      pymeId: action.pymeId,
    };
    return Observable.from(
      App.api.a2censo.getInfoPyme({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getInfoPymeSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

/**
* trae los datos de equipo pyme por pyme id
*/
export const submitGetPymeTeam = action$ => action$.pipe(
  ofType("SUBMIT_GET_PYME_TEAM"),
  flatMap(action => {
    const url = {
      pymeId: action.pymeId,
    };
    return Observable.from(
      App.api.a2censo.getPymeTeam({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getPymeTeamSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitGetCompanyBankInfo = action$ => action$.pipe(
  ofType("SUBMIT_GET_COMPANY_BANK_INFO"),
  flatMap(action => {
    const url = {
      company_id: action.companyId,
    };
    return Observable.from(
      App.api.a2censo.getCompanyBankInfo({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getCompanyBankInfoSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const getStaticParameters = action$ => action$.pipe(
  ofType("SUBMIT_GET_STATIC_PARAMETERS"),
  flatMap(action => {
    const url = {
      tables: action.tables,
    };
    return Observable.from(
      App.api.a2censo.getStaticParameters({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          VinculationActions.getStaticParametersSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const saveCompanyBankInfo = action$ =>
  action$.pipe(
    ofType("SUBMIT_SAVE_COMPANY_BANK_INFO"),
    flatMap((action) => {
      const company_id = action.company_id;
      const bank_id = action.bank_id;
      const bank_account_type_id = action.bank_account_type_id;
      const account_number = action.account_number;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: saveCompanyBankInfoSerializer().serialize({
          company_id,
          bank_id,
          bank_account_type_id,
          account_number,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.saveCompanyBankInfo({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            VinculationActions.saveCompanyBankInfoSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

  export const submitGetLogsByPyme = action$ => action$.pipe(
    ofType("SUBMIT_GET_LOGS_BY_PYME"),
    flatMap(action => {
      const url = {
        company_id: action.company_id,
      };
      return Observable.from(
        App.api.a2censo.getLogsByPyme({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            VinculationActions.getLogsByPymeSuccess(response)
          )
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(
                push(`oops`)
              )
            );
          }
        })
      );
    })
  );


export default combineEpics(vinculationShowcase, submitGetVinculationList, submitGetFatcaByPyme, submitGetSelfAssessmentData,
  submitGetRepAssociate, submitGetInfoPyme, submitGetPymeTeam, submitGetCompanyBankInfo, getStaticParameters, saveCompanyBankInfo,
  submitGetLogsByPyme);

