import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import InvestorsActions from "../reducers/investors.reducer";
import { investorsSerializer } from "../../lib/serializers/investors.serializer";
import { forkJoin } from "rxjs";


export const StartInvestorsShowcase = () => ({
    type: "START_INVESTORS_SHOWCASE"
});

export const investorsShowcase = action$ => action$.pipe(
    ofType("START_INVESTORS_SHOWCASE"),
    flatMap(action => {
        let request;
        request = forkJoin([
            Observable.from(App.api.a2censo.getAllInvestors())
        ])
        return request.pipe(
            flatMap((response) => {
                const investorList = response[0];
                return Observable.of(
                    push(`/investors`, { investorList })
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export const submitGetAllCitiesInTheWorld = action$ => action$.pipe(
    ofType("SUBMIT_GET_ALL_CITIES_IN_THE_WORLD"),
    flatMap(action => {
        return Observable.from(
            App.api.a2censo.getAllCitiesInTheWorld()
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    InvestorsActions.getAllCitiesInTheWorldSuccess(response)
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export const submitGetInvestorById = action$ => action$.pipe(
    ofType("SUBMIT_GET_INVESTOR_BY_ID"),
    flatMap(action => {
        const url = {
            investor_id: action.investor_id
        };
        return Observable.from(
            App.api.a2censo.getInvestorById({ url })
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    InvestorsActions.getInvestorByIdSuccess(response)
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export const updateInfoInvestors = action$ => action$.pipe(
    ofType("SUBMIT_UPDATE_INFO_INVESTORS"),
    flatMap(action => {
        const file = action.file;
        const file_name = action.file_name;
        const investor_id = action.investor_id;
        const deposit_account = action.deposit_account;
        const city_id = action.city_id;
        const city_name = action.city_name;
        const observation = action.observation;
        const user_email = action.user_email;
        const user_ip = action.user_ip;
        const data = {
            body: investorsSerializer().serialize({ file, file_name, investor_id, deposit_account, city_id, city_name, observation, user_email, user_ip }),
        }
        return Observable.from(
            App.api.a2censo.updateInfoInvestors({ ...data })
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                    InvestorsActions.updateInfoInvestorsSuccess(response?.message)
                )
            }),
            catchError((response) => {
                if (response.statusCode === 401) {
                    return Observable.of(LoginActions.submitLogout())
                } else {
                    return Observable.concat(
                        Observable.of(
                            InvestorsActions.updateInfoInvestorsError(response?.data?.attributes?.message)
                        )
                    );
                }
            })
        );
    })
);




export default combineEpics(investorsShowcase, submitGetInvestorById, updateInfoInvestors, submitGetAllCitiesInTheWorld);