import axios from "axios";

export class Connect {

  constructor(baseUrl, prefix, headers) {
    this.baseUrl = baseUrl;
    this.prefix = prefix;
    this.headers = headers;
    this.axios = axios;
  }

  async request({ method, service, headers, params, body }) {
    try {
      return await this.axios({
        method: method,
        url: this.getUrl(service),
        headers: {
          ...this.headers,
          ...headers,
        },
        params,
        data: body,
      });
    } catch (error) {
      return Promise.reject({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }

  getUrl(service) {
    return `${this.baseUrl}${this.prefix ? `/${this.prefix}` : ""}/${service}`;
  }

  /**
   * Implementation required
   */
  handleError(error) {
    throw error;
  }
}
