import { catchError, flatMap , map} from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app.js";
import Users, {UserTypes} from "../reducers/users.reducer";
import { UserSerializer } from "../../lib/serializers/user.serializer";
import LoginActions from "../reducers/login.reducer";

export const startUserShowcase = (tables) => ({
    type: "START_USER_SHOWCASE",
    tables
});

export const userShowcase = action$ =>
    action$.pipe(
        ofType("START_USER_SHOWCASE"),
        flatMap((action) => {
            return forkJoin([
                Observable.from(
                    App.api.a2censo.getStaticParameters({
                        url: { tables: action.tables }
                      })
                ),
                Observable.from(App.api.a2censo.getUserList())
            ]).pipe(
                map((response) => {
                    return push(`/users`, {response});
                }),
            catchError((response) => {
                if(response.data){
                    return Observable.concat(
                        Observable.of(
                            Users.userStateUpdateFail(response.data.attributes.message)
                        )
                    );
                }else if(response.statusCode === 401){   
                    return Observable.of(LoginActions.submitLogout())
                }else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
);

export const editUserState = action$ =>
  action$.pipe(
    ofType(UserTypes.USER_STATE_UPDATE),
    flatMap(action => {
        const idParameter = parseInt(action.data.id);
        const data = {
            body: UserSerializer().serialize(action.data),
            url: {id: idParameter}
        }
        return Observable.from(
            App.api.a2censo.updateUserState({
                ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            Users.userStateUpdateSuccess(response.message),
            push(`/users`)
          )
        }),
        catchError(response => {
            if(response.data){
                return Observable.concat(
                    Observable.of(Users.userStateUpdateFail(response.data.attributes.message)),
                    Observable.of(push(`/users`))
                );
            }else if(response.statusCode === 401){   
                return Observable.of(LoginActions.submitLogout())
            } else {
                return Observable.concat(
                    Observable.of(
                        push(`oops`)
                    )
                );
            }
        })
      );
    })
)

export default combineEpics (userShowcase,editUserState);