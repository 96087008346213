import { Connect } from "./connect.js";
import App from "../lib/app.js";

export class AccIntegrationConnect extends Connect {
  constructor() {
    const { apiKey, apiACCUrl, apiACCPrefix, origin } = App.config.apis.a2censo;
    const { login } = App.redux.store.getState();
    super(apiACCUrl, apiACCPrefix, {
      apiKey,
      Authorization: "Bearer " + login.idToken,
      Origin: origin,
    });
  }

  async creditCreation(body) {
    const response = await this.request({
      method: "post",
      service: "credit-creation/create",
      body,
    });
    return Promise.resolve(response?.data);
  }

  async getTrackingACC(id) {
    const response = await this.request({
      method: "get",
      service: `tracking-acc/${id}`,
    });
    return Promise.resolve(response?.data);
  }
}
