import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetNameList: ["validationDate", "stateSelected", "companySelected", "reported"],
  getNameListSuccess: ["nameList"],
  submitReported: ["report_id", "action_rep", "justification", "email", "user_ip", "type_reported", "file", "file_name"],
  updateReportedSuccess: ["responseUpdate"],
  updateReportedFailed: ["updateError"],
  eraseMessage: [""],
  submitGetPymeList: ["type_reported"]
});

export const AnnotationByFilesTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: "",
  nameList: "",
  responseUpdate: "",
  updateError: "",
};

const submitGetNameList = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getNameListSuccess = (state, { nameList }) =>
  Object.assign({}, state, {
    isFetching: false,
    nameList
  });

const submitReported = state =>
  Object.assign({}, state, {
  isFetching: true
});

const updateReportedSuccess = (state, { responseUpdate }) =>
  Object.assign({}, state, {
    isFetching: false,
    responseUpdate
});

const updateReportedFailed = (state, { updateError }) =>
  Object.assign({}, state, {
    isFetching: false,
    updateError
});

const eraseMessage = state =>   
  Object.assign({}, state, {
    responseUpdate: "",
    updateError: "",
});

const submitGetPymeList = state =>
  Object.assign({}, state, {
    isFetching: true
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_NAME_LIST]: submitGetNameList,
  [Types.GET_NAME_LIST_SUCCESS]: getNameListSuccess,
  [Types.SUBMIT_REPORTED]: submitReported,
  [Types.UPDATE_REPORTED_SUCCESS]: updateReportedSuccess,
  [Types.UPDATE_REPORTED_FAILED]: updateReportedFailed,
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.SUBMIT_GET_PYME_LIST]: submitGetPymeList
});
