import { catchError, flatMap , map} from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app.js";
import ParametersLoad, {ParameterLoadTypes} from "../reducers/parameters-load.reducer";
import { ParameterSerializer } from "../../lib/serializers/parameters.serializer";
import LoginActions from "../reducers/login.reducer";

export const startParameterShowcase = (tables) => ({
    type: "START_PARAMETER_SHOWCASE",
    tables
});

export const parameterShowcase = action$ =>
    action$.pipe(
        ofType("START_PARAMETER_SHOWCASE"),
        flatMap((action) => {
            return forkJoin([
                Observable.from(
                    App.api.a2censo.getStaticParameters({
                        url: { tables: action.tables }
                      })
                ),
                Observable.from(App.api.a2censo.getParametersList())
            ]).pipe(
                map((response) => {
                    return push(`/parameters`, {response});
                }),
                catchError((e) => {
                  if(e.statusCode === 401){   
                    return Observable.of(LoginActions.submitLogout())
                  }else {
                    return Observable.of(
                      push(`/oops`)
                    )
                  }
              })
            );
        })
    );


export const editParameter = action$ =>
  action$.pipe(
    ofType(ParameterLoadTypes.UPDATE_PARAMETER_LOAD),
    flatMap(action => {
        const idParameter = parseInt(action.parameter.id);
        const data = {
            body: ParameterSerializer().serialize(action.parameter),
            url: {id: idParameter}
        }
        return Observable.from(
            App.api.a2censo.updateParameter({
                ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            ParametersLoad.parameterUpdateSuccess("Parametro actualizado correctamente."),
            push(`/parameters`)
          )
        }),
        catchError(response => {
            if(response.data){
                return Observable.concat(
                    Observable.of(ParametersLoad.parameterUpdateFail(response.data.attributes.message)),
                    Observable.of(push(`/parameters`))
                );
            }if(response.statusCode === 401){   
              return Observable.of(LoginActions.submitLogout())
            } else {
                return Observable.concat(
                    Observable.of(
                        push(`oops`)
                    )
                );
            }
        })
      );
    })
  );


export default combineEpics (parameterShowcase,editParameter);