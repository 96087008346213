import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetNoveltiesByType: ["novelty_type"],
  getNoveltiesByTypeSuccess: ["response"],
  submitDeleteNoveltieById: [
    "novelty_id",
    "novelty_type",
    "user_email",
    "user_ip"
  ],
  deleteNoveltieByIdSuccess: ["message"],
  deleteNoveltieByIdFailed: ["error"],
  submitGetTypesMultimedia: [""],
  getTypesMultimediaSuccess: ["multimediaTypes"],
  submitGetAllCampaigns: [""],
  getAllCampaignsSuccess: ["allCampaigns"],
  submitAddNewNovelty: [
    "novelty_type",
    "title",
    "short_description",
    "multimedia_type",
    "multimedia",
    "multimedia_name",
    "multimedia_external_type",
    "ext_multimedia",
    "ext_multimedia_name",
    "is_active",
    "campaign_id",
    "publish_at",
    "user_email",
    "user_ip",
    "fileMul",
    "fileMulExt"
  ],
  addNewNoveltySuccess: ["message"],
  addNewNoveltyFailed: ["req_status"],
  submitUpdateNoveltyById: [
    "novelty_id",
    "novelty_type",
    "title",
    "short_description",
    "multimedia_type",
    "multimedia",
    "multimedia_name",
    "multimedia_external_type",
    "ext_multimedia",
    "ext_multimedia_name",
    "is_active",
    "campaign_id",
    "publish_at",
    "user_email",
    "user_ip",
    "fileMul",
    "fileMulExt"
  ],
  updateNoveltyByIdSuccess: ["message"],
  updateNoveltyByIdFailed: ["error"],
  deleteMessageAndError: [""]
});

export const AnnotationByFilesTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: "",
  novelities: "",
  message: "",
  error: "",
  multimediaTypes: "",
  allCampaigns: "",
  req_status: ""
};

const submitGetNoveltiesByType = state =>
  Object.assign({}, state, {
    // isFetching: true
  });

const deleteMessageAndError = state =>
  Object.assign({}, state, {
    message: "",
    error: "",
    status: ""
  });

const getNoveltiesByTypeSuccess = (state, { response }) =>
  Object.assign({}, state, {
    isFetching: false,
    novelities: response
  });

const submitDeleteNoveltieById = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const deleteNoveltieByIdSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    message
  });

const deleteNoveltieByIdFailed = (state, { error }) =>
  Object.assign({}, state, {
    isFetching: false,
    message: error.message,
    status: error.status
  });

const submitGetTypesMultimedia = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getTypesMultimediaSuccess = (state, { multimediaTypes }) =>
  Object.assign({}, state, {
    isFetching: false,
    multimediaTypes
  });

const submitGetAllCampaigns = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getAllCampaignsSuccess = (state, { allCampaigns }) =>
  Object.assign({}, state, {
    isFetching: false,
    allCampaigns
  });

const submitAddNewNovelty = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const addNewNoveltySuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    message
  });

const addNewNoveltyFailed = (state, {req_status}) => {
  return ( Object.assign({}, state, {
    isFetching: false,
    message: req_status.message,
    status: req_status.status
  }))
}

const submitUpdateNoveltyById = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const updateNoveltyByIdSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    message
  });

const updateNoveltyByIdFailed = (state, { error }) =>
  Object.assign({}, state, {
    isFetching: false,
    message: error.message,
    status: error.status
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_NOVELTIES_BY_TYPE]: submitGetNoveltiesByType,
  [Types.GET_NOVELTIES_BY_TYPE_SUCCESS]: getNoveltiesByTypeSuccess,
  [Types.SUBMIT_DELETE_NOVELTIE_BY_ID]: submitDeleteNoveltieById,
  [Types.DELETE_NOVELTIE_BY_ID_SUCCESS]: deleteNoveltieByIdSuccess,
  [Types.DELETE_NOVELTIE_BY_ID_FAILED]: deleteNoveltieByIdFailed,
  [Types.SUBMIT_GET_TYPES_MULTIMEDIA]: submitGetTypesMultimedia,
  [Types.GET_TYPES_MULTIMEDIA_SUCCESS]: getTypesMultimediaSuccess,
  [Types.SUBMIT_GET_ALL_CAMPAIGNS]: submitGetAllCampaigns,
  [Types.GET_ALL_CAMPAIGNS_SUCCESS]: getAllCampaignsSuccess,
  [Types.SUBMIT_ADD_NEW_NOVELTY]: submitAddNewNovelty,
  [Types.ADD_NEW_NOVELTY_SUCCESS]: addNewNoveltySuccess,
  [Types.ADD_NEW_NOVELTY_FAILED]: addNewNoveltyFailed,
  [Types.SUBMIT_UPDATE_NOVELTY_BY_ID]: submitUpdateNoveltyById,
  [Types.UPDATE_NOVELTY_BY_ID_SUCCESS]: updateNoveltyByIdSuccess,
  [Types.UPDATE_NOVELTY_BY_ID_FAILED]: updateNoveltyByIdFailed,
  [Types.DELETE_MESSAGE_AND_ERROR]: deleteMessageAndError
});
