import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetPatrimonialCampaign: ["campaignData"],
  getPatrimonialCampaignSuccess: ["campaignData"],
  submitGetPatrimonialDetail: ["data"],
  getPatrimonialDetailSuccess: ["data"],
  submitGenerateTransactions: ["data"],
  approvedPatrimonialBatch: ["data"],
  generateTransactionsSuccess: ["message"],
  approvedPatrimonialBatchSuccess: ["message"],
  setCampaignId: ["id"],
  eraseMessage:[""],
  actionFailed: ["error"],
  submitAccreditPatrimonial:[
    "fng",
    "payment_file",
    "payment_file_name",
    "support_file",
    "support_file_name",
    "observation",
    "id_patrimonial",
    "user_email",
    "user_ip"
  ],
  accreditPatrimonialSuccess: ["message"],
  accreditPatrimonialFailed: ["error"],
});

export const PatrimonialTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  isFetchingDetail: false,
  isFetchingTransactions: false,
  data:"",
  message: "",
  campaignData:"",
  error:"",
  campaignSelected:""
};

const eraseMessage = state =>   
  Object.assign({}, state, {
    message: "",
    error: ""
  });

const setCampaignId = (state, {id}) => 
  Object.assign({}, state, {
    campaignSelected: id
  });
  
const submitGetPatrimonialDetail = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getPatrimonialDetailSuccess = (state, {data}) =>
  Object.assign({}, state, {
    isFetching: false,
    data
  });

const submitGetPatrimonialCampaign = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getPatrimonialCampaignSuccess = (state, {campaignData}) => 
  Object.assign({}, state, {
    isFetching: false,
    campaignSelected: campaignData.id,
    campaignData: campaignData.patrimonial
  });

const submitGenerateTransactions = state =>
  Object.assign({}, state, {
    isFetchingTransactions: true
  });

const generateTransactionsSuccess = (state, {response: message}) =>
  Object.assign({}, state, {
    isFetchingTransactions: false,
    message
  });

const actionFailed = (state, {error: responseError}) => 
  Object.assign({}, state, {
    isFetching: false,
    isFetchingTransactions: false,
    error: responseError
  });

const approvedPatrimonialBatch = state =>
  Object.assign({}, state, {
    isFetchingTransactions: true
  });

const approvedPatrimonialBatchSuccess = (state,  {message}) =>
  Object.assign({}, state, {
    isFetchingTransactions: false,
    message: message
  });
 
const submitAccreditPatrimonial = state =>
  Object.assign({}, state, {
    isFetchingTransactions: true
  });

const accreditPatrimonialSuccess = (state,  {message}) =>
  Object.assign({}, state, {
    isFetchingTransactions: false,
    message: message.message
  });

const accreditPatrimonialFailed = (state,  {error}) =>
  Object.assign({}, state, {
    isFetchingTransactions: false,
    error: error
  });
 

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_PATRIMONIAL_DETAIL]: submitGetPatrimonialDetail,
  [Types.GET_PATRIMONIAL_DETAIL_SUCCESS]: getPatrimonialDetailSuccess,
  [Types.SUBMIT_GET_PATRIMONIAL_CAMPAIGN]: submitGetPatrimonialCampaign,
  [Types.GET_PATRIMONIAL_CAMPAIGN_SUCCESS]: getPatrimonialCampaignSuccess,
  [Types.SUBMIT_GENERATE_TRANSACTIONS]: submitGenerateTransactions,
  [Types.GENERATE_TRANSACTIONS_SUCCESS]: generateTransactionsSuccess,
  [Types.APPROVED_PATRIMONIAL_BATCH]: approvedPatrimonialBatch,
  [Types.APPROVED_PATRIMONIAL_BATCH_SUCCESS]: approvedPatrimonialBatchSuccess,
  [Types.ACTION_FAILED]: actionFailed,
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.SUBMIT_ACCREDIT_PATRIMONIAL]: submitAccreditPatrimonial,
  [Types.ACCREDIT_PATRIMONIAL_SUCCESS]: accreditPatrimonialSuccess,
  [Types.ACCREDIT_PATRIMONIAL_FAILED]: accreditPatrimonialFailed,
  [Types.SET_CAMPAIGN_ID]: setCampaignId
});
