import { catchError, flatMap , map} from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app.js";
import ParametersLoadFNG, {ParameterFNGLoadTypes} from "../reducers/parametersFNG-load.reducer.js";
import { ParameterSerializer } from "../../lib/serializers/parameters.serializer.js";
import { ParameterFNGSerializer } from "../../lib/serializers/parametersFNG.serializer.js";
import LoginActions from "../reducers/login.reducer.js";

export const startParameterShowcaseFng = (useremail, userip) => ({
    type: "START_PARAMETER_SHOWCASE_FNG",
    useremail: useremail,
    userip: userip
});

export const createParameterFng = (dato) => ({
  type: "SUBMIT_CREATE_FNG",
  datos:dato
});
export const updateParameterFng = (dato) => ({
  type: "UPDATE_PARAMETER_LOAD_FNG",
  datos:dato
});

export const deleteParameterFng = (dato) => ({
  type: "DELETE_PARAMETER_FNG",
  datos:dato
});
export const parameterFNGShowcase = action$ =>
    action$.pipe(
        ofType("START_PARAMETER_SHOWCASE_FNG"),
        flatMap((action) => {
          const data = {
              url: {email:action.useremail,
                    ip: action.userip}
          }
          return  forkJoin([
            Observable.from(App.api.a2censo.getProductsFNGList(data)),
            Observable.from(App.api.a2censo.getProductsFNGTypes())
          ]).pipe(
                map((response) => {
                    return push(`/pyme_simulator_parameters`, {response});
                }),
                catchError((e) => {
                  if(e.statusCode === 401){   
                    return Observable.of(LoginActions.submitLogout())
                  }else {
                    return Observable.of(
                      push(`/oops`)
                    )
                  }
              })
            );
        })
    );


export const editParameterFNG = action$ =>
  action$.pipe(
    ofType("UPDATE_PARAMETER_LOAD_FNG"),
    flatMap(action => {
        const idParameter = parseInt(action.datos.id);
        action.datos.id=idParameter;
        const data = {
            body: ParameterFNGSerializer().serialize(action.datos),
            url: {id: idParameter,
                  email:action.datos.user_email,
                  ip: action.datos.user_ip}
        }
        return Observable.from(
            App.api.a2censo.updateProductsFNG({
               ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            ParametersLoadFNG.parameterUpdateSuccess("Se modificó exitosamente el producto del FNG seleccionado."),
            push(`/pyme_simulator_parameters`)
          )
        }),
        catchError(response => {
            if(response.data){
                return Observable.concat(
                    Observable.of(ParametersLoadFNG.parameterUpdateFail(response.data.attributes.message)),
                    Observable.of(push(`/pyme_simulator_parameters`))
                );
            }if(response.statusCode === 401){   
              return Observable.of(LoginActions.submitLogout())
            } else {
                return Observable.concat(
                    Observable.of(
                        push(`oops`)
                    )
                );
            }
        })
      );
    })
  );

  export const eliminarParameterFNG = action$ =>
  action$.pipe(
    ofType("DELETE_PARAMETER_FNG"),
    flatMap(action => {
        const idParameter = parseInt(action.datos.id);
        const data = {
            url: {id: idParameter,
                  email:action.datos.user_email,
                  ip: action.datos.user_ip}
        }
        return Observable.from(
            App.api.a2censo.deleteProductsFNG({
               ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            ParametersLoadFNG.parameterUpdateSuccess("Se eliminó correctamente el producto del FNG seleccionado."),
            push(`/pyme_simulator_parameters`)
          )
        }),
        catchError(response => {
            if(response.data){
                return Observable.concat(
                    Observable.of(ParametersLoadFNG.parameterUpdateFail(response.data.attributes.message)),
                    Observable.of(push(`/pyme_simulator_parameters`))
                );
            }if(response.statusCode === 401){   
              return Observable.of(LoginActions.submitLogout())
            } else {
                return Observable.concat(
                    Observable.of(
                        push(`oops`)
                    )
                );
            }
        })
      );
    })
  );  
  export const createFNG = action$ =>
  action$.pipe(
    ofType("SUBMIT_CREATE_FNG"),
    flatMap(action => {
        const data = {
            body: ParameterFNGSerializer().serialize(action.datos)
        }
        return  forkJoin([
          Observable.from(
            App.api.a2censo.createProductsFNG({
                ...data
        }))
        ]).pipe(
        flatMap((response) => {
          return Observable.of(
            ParametersLoadFNG.parameterUpdateSuccess("Se agregó correctamente un nuevo producto del FNG."),
            push(`/pyme_simulator_parameters`)
          )
        }),
        catchError(response => {
            if(response.data){
                return Observable.concat(
                    Observable.of(ParametersLoadFNG.parameterUpdateFail(response.data.attributes.message)),
                    Observable.of(push(`/pyme_simulator_parameters`))
                );
            }if(response.statusCode === 401){   
              return Observable.of(LoginActions.submitLogout())
            } else {
                return Observable.concat(
                    Observable.of(
                        push(`oops`)
                    )
                );
            }
        })
      );
    })
  );

export default combineEpics (parameterFNGShowcase,editParameterFNG,createFNG,eliminarParameterFNG);