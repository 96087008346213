const { Serializer } = require("jsonapi-serializer");

module.exports = {
  UserSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "document_number",
        "document_type",
        "email",
        "id",
        "last_name",
        "name",
        "state",
        "enrollment_state"
      ]
    })
};
