const { Serializer } = require("jsonapi-serializer");

module.exports = {
  TransactionSerializer: (meta = {}) =>
    new Serializer("transaction", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["dispersion_id", "state", "description", "email"]
    })
};
