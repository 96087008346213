import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getListCodebtors: ["requestCampaignId", "deleted"],
  setListCodebtors: ["list"],
  getListAssociates: ["id"],
  setListAssociates: ["list"],
  setCodebtor: ["data"],
  submitPartnerCodebtor: ["data"],
  submitExternalCodebtor: ["data"],
  updateExternalCodebtor: ["data"],
  deleteCodebtor: ["data"],
  consultCodebtor: ["data"],
  postLoad: ["load"],
  eraseDataLoad: [""],
  eraseData: [""],
  eraseDataPymeCodebtor: [""],
  setErrConsultCodebtor: [""],
});

export const CodebtorTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isLoadingAssociateCodebtor: false,
  isFetching: false,
  isFetchingCodebtor: false,
  isConsultCodebtor: false,
  errConsultCodebtor: false,
  codebtorIndividual: [],
  associateList: [],
  codebtorList: { info: [], link: [] },
  load: {},
  showMessage: false,
};

const getListAssociates = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const setListAssociates = (state, { list }) =>
  Object.assign({}, state, {
    isFetching: false,
    associateList: list,
  });

const getListCodebtors = (state) =>
  Object.assign({}, state, {
    isFetchingCodebtor: true,
  });

const setListCodebtors = (state, { list }) =>
  Object.assign({}, state, {
    isFetchingCodebtor: false,
    codebtorList: list,
  });

const postLoad = (state, { load }) =>
  Object.assign({}, state, {
    isFetching: false,
    load: load,
    showMessage: true,
  });

const eraseDataLoad = (state) =>
  Object.assign({}, state, {
    isFetching: false,
    load: "",
  });

const eraseData = (state) =>
  Object.assign({}, state, {
    isFetching: false,
    associateList: [],
    codebtorIndividual: [],
    errConsultCodebtor: false,
  });

const eraseDataPymeCodebtor = (state) =>
  Object.assign({}, state, {
    isFetchingCodebtor: false,
    codebtorList: { info: [], link: [] },
  });

const submitPartnerCodebtor = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const submitExternalCodebtor = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const updateExternalCodebtor = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const deleteCodebtor = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const consultCodebtor = (state) =>
  Object.assign({}, state, {
    isConsultCodebtor: true,
  });

const setCodebtor = (state, { data }) =>
  Object.assign({}, state, {
    isConsultCodebtor: false,
    errConsultCodebtor: false,
    codebtorIndividual: data,
  });

const setErrConsultCodebtor = (state) =>
  Object.assign({}, state, {
    isConsultCodebtor: false,
    errConsultCodebtor: true,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LIST_ASSOCIATES]: getListAssociates,
  [Types.SET_LIST_ASSOCIATES]: setListAssociates,
  [Types.GET_LIST_CODEBTORS]: getListCodebtors,
  [Types.SET_LIST_CODEBTORS]: setListCodebtors,
  [Types.POST_LOAD]: postLoad,
  [Types.ERASE_DATA_LOAD]: eraseDataLoad,
  [Types.ERASE_DATA]: eraseData,
  [Types.ERASE_DATA_PYME_CODEBTOR]: eraseDataPymeCodebtor,
  [Types.SUBMIT_PARTNER_CODEBTOR]: submitPartnerCodebtor,
  [Types.SUBMIT_EXTERNAL_CODEBTOR]: submitExternalCodebtor,
  [Types.UPDATE_EXTERNAL_CODEBTOR]: updateExternalCodebtor,
  [Types.DELETE_CODEBTOR]: deleteCodebtor,
  [Types.CONSULT_CODEBTOR]: consultCodebtor,
  [Types.SET_CODEBTOR]: setCodebtor,
  [Types.SET_ERR_CONSULT_CODEBTOR]: setErrConsultCodebtor,
});
