const { Serializer } = require("jsonapi-serializer");

module.exports = {
  addComiteCompanyInfoSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "company_id",
        "total_assets",
        "total_liabilities",
        "total_operational_income",
        "total_operational_outcome",
        "net_profit"
      ]
    })
};
