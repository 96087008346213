import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import RegisterActions, { RegisterTypes } from "../reducers/company-associate.reducer";
import { AssociateSerializer } from "../../lib/serializers/associate.serializer";
import RegisterFormActions from "../reducers/company-form.reducer";
import LoginActions from "../reducers/login.reducer";

export const registerAssociateSubmit = action$ =>
  action$.pipe(
    ofType(RegisterTypes.SUBMIT_REGISTER_ASSOCIATE),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.registerAssociate({
          body: AssociateSerializer().serialize(action.data.associate)
        })
      ).pipe(
        flatMap((response) => {
          action.data.associate["id"] = response.details.id;
          action.data.associates.unshift(action.data.associate);
          return Observable.of(
            RegisterActions.createAssociate("OK"),
            RegisterActions.registerAssociateSuccess(),
            RegisterFormActions.updateAssociates(action.data.associates),
            push(`/company`)
          )
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerAssociateFail(e)),
              Observable.of(RegisterActions.createAssociate("ERROR"))
            );
          }
        })
      );
    })
  );

export const updateSubmitAssociate = action$ =>
  action$.pipe(
    ofType(RegisterTypes.UPDATE_REGISTER_ASSOCIATE),
    flatMap(action => {
      const idAssociate = parseInt(action.data.associate.id);
      const data = {
        body: AssociateSerializer().serialize(action.data.associate),
        url: {id: idAssociate}
      }  
      return Observable.from(
        App.api.a2censo.updateAssociate({
          ...data
        })
      ).pipe(
        flatMap(() => {
          return Observable.of(
            RegisterFormActions.startCompanyForm(action.data.company),
            RegisterActions.registerAssociateSuccess(),
            RegisterActions.editAssociate("OK"),
            RegisterFormActions.updateAssociates(action.data.associates)
          )
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerAssociateFail(e)),
              Observable.of(RegisterActions.editAssociate("ERROR"))
            );
          }
        })
      );
    })
  );

export const deleteCompanyAssociate = action$ =>
  action$.pipe(
    ofType(RegisterTypes.DELETE_COMPANY_ASSOCIATE),
    flatMap(action => {
      const idAssociate = parseInt(action.data.associate.id);
      const data = {
        url: {id: idAssociate, email: action.data.associate.email , ip: action.data.associate.ip}
      }  
      return Observable.from(
        App.api.a2censo.deleteAssociate({
          ...data
        })
      ).pipe(
        flatMap(() => {
          action.data.associates.splice(action.data.associates.indexOf(action.data.associate), 1);
          return Observable.of(
            RegisterActions.deleteAssociate("OK"),
            RegisterActions.registerAssociateSuccess(),
            RegisterFormActions.updateAssociates(action.data.associates),
            push(`/company`)
          )
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerAssociateFail(e)),
              Observable.of(
                RegisterActions.deleteAssociate("ERROR")
              )
            );
          }
        })
      );
    })
  );

export default combineEpics(registerAssociateSubmit, updateSubmitAssociate, deleteCompanyAssociate);
