import { Deserializer } from "jsonapi-serializer";
import App from "./app.js";
/* eslint no-param-reassign: 0 */
/* eslint prefer-promise-reject-errors: 0 */

class RequestContext {
  constructor(axios) {
    this.axios = axios;
  }

  requestInterceptor() {
    this.axios.interceptors.request.use(
      config => {
        // this place is where we have to add the token when the user
        // had a started session from App.redux.store.getState()
        config.headers.Authorization = "Bearer "+App.redux.store.getState().login.idToken;
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Methods"] = "DELETE, POST, PUT, GET, OPTIONS";
        config.headers["Access-Control-Allow-Headers"] = "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With";
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  responseInterceptor() {
    this.axios.interceptors.response.use(
      response => {
        if (response.data) {
          const dataDeserialized = new Deserializer({
            keyForAttribute: "snake_case",
            action: {
              valueForRelationship: relationship => ({
                id: relationship.id
              })
            }
          }).deserialize(response.data);

          return { data: dataDeserialized };
        }
        return response;
      },
      error => {
        if(error.response){
          if(error.response){
            return Promise.reject({
              ...error.response.data,
              statusCode: error.response.status
            });
          }else {
            return Promise.reject({
              ...error,
              statusCode: error.response
            });
          }
        }else {
          return Promise.reject({
            ...error,
            statusCode: 401
          });
        }
      }
    );
  }
}

export default RequestContext;
