const { Serializer } = require("jsonapi-serializer");

module.exports = {
  AnnotationSerializer: (meta = {}) =>
    new Serializer("annotationSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["campaign_serie", "emission_year", "expedition_date", "due_date", "company_code", "fng_fee", "origination_fee"]
    })
};
