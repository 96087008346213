import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitRegister: ["campaign"],
  updateRegister: ["campaign"],
  registerSuccess: ["response"],
  registerFail: ["error"]
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: ""
};

const submitRegister = state =>
  Object.assign({}, state, {
    isFetching: true
  });
const updateRegister = state =>
  Object.assign({}, state, {
    isFetching: true
  });
const registerSuccess = state => 
  Object.assign({}, state, {
    isFetching: false
  });
const registerFail = state =>
  Object.assign({}, state, {
    isFetching: false,
    error: registerFail
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_REGISTER]: submitRegister,
  [Types.UPDATE_REGISTER]: updateRegister,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAIL]: registerFail
});