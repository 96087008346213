import * as Yup from "yup";

const schema = {
    file: Yup.mixed()
      .required('Required')
      //.test("fileFormat","Formato incorrecto", 
        // value => value && value.toUpperCase().includes(format) 
      //)
   }

export default  Yup.object().shape(schema);