import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import RegisterActions, { RegisterTypes } from "../reducers/company-legal-representative.reducer";
import { LegalRepresentativeSerializer } from "../../lib/serializers/legalRepresentative.serializer";
import RegisterFormActions from "../reducers/company-form.reducer";
import LoginActions from "../reducers/login.reducer";

export const registerLegalRepresentativeSubmit = action$ =>
  action$.pipe(
    ofType(RegisterTypes.SUBMIT_REGISTER_LEGAL_REPRESENTATIVE),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.registerLegalRepresentative({
          body: LegalRepresentativeSerializer().serialize(action.data.legalRepresentative)
        })
      ).pipe(
        flatMap((response) => {
          action.data.legalRepresentative["id"] = response.details.id;
          return Observable.of(
            RegisterActions.createLegalRepresentative("OK"),
            RegisterActions.registerLegalRepresentativeSuccess(),
            RegisterFormActions.updateLegalRepresentative(action.data.legalRepresentative),
            push(`/company`)
          )
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerLegalRepresentativeFail(e)),
              Observable.of(RegisterActions.createLegalRepresentative("ERROR"))
            );
          }
        })
      );
    })
  );

export const updateSubmitLegalRepresentative = action$ =>
  action$.pipe(
    ofType(RegisterTypes.UPDATE_REGISTER_LEGAL_REPRESENTATIVE),
    flatMap(action => {
      const idLegalRepresentative = parseInt(action.data.legalRepresentative.id);
      const data = {
        body: LegalRepresentativeSerializer().serialize(action.data.legalRepresentative),
        url: {id: idLegalRepresentative}
      }  
      return Observable.from(
        App.api.a2censo.updateLegalRepresentative({
          ...data
        })
      ).pipe(
        flatMap(() => {
          return Observable.of(
            RegisterActions.editLegalRepresentative("OK"),
            RegisterActions.registerLegalRepresentativeSuccess(),
            RegisterFormActions.updateLegalRepresentative(action.data.legalRepresentative)
          )
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerLegalRepresentativeFail(e)),
              Observable.of(
                RegisterActions.editLegalRepresentative("ERROR")
              )
            );
          }
        })
      );
    })
  );

export const deleteCampaignLegalRepresentative = action$ =>
  action$.pipe(
    ofType(RegisterTypes.DELETE_CAMPAIGN_LEGAL_REPRESENTATIVE),
    flatMap(action => {
      const idLegalRepresentative = parseInt(action.data.legalRepresentative.id);
      const data = {
        url: {id: idLegalRepresentative}
      }  
      return Observable.from(
        App.api.a2censo.deleteLegalRepresentative({
          ...data
        })
      ).pipe(
        flatMap(() => {
          return Observable.of(
            RegisterActions.registerLegalRepresentativeSuccess(),
            RegisterFormActions.eraseLegalRepresentative()
          )
        }),
        catchError(e => {
          if(e.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
            return Observable.concat(
              Observable.of(RegisterActions.registerLegalRepresentativeFail(e)),
              Observable.of(
                push(`/oops`)
              )
            );
          }
        })
      );
    })
  );

export default combineEpics(registerLegalRepresentativeSubmit, updateSubmitLegalRepresentative, deleteCampaignLegalRepresentative);
