import React, { Component } from "react";
import app from "../../lib/app";
import App from "./App";

class AppBooter extends Component {
  state = {
    booting: true,
    failedBooting: false
  };

  componentDidMount() {
    app
      .boot()
      .then(() => {
        return this.setState({ booting: false });
      })
      .catch(() => this.setState({ booting: false, failedBooting: true }));
  }

  render() {
    const { booting, failedBooting } = this.state;
    if (booting) {
      return <Loader />;
    }
    if (failedBooting) {
      return <p>Failed to load. Please try again</p>;
    }
    return <App app={app} />;
  }
}

const Loader = () => <img src="Spinner-1s-200px.svg" alt="loader" />;

export default AppBooter;
