const { Serializer } = require("jsonapi-serializer");

module.exports = {
    alertManagementSerializer: (meta = {}) =>
      new Serializer("alertManagement", {
        meta,
        pluralizeType: false,
        keyForAttribute: "snake_case",
        attributes: ["report_id", "action_rep", "justification", "user_email", "user_ip", "type_reported", "file", "file_name"]
      })
  };
  