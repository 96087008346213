import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitRegisterLegalRepresentative: ["data"],
  updateRegisterLegalRepresentative: ["data"],
  deleteCampaignLegalRepresentative: ["data"],
  registerLegalRepresentativeSuccess: ["response"],
  registerLegalRepresentativeFail: ["error"],
  cleanRegisterLegalRepresentative: [""],
  createLegalRepresentative: ["msj"],
  editLegalRepresentative: ["msj"],
  eraseLegalRepresentative: [],
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetchingLegalRepresentative: false,
  associateId: "",
  error: "",
  createRL: "",
  editRL: ""
};

const cleanRegisterLegalRepresentative = state =>
  Object.assign({}, state, {
    associateId: ""
  });

const submitRegisterLegalRepresentative = state =>
  Object.assign({}, state, {
    isFetchingLegalRepresentative: true
  });

const updateRegisterLegalRepresentative = state =>
  Object.assign({}, state, {
    isFetchingLegalRepresentative: true
  });

const deleteCampaignLegalRepresentative = state =>
  Object.assign({}, state, {
    isFetchingLegalRepresentative: true
  });

const registerLegalRepresentativeSuccess = state =>
  Object.assign({}, state, {
    isFetchingLegalRepresentative: false,
  });
const registerLegalRepresentativeFail = state =>
  Object.assign({}, state, {
    isFetchingLegalRepresentative: false,
    error: registerLegalRepresentativeFail
  });

const createLegalRepresentative = (state, { msj: message }) =>
  Object.assign({}, state, {
    createRL: message
  });

const editLegalRepresentative = (state, { msj: message }) =>
  Object.assign({}, state, {
    editRL: message
  });

const eraseLegalRepresentative = state =>
  Object.assign({}, state, {
    createRL: "",
    editRL: ""
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_REGISTER_LEGAL_REPRESENTATIVE]: submitRegisterLegalRepresentative,
  [Types.UPDATE_REGISTER_LEGAL_REPRESENTATIVE]: updateRegisterLegalRepresentative,
  [Types.DELETE_CAMPAIGN_LEGAL_REPRESENTATIVE]: deleteCampaignLegalRepresentative,
  [Types.REGISTER_LEGAL_REPRESENTATIVE_SUCCESS]: registerLegalRepresentativeSuccess,
  [Types.REGISTER_LEGAL_REPRESENTATIVE_FAIL]: registerLegalRepresentativeFail,
  [Types.CLEAN_REGISTER_LEGAL_REPRESENTATIVE]: cleanRegisterLegalRepresentative,
  [Types.CREATE_LEGAL_REPRESENTATIVE]: createLegalRepresentative,
  [Types.EDIT_LEGAL_REPRESENTATIVE]: editLegalRepresentative,
  [Types.ERASE_LEGAL_REPRESENTATIVE]: eraseLegalRepresentative
});
