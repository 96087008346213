import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    validateCampaignFailed: ["error"],
    validateCampaignSuccess: ["response"],
    startValidateCampaign: ["data"],
    processCampaignFailed: ["error"],
    processCampaignSuccess: ["message"],
    startProcessCampaign: ["data"],
    eraseProcessErrorMessage: [""],
    eraseTransactionsData: [""],
    eraseData:[""],
});

export const AwardCampaignTypes = Types;
export default Creators;

export const INITIAL_STATE = {
    isFetching: false,
    isFetchingProcess: false,
    response : "",
    error: "",
    errorProcess: "",
    messageProcess: "",
    data:"",
    showMessage: false,
    openModal: false
};

const startValidateCampaign = state =>
Object.assign({}, state, {
    isFetching: true,
});

const validateCampaignSuccess = (state, {response: data}) =>
Object.assign({}, state, {
  isFetching: false,
  data: data,
  openModal: true,
  error: "",
  showMessage: true
});

const validateCampaignFailed = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    openModal: false,
    message: "",
    showMessage: true
  });

const startProcessCampaign = state =>
  Object.assign({}, state, {
    isFetchingProcess: true,
  });
  
const processCampaignSuccess = (state, {message}) =>
  Object.assign({}, state, {
    isFetchingProcess: false,
    errorProcess: "",
    messageProcess: message,
    openModal: false
  });
  
const processCampaignFailed = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetchingProcess: false,
    errorProcess: responseError,
    messageProcess: "",
  });

const eraseProcessErrorMessage = state =>
  Object.assign({}, state, {
    errorProcess: ""
  })

const eraseTransactionsData = state =>
  Object.assign({}, state, {
    data: "",
    error: ""
  })

const eraseData = state => 
  Object.assign({}, state, INITIAL_STATE)


export const reducer = createReducer(INITIAL_STATE, {
    [Types.START_VALIDATE_CAMPAIGN]: startValidateCampaign,
    [Types.VALIDATE_CAMPAIGN_SUCCESS]: validateCampaignSuccess,
    [Types.VALIDATE_CAMPAIGN_FAILED]: validateCampaignFailed,
    [Types.START_PROCESS_CAMPAIGN]: startProcessCampaign,
    [Types.PROCESS_CAMPAIGN_SUCCESS]: processCampaignSuccess,
    [Types.PROCESS_CAMPAIGN_FAILED]: processCampaignFailed,
    [Types.ERASE_PROCESS_ERROR_MESSAGE]: eraseProcessErrorMessage,
    [Types.ERASE_TRANSACTIONS_DATA]: eraseTransactionsData,
    [Types.ERASE_DATA]: eraseData,
});