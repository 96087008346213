const { Serializer } = require("jsonapi-serializer");

module.exports = {
  RegisterSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "detail_type",
        "private",
        "title",
        "content",
        "second_content",
        "campaign_id",
        "url",
        "files",
        "user_email",
        "user_ip",
        "url_image",
        "section_id",
        "subsection_id",
        "order",
        "id",
        "subsection_detail_id"
      ]
    })
};
