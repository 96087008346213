import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetValidateInfo: ["data"],
  getValidateInfoSuccess: ["preChargedData"],
  submitRegisterCompany: ["company"],
  updateRegisterCompany: ["company"],
  registerSuccessCompany: ["response"],
  registerFailCompany: ["error"],
  eraseRegisterCompany: []
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  business_name: "",
  phone: "",
  isFetching: false,
  error: "",
  preChargedData: {},
};
const submitGetValidateInfo = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getValidateInfoSuccess = (state, {preChargedData}) => 
  Object.assign({}, state, {
    isFetching: false,
    preChargedData
  });

const submitRegisterCompany = state =>
  Object.assign({}, state, {
    isFetching: true
  });
const updateRegisterCompany = state =>
  Object.assign({}, state, {
    isFetching: true
  });
const registerSuccessCompany = state => 
  Object.assign({}, state, {
    isFetching: false
  });
const registerFailCompany = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError
  });
const eraseRegisterCompany = state =>
  Object.assign({}, state, {
    error: ""
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_VALIDATE_INFO]: submitGetValidateInfo,
  [Types.GET_VALIDATE_INFO_SUCCESS]: getValidateInfoSuccess,
  [Types.SUBMIT_REGISTER_COMPANY]: submitRegisterCompany,
  [Types.UPDATE_REGISTER_COMPANY]: updateRegisterCompany,
  [Types.REGISTER_SUCCESS_COMPANY]: registerSuccessCompany,
  [Types.REGISTER_FAIL_COMPANY]: registerFailCompany,
  [Types.ERASE_REGISTER_COMPANY]: eraseRegisterCompany
});