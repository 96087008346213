const { Serializer } = require("jsonapi-serializer");

module.exports = {
  FileSerializer: (meta = {}) =>
    new Serializer("login", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["path", "bucket_id", "base_64"]
    })
};
