export default {
  apis: {
    a2censo: {
      apiKey: process.env.REACT_APP_API_KEY,
      baseURL: process.env.REACT_APP_API_URL,
      apiACCUrl: process.env.REACT_APP_SERVICE_ACC_API_URL,
      apiACCPrefix: process.env.REACT_APP_SERVICE_ACC_PREFIX,
      origin: process.env.ORIGEN_ADMIN,
      endpoints: {
        getCampaignList: {
          method: "get",
          uri: "/campaigns"
        },
        getCampaign: {
          method: "get",
          uri: "/campaign/{params.id}"
        },
        getCampaigns: {
          method: "get",
          uri: "/campaigns?state={params.state}&business_line_id={params.business_line_id}"
        },
        register: {
          method: "post",
          uri: "/campaign"
        },
        update: {
          method: "put",
          uri: "/campaign/{params.id}"
        },
        login: {
          method: "post",
          uri: "/login"
        },
        registerDetail: {
          method: "post",
          uri: "/campaign_detail"
        },
        updateDetail: {
          method: "put",
          uri: "/campaign_detail/{params.id}"
        },
        deleteDetail: {
          method: "delete",
          uri: "/campaign_detail/delete?campaign_det_id={params.id}&user_email={params.user_email}&user_ip={params.user_ip}"
        },
        getPymeList: {
          method: "get",
          uri: "pymes"
        },
        getCompany: {
          method: "get",
          uri: "/pyme/{params.id}"
        },
        registerCompany: {
          method: "post",
          uri: "/pyme"
        },
        updateCompany: {
          method: "put",
          uri: "/pyme/{params.id}"
        },
        registerAssociate:{
          method: "post",
          uri: "/associate"
        },
        updateAssociate: {
          method: "put",
          uri: "/associate/{params.id}"
        },
        deleteAssociate: {
          method: "delete",
          uri: "/associate/{params.id}?user_email={params.email}&user_ip={params.ip}"
        },
        registerLegalRepresentative:{
          method: "post",
          uri: "/legal-rep"
        },
        updateLegalRepresentative: {
          method: "put",
          uri: "/legal-rep/{params.id}"
        },
        deleteLegalRepresentative: {
          method: "delete",
          uri: "/legal-rep/{params.id}"
        },
        getMulticashList: {
          method: "get",
          uri: "/multicash-list"
        },
        multicashLoad: {
          method: "post",
          uri: "/multicash-load"
        },
        readMulticash: {
          method: "post",
          uri: "/multicash-read"
        },
        updateMulticash: {
          method: "post",
          uri: "/multicash-update"
        },
        downloadFile: {
          method: "post",
          uri: "/download-file"
        },
        getPab: {
          method: "post",
          uri: "/dispersion-file"
        },
        getDispersionList: {
          method: "get",
          uri: "/dispersion-list?date_gen={params.dateSelected}&date_con=&name={params.nameSelected}&state={params.stateSelected}"
        },
        getDispersionStates: {
          method: "get",
          uri: "/dispersion/dispersion_file_states"
        },
        getDispersionHist: {
          method: "get",
          uri: "/dispersion/dispersion_history/{params.id}"
        },
        getTransactionList: {
          method: "get",
          uri: "/dispersion/dispersion-info?state={params.stateSelected}&date_gen={params.dateSelected}&type={params.typeSelected}"
        },
        getTransactionStates: {
          method: "get",
          uri: "/dispersion/dispersion_states?currentState={params.currentState}&txType={params.txType}"
        },
        getTransactionDetail: {
          method: "get",
          uri: "/dispersion/dispersion-detail?id={params.id}"
        },
        dispersionLoad: {
          method: "post",
          uri: "/dispersion-load"
        },
        dispersionChangeState: {
          method: "put",
          uri: "/dispersion/process_dispersion"
        },
        getStaticParameters: {
          method: "get",
          uri: "/parameters?tables={params.tables}&groupId={params.groupId}"
        },
        getParametersList:{
          method: "get",
          uri: "/parameters-list"
        },
        updateParameter:{
          method: "put",
          uri: "/parameters/{params.id}"
        },
        getProductsFNGList:{
          method: "get",
          uri: "/listFNGProducts?user_email={params.email}&user_ip={params.ip}"
        },
        getProductsFNGTypes:{
          method: "get",
          uri: "/listFNGTypes"
        },
        createProductsFNG:{
          method: "post",
          uri: "/createFNGProduct"
        },
        updateProductsFNG:{
          method: "put",
          uri: "/updateFNGProduct/{params.id}"
        },
        deleteProductsFNG:{
          method: "delete",
          uri: "/deleteFNGProduct/{params.id}?user_email={params.email}&user_ip={params.ip}"
        },
        dispersionPyme: {
          method: "post",
          uri: "/campaign/{params.id}"
        },
        getUserList: {
          method: "get",
          uri: "/users"
        },
        updateUserState:{
          method: "put",
          uri: "/users/{params.id}"
        },
        apiGateway: {
          method: "get",
          uri:"/test/auth/test-1"
        },
        annotateCampaign: {
          method: "post",
          uri: "/annotation/generate-p-file/{params.id}"
        },
        loadFileZ: {
          method: "post",
          uri: "/annotation/filez"
        },
        getAnnotations: {
          method: "get",
          uri: "/annotation/campaigns-annotation?date_annotation={params.dateAnnotationSelected}&date_campaign={params.dateCampaignSelected}&campaign_state={params.campaignState}&annotation_state={params.annotationState}&business_line={params.businessLine}"
        },
        getAnnotationDetail: {
          method: "get",
          uri: "/annotation/annotation-detail/{params.id}?annotation_state={params.annotationState}&start_selection={params.startSelection}&end_selection={params.endSelection}"
        },
        getPatrimonialCampaigns: {
          method: "get",
          uri: "/patrimonial/patrimonial-campaigns"
        },
        getPatrimonialCampaign: {
          method: "get",
          uri: "/patrimonial/campaign-patrimonial-batch/{params.id}"
        },
        getPatrimonialDetail: {
          method: "get",
          uri: "/patrimonial/campaign-patrimonial-items/{params.id}"
        },
        generateTransactions: {
          method: "post",
          uri: "/patrimonial/accepted-patrimonial/{params.id}"
        },
        validateCampaign: {
          method: "get",
          uri: "/campaign/validate-campaign/{params.id}"
        },
        processCampaign: {
          method: "post",
          uri: "/campaign/process-campaign/{params.id}"
        },
        approvedPatrimonial: {
          method: "post",
          uri: "/patrimonial/approved-patrimonial/{params.id}"
        },
        getPaymentCampaigns: {
          method: "get",
          uri: "/payment/paying-campaigns"
        },
        getPaymentCampaignsStates: {
          method: "get",
          uri: "/payment/paying-states"
        },
        getPaymentCampaign: {
          method: "get",
          uri: "/payment/campaign-payments?campaign={params.campaignSelectedId}&state={params.stateSelectedId}"
        },
        getPaymentDetail: {
          method: "get",
          uri: "/payment/paying-detail/{params.id}"
        },
        getValidateInfo: {
          method: "get",
          uri: "/userPyme?document_type={params.document_type}&document_number={params.document_number}"
        },
        getFormCampaignList: {
          method: "get",
          uri: "/campaigns/time-terms"
        },
        getAnnotationCampaigns: {
          method: "get",
          uri: "/annotation/campaigns-states"
        },
        getAnnotationStates: {
          method: "get",
          uri: "/annotation/annotation-states"
        },    
        getAnnotationHist: {
          method: "get",
          uri: "/annotation/annotation-history/{params.id}"
        },
        getAnnotationByFile: {
          method: "get",
          uri: "/annotation/annotations-batch?file_name={params.name}&date_gen={params.date_gen}&date_upload={params.date_upload}"
        },
        getAnnotationByFileDetail: {
          method: "get",
          uri: "/annotation/annotation-items/{params.id}"
        },
        getEmissionData: {
          method: "get",
          uri: "/annotation/emission-data/{params.id}"
        },
        getStatesForChange: {
          method: "get",
          uri: "/annotation/annotation-detail-states"
        },
        saveEmissionData: {
          method: "put",
          uri: "/annotation/register-emission-data/{params.id}"
        },
        annotate: {
          method: "post",
          uri: "/annotation/generate-p-file/{params.id}"
        },
        changeState: {
          method: "put",
          uri: "/annotation/procces_annotation/{params.annotation_detail_id}"
        },
        getPymesCampaignList: {
          method: "get",
          uri: "/campaign_request?date_gen={params.dateSelected}&state={params.stateSelected}"
        },
        getPymeCampaignStates: {
          method: "get",
          uri: "/campaign_request/campaign_states"
        },  
        getNameList: {
          method: "get",
          uri: "/pyme_module/reported_user?state={params.stateSelected}&date={params.validationDate}&pyme_id={params.companySelected}&reported={params.reported}"
        },
        getAlertManagementStates: {
          method: "get",
          uri: "/pyme_module/reported_user_states"
        },
        getFatcaByPyme: {
          method: "get",
          uri: "/pyme_module/fatca/?pyme_id={params.pymeId}"
        },
        getSelfAssessmentData: {
          method: "get",
          uri: "/vinculation/autoevaluation/?campaign_id={params.campaignId}&req_campaign_id={params.req_campaign_id}"
        },
        getRepAssociate:{
          method: "get",
          uri: "/vinculation/rep-associate?id={params.company_id}"
        },
        getInfoPyme: {
          method: "get",
          uri: "/pyme_module/infoPyme/?pyme_id={params.pymeId}"
        },
        getVinculationStates: {
          method: "get",
          uri: "/vinculation/vinculation_states"
        },
        getVinculationList: {
          method: "get",
          uri: "/vinculation?state={params.stateSelected}"
        },
        getPymeTeam: {
          method: "get",
          uri: "/pyme_module/pymeTeam?pyme_id={params.pymeId}"
        },
        updateReported: {
          method: "put",
          uri: "/pyme_module/process_reported_user"
        },
        getCampaignRequest:{
          method: "get",
          uri: "/campaign_request/detail?request_id={params.campaignId}"
        },
        getDocumentsByStage: {
          method: "get",
          uri: "/campaign_request/detail/documentList?request_id={params.campaignId}&stage={params.stage_id}"
        },
        documentChangeState: {
          method: "put",
          uri: "/campaign_request/detail/initialFilters/documentStateChange?document_id={params.document_id}&action={params.action}"
        },
        getAdditionalDocs: {
          method: "get",
          uri: "/campaign_request/detail/additionalDocs?stage_id={params.stage_id}"
        },
        getInterviewValidation: {
          method: "get",
          uri: "/interview/dateValidation?start_date={params.start_date}&end_date={params.end_date}"
        },
        getInterviewData: {
          method: "get",
          uri: "/interview/getInterviewByReqCamp?req_campaign_id={params.req_campaign_id}"
        },
        scheduleInterview: {
          method: "post",
          uri: "/interview/scheduleInterview?req_campaign_id={params.req_campaign_id}"
        },
        updateOrAddDocs: {
          method: "put",
          uri: "/campaign_request/detail/updateOrAdditionalDocs"
        },
        resultByStage: {
          method: "put",
          uri: "/campaign_request/detail/resultByStage"
        },
        resultInterview: {
          method: "put",
          uri: "/campaign_request/detail/initerview/resultInterview"
        },
        deleteInterview: {
          method: "delete",
          uri: "/interview/deleteInterviewByReqCamp?request_id={params.request_id}&user_email={params.user_email}&user_ip={params.user_ip}"
        },
        uploadDoc: {
          method: "post",
          uri: "/campaign_request/detail/uploadDoc"
        },
        getRiskClassification: {
          method: "get",
          uri: "/campaign_request/getRiskClassification"
        },
        getCompanyClassification: {
          method: "get",
          uri: "/campaign_request/getCompanyClassification"
        },
        getRequestCampaignInfo: {
          method: "get",
          uri: "/campaign_request/detail/getRequestCampaignInfo/{params.id}"
        },
        updateRequestCampaignClass: {
          method: "put",
          uri: "/campaign_request/detail/updateRequestCampaignClassification"
        },
        getFngInfo: {
          method: "get",
          uri: "/campaign_request/detail/getFngInfo?company_id={params.company_id}"
        },
        addFngInfo: {
          method: "post",
          uri: "/campaign_request/detail/addFngInfo"
        },
        getComiteCompanyInfo: {
          method: "get",
          uri: "/campaign_request/detail/getComiteCompanyInfo?company_id={params.company_id}"
        },
        addComiteCompanyInfo: {
          method: "post",
          uri: "/campaign_request/detail/addComiteCompanyInfo"
        },
        getComiteFinancialConditions: {
          method: "get",
          uri: "/campaign_request/detail/getComiteFinancialConditions?request_campaign_id={params.request_campaign_id}"
        },
        financialCondTerms: {
          method: "post",
          uri: "/campaign_request/detail/submitFinancialCondTerms"
        },
        getStageListByRequestCampaign: {
          method: "get",
          uri: "/campaign_request/detail/getStageListByRequestCampaign?request_campaign_id={params.request_campaign_id}"
        },
        markDocumentsToPublish: {
          method: "put",
          uri: "/campaign_request/detail/markDocumentsToPublish"
        },
        publicationResult: {
          method: "put",
          uri: "/campaign_request/detail/publicationResult"
        },
        getCompanyBankInfo: {
          method: "get",
          uri: "/vinculation/getCompanyBankInfo?company_id={params.company_id}"
        },
        saveCompanyBankInfo: {
          method: "put",
          uri: "/vinculation/saveCompanyBankInfo"
        },
        getSimulatorFeatureComPercentage: {
          method: "get",
          uri: "/campaign_request/detail/getSimulatorFeatureComPercentage?request_campaign_id={params.request_campaign_id}"
        },
        getAllDocuments: {
          method: "get",
          uri: "/document/getAllDocuments?stage={params.stage}"
        },
        setDocumentActive: {
          method: "put",
          uri: "/document/setDocumentActive"
        },
        getStages: {
          method: "get",
          uri: "/document/getStages?flag={params.flag}"
        },
        createDocument: {
          method: "post",
          uri: "/document/createDocument"
        },
        updateDocument: {
          method: "put",
          uri: "/document/updateDocument"
        },
        getParametersDispersionType: {
          method: "get",
          uri: "/parameters?tables=_dispersion_type"
        },
        getLogsByPyme: {
          method: "get",
          uri: "/pyme_module/getLogsByPyme?company_id={params.company_id}"
        },
        getLogsByReqCampaign: {
          method: "get",
          uri: "/campaign_request/detail/getLogsByReqCampaign?request_campaign_id={params.request_campaign_id}"
        },
        getCampaignStateHistory: {
          method: "get",
          uri: "/campaigns/getCampaignStateHistory?campaign_id={params.campaign_id}"
        },
        getCampaignInvestors: {
          method: "get",
          uri: "/campaigns/campaignInvestments?campaign_id={params.campaign_id}"
        },
        removeInvestor: {
          method: "put",
          uri: "/campaigns/removeInvestor"
        },
        getAllMsgReject: {
          method: "get",
          uri: "/campaign_request/detail/getAllMsgReject?stage={params.stage}"
        },
        getMessageInfo: {
          method: "get",
          uri: "/message/allMessages?stage_id={params.stage_id}"
        },
        createMessage: {
          method: "post",
          uri: "/message/createMessage"
        },
        editMessage: {
          method: "put",
          uri: "/message/editMessage"
        },
        getPymesWithCertificates: {
          method: "get",
          uri: "/pymesWithCertificates"
        },
        getCertificatesByPyme: {
          method: "get",
          uri: "/certificatesByPyme?pyme_id={params.pyme_id}"
        },
        updateCertificateByPyme: {
          method: "put",
          uri: "/updateCertificateByPyme"
        },
        getPymesByTypeReported: {
          method: "get",
          uri: "/pyme_module/getPymesByTypeReported?type_reported={params.type_reported}"
        },
        getCodeptorsByCampaignReq: {
          method: "get",
          uri: "/campaign_request/getCodeptorsByCampaignReq?campaign_Id={params.campaign_Id}"
        },
        updateCodebtorState: {
          method: "put",
          uri: "/campaign_request/updateCodebtorState"
        },
        accreditPatrimonial: {
          method: "put",
          uri: "/patrimonial/accreditPatrimonial"
        },
        getAllInvestors: {
          method: "get",
          uri: "/getAllInvestors"
        },
        getInvestorById: {
          method: "get",
          uri: "/getInvestorById?investor_id={params.investor_id}"
        },
        getAllCitiesInTheWorld: {
          method: "get",
          uri: "/getAllCitiesInTheWorld"
        },
        updateInfoInvestors: {
          method: "put",
          uri: "/updateInfoInvestors"
        },
        getAvaliableDateAndCloseDate: {
          method: "get",
          uri: "/getAvaliableDateAndCloseDate"
        },
        inactivateCampaign: {
          method: "put",
          uri: "/inactivateCampaign"
        },
        getBusinessLine: {
          method: "get",
          uri: "/getBusinessLine"
        },
        getCampaignStates: {
          method: "get",
          uri: "/getCampaignStates"
        },
        getNoveltiesByType: {
          method: "get",
          uri: "/getNoveltiesByType?novelty_type={params.novelty_type}"
        },
        deleteNoveltieById: {
          method: "delete",
          uri: "/deleteNoveltieById?novelty_id={params.novelty_id}&novelty_type={params.novelty_type}&user_email={params.user_email}&user_ip={params.user_ip}"
        },
        getTypesMultimedia: {
          method: "get",
          uri: "/getTypesMultimedia"
        },
        getAllCampaigns: {
          method: "get",
          uri: "/getAllCampaigns"
        },
        addNewNoveltie: {
          method: "post",
          uri: "/addNewNoveltie"
        },
        updateNoveltieById: {
          method: "put",
          uri: "/updateNoveltieById"
        },
        uploadZip: {
          method: "post",
          uri: "/campaign_create/uploadZipFile"
        },
        unZip: {
          method: "get",
          uri: "/campaign_create/getunzip?file={params.file}"
        },
        dataExcelCampaign: {
          method: "get",
          uri: "/campaign_create/getdataexcelcampaign?file={params.file}&arrFile={params.arrFile}"
        },
        sectionExcelCampaign: {
          method: "get",
          uri: "/campaign_create/getsectionexcelcampaign?file={params.file}&arrFile={params.arrFile}"
        },
        getNewCampaign: {
          method: "get",
          uri: "/campaignNew/{params.id}"
        },
        dataValidatePyme: {
          method: "get",
          uri: "/datavalidatepyme/{params.document}"
        },
        nitbyPymes: {
          method: "get",
          uri: "/nitbyPymes"
        },
        subsectionDetailType: {
          method: "get",
          uri: "/subsectionDetailType"
        },
        subsectionDetail: {
          method: "get",
          uri: "/subsectionDetail"
        },
        campaignSection: {
          method: "get",
          uri: "/campaignSection"
        },
        listCoDebtors: {
          method: "get",
          uri: "/listCoDebtors/{params.id}"
        },
        listCoDebtorsIndividual: {
          method: "get",
          uri: "/listCoDebtors/{params.id}?document_type={params.documentType}&document_number={params.documentNumber}"
        },
        listCoDebtorsFilter: {
          method: "get",
          uri: "/listCoDebtors/{params.id}?deleted={params.deleted}",
        },
        listAssociates: {
          method: "get",
          uri: "/listAssociates/{params.id}"
        },
        createCoDebtors: {
          method: "post",
          uri: "/coDebtors"
        },
        editCoDebtors: {
          method: "put",
          uri: "/coDebtors/{params.id}"
        },
        deleteCoDebtors: {
          method: "delete",
          uri: "/deleteAssociateCodebtor/{params.id}?user_email={params.email}&user_ip={params.ip}"
        }
      }
    }
  },
  aws: {
    assets: process.env.REACT_APP_AWS_URL
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN
  },
  isProduction: process.env.NODE_ENV === "production",
  resources: process.env.REACT_APP_RESOURCES_URL,
  sessionTimeout: process.env.REACT_APP_SESSION_TIMEOUT
};
