import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector  } from "react-redux";

const ProtectedRoute = ({ component: Component, pageFunctionalities, ...rest }) => {
    const idToken = useSelector(({login})=>login && login.idToken);
    const funcionalitiesRole = useSelector(({login})=>login && login.funcionalitiesRole);
    return(
        <Route {...rest} render={props => {            
            let hasFuncionality = false;
            if(funcionalitiesRole.length && pageFunctionalities){
                funcionalitiesRole.forEach((e1) => pageFunctionalities[0].forEach((e2)=>{
                    if(e1.id === e2.id){
                        hasFuncionality = true
                    }
                }))
            }
           
            if (!idToken || !hasFuncionality) {
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
            props.location.pageFunctionalities = funcionalitiesRole;
            return <Component {...props} />
        }} />
    )
}

export default ProtectedRoute;