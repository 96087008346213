import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGeneratePab: ["data"],
  generatePabSuccess: ["data"],
  generatePabFail: ["message"],
  erasePabFile:[""],
  submitGetDispersion: ["dateSelected", "nameSelected", "stateSelected"],
  getDispersionSuccess: ["filterData"],
  submitGetDispersionHist: ["dispHistId"],
  getDispersionHistSuccess: ["dispHistData"],
  getDispersionStatesSuccess: ["DispersionStates"]
});

export const DispersionTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data:"",
  error:"",
  state:"",
  filterData:"",
  dispHistData: "",
  DispersionStates: ""
};

const submitGetDispersion = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getDispersionSuccess = (state, {filterData}) =>
  Object.assign({}, state, {
    isFetching: false,
    filterData
  });
  
const erasePabFile = state =>   
  Object.assign({}, state, {
    data: "",
    error: ""
  });
  
const submitGeneratePab = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const generatePabSuccess = (state, {data}) =>
  Object.assign({}, state, {
    isFetching: false,
    data
  });

const generatePabFail = (state, { message }) => 
  Object.assign({}, state, {
    isFetching: false,
    error: message
  });

const submitGetDispersionHist = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getDispersionHistSuccess = (state, {dispHistData}) =>
  Object.assign({}, state, {
    isFetching: false,
    dispHistData
  });

const getDispersionStatesSuccess = (state, {DispersionStates}) =>
Object.assign({}, state, {
  isFetching: false,
  DispersionStates
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GENERATE_PAB]: submitGeneratePab,
  [Types.GENERATE_PAB_SUCCESS]: generatePabSuccess,
  [Types.GENERATE_PAB_FAIL]: generatePabFail,
  [Types.ERASE_PAB_FILE]: erasePabFile,
  [Types.SUBMIT_GET_DISPERSION]: submitGetDispersion,
  [Types.GET_DISPERSION_SUCCESS]: getDispersionSuccess,
  [Types.SUBMIT_GET_DISPERSION_HIST]: submitGetDispersionHist,
  [Types.GET_DISPERSION_HIST_SUCCESS]: getDispersionHistSuccess,
  [Types.GET_DISPERSION_STATES_SUCCESS]: getDispersionStatesSuccess
});
