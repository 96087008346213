import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle` 

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container > input {
  ${props => props.theme.smartphone} {
    font-size: 13px !important;
  }
}

`;

export default GlobalStyle;
