import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import PaymentActions from "../../redux/reducers/payment.reducer";
import { forkJoin } from "rxjs";

export const startPaymentShowcase = () => ({
    type: "START_PAYMENT_SHOWCASE"
});

export const paymentShowcase = action$ =>
    action$.pipe(
        ofType("START_PAYMENT_SHOWCASE"),
        flatMap(() => {
            const { payment } = App.redux.store.getState();
            const url = { campaignSelectedId: "", stateSelectedId: ""};
            let request;
            if (payment.campaignSelected) {
                request = forkJoin([
                    Observable.from(App.api.a2censo.getPaymentCampaigns()),
                    Observable.from(App.api.a2censo.getPaymentCampaignsStates()),
                    Observable.from(App.api.a2censo.getPaymentCampaign({ url }))
                ])
            } else {
                request = forkJoin([
                    Observable.from(App.api.a2censo.getPaymentCampaigns()),
                    Observable.from(App.api.a2censo.getPaymentCampaignsStates()),
                    Observable.from(App.api.a2censo.getPaymentCampaign({ url }))
                ])
            }
            return request.pipe(
                flatMap((response) => {
                    if (payment) {
                        return Observable.of(
                            push(`/payment`, { response: response[0], items: response[1], payment: response[2] })
                        )
                    } else {
                        return Observable.of(
                            push(`/payment`, { response: response[0], items: response[1], payment: response[2] })
                        )
                    }
                }),
                catchError((e) => {
                    if (e.statusCode === 401) {
                        return Observable.of(LoginActions.submitLogout())
                    } else {
                        return Observable.of(
                            push(`/oops`)
                        )
                    }
                })
            );
        })
    );

export const getPaymentCampaign = action$ =>
    action$.pipe(
        ofType("SUBMIT_GET_PAYMENT_CAMPAIGN"),
        flatMap((action) => {
            const url = { campaignSelectedId: action.campaignSelectedId? action.campaignSelectedId:"", 
            stateSelectedId: action.stateSelectedId? action.stateSelectedId:""};
            let request = Observable.from(App.api.a2censo.getPaymentCampaign({ url }));
            return request.pipe(
                flatMap((response) => {
                    return Observable.of(
                        PaymentActions.getPaymentCampaignSuccess({ id: action.campaignData, payment: response })
                    )
                }),
                catchError((e) => {
                    if (e.statusCode === 401) {
                        return Observable.of(LoginActions.submitLogout())
                    } else {
                        return Observable.of(
                            push(`/oops`)
                        )
                    }
                })
            );
        })
    );

export const getPaymentDetail = action$ =>
    action$.pipe(
        ofType("SUBMIT_GET_PAYMENT_DETAIL"),
        flatMap(action => {
            const url = { id: action.data };
            return Observable.from(
                App.api.a2censo.getPaymentDetail({
                    url
                })
            ).pipe(
                flatMap((items) => {
                    return Observable.of(
                        PaymentActions.getPaymentDetailSuccess(items),
                        push(`payment-detail`)
                    )
                }),
                catchError((response) => {
                    if (response.data) {
                        return Observable.concat(
                            Observable.of(
                                PaymentActions.actionFailed(response.data.attributes.message)
                            )
                        );
                    } else if (response.statusCode === 401) {
                        return Observable.of(LoginActions.submitLogout())
                    } else {
                        return Observable.concat(
                            Observable.of(
                                push(`oops`)
                            )
                        );
                    }
                })
            );
        })
    );

export default combineEpics(paymentShowcase, getPaymentCampaign, getPaymentDetail);
