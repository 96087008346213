import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import Button from "../Button/Button";
import "./Header.scss";
import LoginActions from "../../redux/reducers/login.reducer";
import Pages from "../../lib/functionalities";
import IdleTimerContainer from '../IdleTimerContainer/IdleTimerContainer';
import loadRedux from "../../lib/loaders/redux_loader";
import { persistStore } from "redux-persist";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import App from "../../lib/app.js";

const Header = () => {
  const dispatch = useDispatch();
  const idToken = useSelector(({ login }) => login && login.idToken);
  let funcionalitiesRole = useSelector(({login})=>login && login.funcionalitiesRole);
  const { t } = useTranslation();

  if(!funcionalitiesRole){
    funcionalitiesRole = [];
  }

  const sessionTimeout = (inactivity) =>{
    if(inactivity) logout()
  }

  const logout = async () => {
    // Consultamos el store y posteriormente inicializamos las variables a vacias
    let store = (await loadRedux()).store
    let persistor = persistStore(store);
    setTimeout(() => persistor.purge(), 500)
    dispatch(
      LoginActions.submitLogout()
    );
    sessionStorage.clear();
    localStorage.clear();
  };

  let showCampaign = false;
  let showPymes = false;
  let showMulticash = false;
  let showDispersion = false;
  let showParameters = false;
  let showParametersFNG = false;
  let showUsers = false;
  let showAnnotation = false;
  let showPatrimonial = false;
  let showPayment = false;
  let showAnnotationByFile = false;
  let showTransaction = false;
  let showAlertManagement = false;
  let showVinculation = false;
  let showRequestCampaing = false;
  let showDocManagement = false;
  let showResponseManagement = false;
  let showCertificates = false;
  let showInvestors = false;
  let showNews = false;

  //Show Campaign
  funcionalitiesRole.forEach((e1) => Pages.Campaign.forEach((e2)=>{
    if(e1.id === e2.id){
      showCampaign = true
    }
  }))

  //Show Company
  funcionalitiesRole.forEach((e1) => Pages.Company.forEach((e2)=>{
    if(e1.id === e2.id){
      showPymes = true
    }
  }))
  
  //Show Multicash
  funcionalitiesRole.forEach((e1) => Pages.Multicash.forEach((e2)=>{
    if(e1.id === e2.id){
      showMulticash = true
    }
  }))

  //Show Dispersion
  funcionalitiesRole.forEach((e1) => Pages.Dispersion.forEach((e2)=>{
    if(e1.id === e2.id){
      showDispersion = true
    }
  }))

  //Show Parameters
  funcionalitiesRole.forEach((e1) => Pages.Parameters.forEach((e2)=>{
    if(e1.id === e2.id){
      showParameters = true
    }
  }))

  //Show ParametersFNG
  funcionalitiesRole.forEach((e1) => Pages.ParametersFNG.forEach((e2)=>{
    if(e1.id === e2.id){
      showParametersFNG = true
    }
  }))

  //Show Users
  funcionalitiesRole.forEach((e1) => Pages.Users.forEach((e2)=>{
    if(e1.id === e2.id){
      showUsers = true
    }
  }))

  //Show Annotation
  funcionalitiesRole.forEach((e1) => Pages.Annotation.forEach((e2)=>{
    if(e1.id === e2.id){
      showAnnotation = true
    }
  }))

  //Show Patrimonial
  funcionalitiesRole.forEach((e1) => Pages.Patrimonial.forEach((e2)=>{
    if(e1.id === e2.id){
      showPatrimonial = true
    }
  }))
  
  //Show Payment
  funcionalitiesRole.forEach((e1) => Pages.Payment.forEach((e2)=>{
    if(e1.id === e2.id){
      showPayment = true
    }
  }))

  //Show Transaction
  funcionalitiesRole.forEach((e1) => Pages.Transaction.forEach((e2)=>{
    if(e1.id === e2.id){
      showTransaction = true
    }
  }))

  //Show AnnotationByFile
  funcionalitiesRole.forEach((e1) => Pages.Annotation.forEach((e2)=>{
    if(e1.id === e2.id){
      showAnnotationByFile = true
    }
  }))

  //Show showAlertManagement
  funcionalitiesRole.forEach((e1) => Pages.AlertManagement.forEach((e2)=>{
    if(e1.id === e2.id){
      showAlertManagement = true
    }
  }))

  //Show showVinculation
  funcionalitiesRole.forEach((e1) => Pages.Vinculation.forEach((e2)=>{
    if(e1.id === e2.id){
      showVinculation = true
    }
  }))

  //Show showRequestCampaing
  funcionalitiesRole.forEach((e1) => Pages.PymesCampaign.forEach((e2)=>{
    if(e1.id === e2.id){
      showRequestCampaing = true
    }
  }))

  //Show showDocManagement
  funcionalitiesRole.forEach((e1) => Pages.DocManagement.forEach((e2)=>{
    if(e1.id === e2.id){
      showDocManagement = true
    }
  }))

  //Show showResponseManagement
  funcionalitiesRole.forEach((e1) => Pages.ResponseManagement.forEach((e2)=>{
    if(e1.id === e2.id){
      showResponseManagement = true
    }
  }))
  //Show showCertificates
  funcionalitiesRole.forEach((e1) => Pages.Certificates.forEach((e2)=>{
    if(e1.id === e2.id){
      showCertificates = true
    }
  }))
  //Show Investors
  funcionalitiesRole.forEach((e1) => Pages.Investors.forEach((e2)=>{
    if(e1.id === e2.id){
      showInvestors = true
    }
  }))
  //Show News
  funcionalitiesRole.forEach((e1) => Pages.News.forEach((e2)=>{
    if(e1.id === e2.id){
      showNews = true
    }
  }))

  let optionsAnnotation = []
  let optionsAcounts = []
  let optionsParameters = []
  let optionsPayments = []
  let optionsDispersion = []
  let optionsPyme = [];


  optionsAnnotation.push({text: "Anotación", value: "/annotation", display: showAnnotation})
  optionsAnnotation.push({text: "Anotación por archivo", value: "/annotation-by-file", display: showAnnotationByFile})

  optionsAcounts.push({text: "Usuarios", value: "/users", display: showUsers})
  optionsAcounts.push({text: "Pymes", value: "/companies", display: showPymes})
  optionsAcounts.push({text: "Inversionistas", value: "/investors", display: showInvestors})

  optionsParameters.push({text: "Cuentas bancarias", value: "/parameters", display: showParameters})
  optionsParameters.push({text: "Comisión de garantía del FNG", value: "/pyme_simulator_parameters", display: showParametersFNG})


  optionsPayments.push({text: "Derechos patrimoniales", value: "/patrimonial", display: showPatrimonial})
  optionsPayments.push({text: "Pago Pyme", value: "/payment", display: showPayment})

  optionsDispersion.push({text: "Archivos", value: "/dispersion", display: showDispersion})
  optionsDispersion.push({text: "Transacciones", value: "/transaction", display: showTransaction})

  optionsPyme.push({text: "Gestión de alertas", value: "/alert-management", display: showAlertManagement})
  optionsPyme.push({text: "Vinculación", value: "/vinculation", display: showVinculation})
  optionsPyme.push({text: "Solicitud de campañas", value: "/pymes-campaign", display: showRequestCampaing})
  optionsPyme.push({text: "Gestión de Documentación", value: "/doc-management", display: showDocManagement})
  optionsPyme.push({text: "Gestión de Respuestas", value: "/response-management", display: showResponseManagement} )
  optionsPyme.push({text: "Novedades de Certificados", value: "/certificates", display: showCertificates} )

  return (
    <Fragment>
      <Navbar id="navbarMain" collapseOnSelect expand="lg">
        <Navbar.Brand href="/">
          <img
            className="navbar--logo"
            src="/assets/images/logo.png"
            alt="a2censo"
          />
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            {idToken ?
            <Nav className="mr-auto">
              { showCampaign ? <Nav.Link href="/campaigns" className="text-center">Campañas</Nav.Link>: null }
              
              { optionsAnnotation.filter( el => el.display === true ).length > 0 ? 
              <NavDropdown title="Anotación" className="text-center">
                {optionsAnnotation.map(option => {
                  return option.display ? <NavDropdown.Item href={option.value}>{option.text}</NavDropdown.Item> : null;
                })}
              </NavDropdown> : null }
              
              { optionsDispersion.filter( el => el.display === true ).length > 0 ? 
              <NavDropdown title="Dispersión" className="text-center">
                {optionsDispersion.map(option => {
                  return option.display ? <NavDropdown.Item href={option.value}>{option.text}</NavDropdown.Item> : null;
                })}
              </NavDropdown> : null }
              
              { optionsAcounts.filter( el => el.display === true ).length > 0 ? 
                <NavDropdown title="Cuentas" className="text-center">
                  {optionsAcounts.map(option => {
                    return option.display ? <NavDropdown.Item href={option.value}>{option.text}</NavDropdown.Item> : null;
                  })}
                </NavDropdown> : null }
              
              { optionsParameters.filter( el => el.display === true ).length > 0 ? 
                <NavDropdown title="Parámetros" className="text-center">
                  {optionsParameters.map(option => {
                    return option.display ? <NavDropdown.Item href={option.value}>{option.text}</NavDropdown.Item> : null;
                  })}
                </NavDropdown> : null }
              
              { optionsPayments.filter( el => el.display === true ).length > 0 ? 
                <NavDropdown title="Pagos" className="text-center">
                  {optionsPayments.map(option => {
                    return option.display ? <NavDropdown.Item href={option.value}>{option.text}</NavDropdown.Item> : null;
                  })}
                </NavDropdown> : null }
              
              { optionsPyme.filter( el => el.display === true ).length > 0 ? 
                <NavDropdown title="Pyme" className="text-center">
                  {optionsPyme.map(option => {
                    return option.display ? <NavDropdown.Item href={option.value}>{option.text}</NavDropdown.Item> : null;
                  })}
                </NavDropdown> : null }
              
                { showNews ? <Nav.Link href="/news" className="text-center">Novedades</Nav.Link>: null }

            </Nav> : <Nav className="mr-auto"></Nav> }
            <Nav>
              <Nav.Link>
                {!idToken ?
                  <Button
                    id="login-button"
                    className="navbar__button--turquoise"
                    type="transparent"
                    onClick={() => dispatch(push("/login"))}
                    value={t("login.login")}
                  />:
                  <div>
                    <Button
                      id="login-button"
                      className="navbar__button--turquoise"
                      type="transparent"
                      onClick={() => logout()}
                      value="Salir"
                    />
                    <IdleTimerContainer 
                      action = {sessionTimeout}
                      minuts = {App.config.sessionTimeout}
                    />
                  </div>
                }
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

export default Header;
