import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetDocManagementList: ["stage"],
  getDocManagementListSuccess: ["docManagementList"],
  submitSetDocumentActive: [
    "document_id",
    "active",
    "user_email",
    "user_ip"
  ],
  setDocumentActiveSuccess: ["message"],
  submitGetStages: ["flag"],
  getStagesSuccess: ["docManagementStages"],
  submitCreateDocument: [
    "name",
    "description",
    "stage_id",
    "document_origin_id",
    "type_document_id",
    "classification_id",
    "file",
    "file_name",
    "user_email",
    "user_ip"
  ],
  createDocumentSuccess: ["message"],
  submitUpdateDocument: [
    "document_id",
    "name",
    "description",
    "old_path",
    "file",
    "file_name",
    "user_email",
    "user_ip"
  ],
  updateDocumentSuccess: ["message"],
});

export const docManagementTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  gettingData: false,
  docManagementList: "",
  message: "",
  docManagementStages: ""
};

const submitGetDocManagementList = state =>
  Object.assign({}, state, {
    // isFetching: true
    gettingData: true
  });

const getDocManagementListSuccess = (state, { docManagementList }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    docManagementList
  });

const submitSetDocumentActive = state =>
  Object.assign({}, state, {
    isFetching: true,
    gettingData: true
  });

const setDocumentActiveSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });

const submitGetStages = state =>
  Object.assign({}, state, {
    // isFetching: true,
    gettingData: true
  });

const getStagesSuccess = (state, { docManagementStages }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    docManagementStages
  });

const submitCreateDocument = state =>
  Object.assign({}, state, {
    isFetching: true,
    gettingData: true
  });

const createDocumentSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });

const submitUpdateDocument = state =>
  Object.assign({}, state, {
    isFetching: true,
    gettingData: true,
  });

const updateDocumentSuccess = (state, { message }) =>
  Object.assign({}, state, {
    isFetching: false,
    gettingData: false,
    message
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_DOC_MANAGEMENT_LIST]: submitGetDocManagementList,
  [Types.GET_DOC_MANAGEMENT_LIST_SUCCESS]: getDocManagementListSuccess,
  [Types.SUBMIT_SET_DOCUMENT_ACTIVE]: submitSetDocumentActive,
  [Types.SET_DOCUMENT_ACTIVE_SUCCESS]: setDocumentActiveSuccess,
  [Types.SUBMIT_GET_STAGES]: submitGetStages,
  [Types.GET_STAGES_SUCCESS]: getStagesSuccess,
  [Types.SUBMIT_CREATE_DOCUMENT]: submitCreateDocument,
  [Types.CREATE_DOCUMENT_SUCCESS]: createDocumentSuccess,
  [Types.SUBMIT_UPDATE_DOCUMENT]: submitUpdateDocument,
  [Types.UPDATE_DOCUMENT_SUCCESS]: updateDocumentSuccess
});
