import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitRegisterDetail: ["data"],
  updateRegisterDetail: ["data"],
  deleteCampaignDetail: ["data"],
  registerDetailSuccess: ["response"],
  registerDetailFail: ["error"],
  cleanRegisterDetail: [""]
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetchingDetail: false,
  detailId: "",
  error: ""
};

const cleanRegisterDetail = state =>
Object.assign({}, state, {
  detailId : ""
});

const submitRegisterDetail = state =>
  Object.assign({}, state, {
    isFetchingDetail: true
  });

const updateRegisterDetail = state =>
  Object.assign({}, state, {
    isFetchingDetail: true
  });

const deleteCampaignDetail = state =>
  Object.assign({}, state, {
    isFetchingDetail: true
  });

const registerDetailSuccess = state =>
  Object.assign({}, state, {
    isFetchingDetail: false,
  });
const registerDetailFail = (state, {error}) =>
  Object.assign({}, state, {
    isFetchingDetail: false,
    error: error
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_REGISTER_DETAIL]: submitRegisterDetail,
  [Types.UPDATE_REGISTER_DETAIL]: updateRegisterDetail,
  [Types.DELETE_CAMPAIGN_DETAIL]: deleteCampaignDetail,
  [Types.REGISTER_DETAIL_SUCCESS]: registerDetailSuccess,
  [Types.REGISTER_DETAIL_FAIL]: registerDetailFail,
  [Types.CLEAN_REGISTER_DETAIL]: cleanRegisterDetail
});
