import { catchError, flatMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import FileActions, { FileTypes } from "../reducers/file.reducer";
import { FileSerializer } from "../../lib/serializers/file.serializer";
import PymesCampaignActions from "../reducers/pymesCampaign.reducer";

export const getFile = action$ =>
  action$.pipe(
    ofType(FileTypes.SUBMIT_GET_FILE),
    flatMap(( action ) => {
        const path = action.path;
        const bucket_id = action.bucket_id;
        const base_64 = action.base_64;
        const downloadFunction = action.downloadFunction || function() {};
        const errorFunction = action.errorFunction || function() {};
        const url = action.url;
        return Observable.from(
            App.api.a2censo.downloadFile({
                body: FileSerializer().serialize({ path, bucket_id, base_64, url })
        }))
        .pipe(
            flatMap((response) => {
                downloadFunction(response);
                return Observable.of(
                    FileActions.getFileSuccess(response)
                );
            }),
            catchError(response => {
                if(response.data){
                    errorFunction(response.data.attributes.message);
                    return Observable.concat(
                        Observable.of(
                            FileActions.getFileFail(response.data.attributes.message)
                        )
                    );
                }else {
                    errorFunction(`${response.statusCode}: ERROR EN DESCARGA DE ARCHIVO.`);
                    return Observable.concat(
                        Observable.of(
                            FileActions.getFileFail(`${response.statusCode}: ERROR EN DESCARGA DE ARCHIVO.`)
                        ),
                        Observable.of(
                            FileActions.getFileFail(`${response.statusCode}: ERROR EN DESCARGA DE ARCHIVO.`)
                        )
                    );
                }
            })
        );
    })
  );

export default getFile;