export default {
  showcase: {
    investToday: "What do you want to invest on?",
    ourShowcase: "Our showcase",
    viewDetail: "View detail",
    invest: "Invest",
    createAccount: "Create account"
  },
  campaign: {
    team: "Team",
    partners: "Partners",
    knowUs: "Meet our campaign",
    ourCompany: "Our company",
    simulate: "Simulate"
  },
  financialInfo: {
    rate: "Rate: fixed",
    warranty: "Warranty",
    term: "Term",
    classification: "Classification",
    interest: "Interest payment + capital",
    sector: "Sector"
  },
  anonymous: {
    connectWithA2censo: "Connect with a2censo",
    knowAll: "From this way you can know every",
    campaingAndContent: "campaing and content that we have for you."
  },
  welcome: "Hey, Welcome to",
  grability: "Grability",
  not_found: "Oops!, 404 not found",
  form: {
    email: "Email",
    firstName: "Name",
    lastName: "Lastname",
    send: "Send"
  },
  footer: {
    termAndConditions: "Terms and conditions",
    dataProcessingPolicy: "Data processing policy",
    frequencyQuestions: "Frequency questions",
    resources: "Resources",
    contact: "Contact",
    address: "Cra 7 No 71-21 Torre B Piso 12 Bogotá D.C.",
    phone: "(+57 1) 313 9000",
    email: "a2censo@bvc.com.co",
    aProduct: "A product",
    support: "Support",
    poweredBy: "Powered by"
  },
  personType: {
    whatKindOfPersonYouAre: "¿What kind of person you are?",
    naturalPerson: "Natural person",
    naturalPersonRules:
      "You have a Colombian ID or an immigration ID, and you have a bank account in Colombia.",
    legalPerson: "Legal person",
    legalPersonRules:
      "Do you identify with a Colombian NIT or are a foreign company a bank account in Colombia."
  },
  oops: {
    sorry: "Sorry, it's not you, ",
    itsUs: "it's us.",
    tryAgain: "We are presenting some inconveniences, please try again later.",
    goHome: "Go at home"
  },
  checkEmail: {
    checkYourEmail: "Check your email",
    completedSuccessfully: "You have successfully completed your data.",
    inYourEmail: "In your email you will find a confirmation message ",
    youMustUse: "that you should check to continue"
  },
  successView: {
    processFinishedSuccessfully: "Process finished successfully"
  },
  login: {
    title: "Login",
    firstTime: "first time in a2censo?",
    login: "Log in",
    register: "Sign up",
    forgotPassword: "Did you forget your password?",
    invalidCredentials: "email or password are not valid on our platform"
  },
  vinculationExpired: {
    linkExpired: "This link has expired!",
    linkExpiredDescription:
      "This link has exceeded 30 days since it was sent. Register again and continue with the link in a2censo."
  }
};
