import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import ResponseManagementActions, {responseManagementTypes} from "../../redux/reducers/responseManagement.reducer";
import { createMessageSerializer } from "../../lib/serializers/createMessage.serializer";
import { editMessageSerializer } from "../../lib/serializers/editMessage.serializer";

export const StartResponseManagementShowcase = () => ({
  type: "START_RESPONSE_MANAGEMENT_SHOWCASE"
});

export const responseManagementShowcase = action$ => action$.pipe(
  ofType("START_RESPONSE_MANAGEMENT_SHOWCASE"),
  flatMap(action => {
    const url = {
      stage_id: action.stage ? action.stage : ""
    };
    return Observable.from(
      App.api.a2censo.getMessageInfo({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          push(`/response-management`, { response })
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitGetResponseManagementList = action$ => 
action$.pipe(
  ofType("SUBMIT_GET_RESPONSE_MANAGEMENT_LIST"),
  flatMap(action => {
    const url = {
      stage_id: action.stage ? action.stage : ""
    };
    return Observable.from(
      App.api.a2censo.getMessageInfo({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          ResponseManagementActions.getResponseManagementListSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              console.log("oops")
            )
          );
        }
      })
    );
  })
);


export const createMessage = action$ =>
  action$.pipe(
    ofType("SUBMIT_CREATE_MESSAGE"),
    flatMap((action) => {
      const message_text = action.message_text;
      const stages = action.stages;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: createMessageSerializer().serialize({
          message_text,
          stages,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.createMessage({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            ResponseManagementActions.createMessageSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              ResponseManagementActions.errorActionResponseManagement(e.data.attributes)
            )
          }
        })
      );
    })
  );


export const editMessage = action$ =>
  action$.pipe(
    ofType("SUBMIT_EDIT_MESSAGE"),
    flatMap((action) => {
      const message_id = action.message_id;
      const message_text = action.message_text;
      const stages = action.stages;
      const active = action.active;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: editMessageSerializer().serialize({
          message_id,
          message_text,
          stages,
          active,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.editMessage({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            ResponseManagementActions.editMessageSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              ResponseManagementActions.errorActionResponseManagement(e.data.attributes)
            )
          }
        })
      );
    })
  );


export default combineEpics(responseManagementShowcase, editMessage, createMessage, submitGetResponseManagementList );