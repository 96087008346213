import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetCities: ["data"],
  getCompanieCitiesSuccess: ["cities"],
  getLegalCitiesSuccess: ["cities"],
  getAssociatedCitiesSuccess: ["cities"],
  getCodebtorCitiesSuccess: ["cities"],
  getAssociatedDepartmentsSuccess: ["department"],
  getCodebtorDepartmentsSuccess: ["department"],
  getCitiesFail: ["message"],
  eraseCities: [],
  eraseAssociatedCity: [],
  eraseDataCodebtor: [],
});

export const CityTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: "",
  companieCities: [],
  legalCities: [],
  associatedCities: [],
  associatedDepartment: [],
  codebtorDepartment: [],
  codebtorCity: [],
};

const submitGetCities = (state) =>
  Object.assign({}, state, {
    isFetching: true,
  });

const getCompanieCitiesSuccess = (state, { cities }) =>
  Object.assign({}, state, {
    isFetching: false,
    companieCities: cities.response._city,
  });

const getLegalCitiesSuccess = (state, { cities }) =>
  Object.assign({}, state, {
    isFetching: false,
    legalCities: cities.response._city,
  });

const getAssociatedCitiesSuccess = (state, { cities }) =>
  Object.assign({}, state, {
    isFetching: false,
    associatedCities: cities.response._city,
  });

const getCodebtorCitiesSuccess = (state, { cities }) =>
  Object.assign({}, state, {
    isFetching: false,
    codebtorCity: cities.response._city,
  });

const getAssociatedDepartmentsSuccess = (state, { department }) =>
  Object.assign({}, state, {
    isFetching: false,
    associatedDepartment: department.response._department,
  });

const getCodebtorDepartmentsSuccess = (state, { department }) =>
  Object.assign({}, state, {
    isFetching: false,
    codebtorDepartment: department.response._department,
  });

const getCitiesFail = (state, message) =>
  Object.assign({}, state, {
    isFetching: false,
    error: message,
  });

const eraseCities = (state) =>
  Object.assign({}, state, {
    companieCities: [],
    legalCities: [],
    associatedCities: [],
    associatedDepartment: [],
  });

const eraseAssociatedCity = (state) =>
  Object.assign({}, state, {
    associatedCities: [],
    associatedDepartment: [],
  });

const eraseDataCodebtor = (state) =>
  Object.assign({}, state, {
    codebtorDepartment: [],
    codebtorCity: [],
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_CITIES]: submitGetCities,
  [Types.GET_COMPANIE_CITIES_SUCCESS]: getCompanieCitiesSuccess,
  [Types.GET_LEGAL_CITIES_SUCCESS]: getLegalCitiesSuccess,
  [Types.GET_ASSOCIATED_CITIES_SUCCESS]: getAssociatedCitiesSuccess,
  [Types.GET_CODEBTOR_CITIES_SUCCESS]: getCodebtorCitiesSuccess,
  [Types.GET_ASSOCIATED_DEPARTMENTS_SUCCESS]: getAssociatedDepartmentsSuccess,
  [Types.GET_CODEBTOR_DEPARTMENTS_SUCCESS]: getCodebtorDepartmentsSuccess,
  [Types.GET_CITIES_FAIL]: getCitiesFail,
  [Types.ERASE_CITIES]: eraseCities,
  [Types.ERASE_ASSOCIATED_CITY]: eraseAssociatedCity,
  [Types.ERASE_DATA_CODEBTOR]: eraseDataCodebtor,
});
