const { Serializer } = require("jsonapi-serializer");

module.exports = {
  updateCodebtorStateSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "codebtor_id",
        "action",
        "user_email",
        "user_ip",
        "comments"
      ]
    })
};
