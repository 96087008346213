import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import PymesCampaignActions, { pymesCampaignTypes } from "../reducers/pymesCampaign.reducer";
//import { GetReqCampaognSerializer } from "../../lib/serializers/pymesReqCampaign.serializer";
import { documentChangeStateSerializer } from "../../lib/serializers/documentChangeState.serializer";
import { scheduleInterviewSerializer } from "../../lib/serializers/scheduleInterview.serializer";
import { updateOrAddDocsSerializer } from "../../lib/serializers/documentsUpdate.serializer";
import { resultByStageSerializer } from "../../lib/serializers/resultInitialFilters.serializer";
import { resultInterviewSerializer } from "../../lib/serializers/resultInterview.serializer";
import { fileChargeSerializer } from "../../lib/serializers/fileCharge.serializer";
import { updateRequestCampaignClassSerializer } from "../../lib/serializers/updateRequestCampaignClass.serializer";
import { addFngInfoSerializer } from "../../lib/serializers/addFngInfo.serializer";
import { addComiteCompanyInfoSerializer } from "../../lib/serializers/addComiteCompanyInfo.serializer";
import { financialCondTermsSerializer } from "../../lib/serializers/financialCondTerms.serializer";
import { markDocumentsToPublishSerializer } from "../../lib/serializers/markDocumentsToPublish.serializer";
import { publicationResultSerializer } from "../../lib/serializers/publicationResult.serializer";
import { updateCodebtorStateSerializer } from "../../lib/serializers/updateCodebtorState.serializer";

import { requestFileWithURL } from "../../components/LoadDocument/LoadDocument";
import { forkJoin } from "rxjs";

export const startPymesCampaignShowcase = () => ({
  type: "SUBMIT_GET_CAMPAIGN_STATE"
});

export const getCampaignState = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_CAMPAIGN_STATE"),
    flatMap((action) => {
      return Observable.from(App.api.a2censo.getPymeCampaignStates({})
      ).pipe(
        flatMap((CampaignStates) => {
          return Observable.of(
            push(`/pymes-campaign`, { CampaignStates })
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getPymesCampaign = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_CAMPAIGN"),
    flatMap((action) => {
      const url = {
        stateSelected: action.stateSelected ? action.stateSelected : "",
        dateSelected: action.dateSelected ? action.dateSelected : "",
      };
      return Observable.from(App.api.a2censo.getPymesCampaignList({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getCampaignSuccess({ response })
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getCampaignRequest = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_CAMPAIGN_REQUEST"),
    flatMap((action) => {
      const url = { campaignId: action.campaignId ? action.campaignId : "" };
      return Observable.from(App.api.a2censo.getCampaignRequest({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getCampaignRequestSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const submitGetDocsByStage = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_DOCS_BY_STAGE"),
    flatMap((action) => {
      const url = {
        campaignId: action.campaignId ? action.campaignId : "",
        stage_id: action.stage_id ? action.stage_id : ""
      };
      let request;
      request = forkJoin([
        Observable.from(App.api.a2censo.getDocumentsByStage({ url })),
        Observable.from(App.api.a2censo.getAdditionalDocs({ url }))
      ])
      return request.pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getDocsByStageSuccess({ documentsData: response[0] }, { additionalDocsList: response[1] })
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getInterviewValidation = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_INTERVIEW_VALIDATION"),
    flatMap((action) => {
      const url = { start_date: action.start_date, end_date: action.end_date }
      return Observable.from(App.api.a2censo.getInterviewValidation({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getInterviewValidationSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getInterviewData = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_INTERVIEW_DATA"),
    flatMap((action) => {
      const req_campaign_id = { req_campaign_id: action.req_campaign_id };
      const url = { req_campaign_id: action.req_campaign_id }
      return Observable.from(App.api.a2censo.getInterviewData({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getInterviewDataSuccess(response, req_campaign_id)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const documentChangeState = action$ =>
  action$.pipe(
    ofType("SUBMIT_DOCUMENT_CHANGE_STATE"),
    flatMap((action) => {
      const comments = action.comments;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: documentChangeStateSerializer().serialize({ comments, user_email, user_ip }),
        url: {
          document_id: action.document_id,
          action: action.action
        }
      }
      return Observable.from(App.api.a2censo.documentChangeState({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.documentChangeStateSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const scheduleInterview = action$ =>
  action$.pipe(
    ofType("SUBMIT_SCHEDULE_INTERVIEW"),
    flatMap((action) => {
      const start_date = action.start_date;
      const end_date = action.end_date;
      const virtual = action.virtual;
      const place = action.place;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: scheduleInterviewSerializer().serialize({ start_date, end_date, virtual, place, user_email, user_ip }),
        url: { req_campaign_id: action.req_campaign_id }
      }
      return Observable.from(App.api.a2censo.scheduleInterview({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.scheduleInterviewSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );


export const submitUpdateOrAddDocs = action$ =>
  action$.pipe(
    ofType("SUBMIT_UPDATE_OR_ADD_DOCS"),
    flatMap((action) => {
      const action_req = action.action_req;
      const stage = action.stage;
      const request_id = action.request_id;
      const docs_id = action.docs_id;
      const comments = action.comments;
      const pyme_name = action.pyme_name;
      const pyme_email = action.pyme_email;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const checkCondFinancial = action.checkCondFinancial;
      const data = {
        body: updateOrAddDocsSerializer().serialize({ action_req, stage, request_id, docs_id, comments, pyme_name, pyme_email, user_email, user_ip, checkCondFinancial })
      }
      return Observable.from(App.api.a2censo.updateOrAddDocs({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.updateOrAddDocsSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const resultByStage = action$ =>
  action$.pipe(
    ofType("SUBMIT_RESULT_BY_STAGE"),
    flatMap((action) => {
      const request_id = action.request_id;
      const action_req = action.action_req;
      const stage = action.stage;
      const justification = action.justification;
      const comments = action.comments;
      const pyme_name = action.pyme_name;
      const pyme_email = action.pyme_email;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const has_codeptor = action.has_codeptor;
      const docs_id = action.docs_id;
      const functionality_id = action.functionality_id;
      const msg_id = action.msg_id;
      const reject_html = action.reject_html;
      const data = {
        body: resultByStageSerializer().serialize({
          request_id,
          action_req,
          stage,
          justification,
          comments,
          pyme_name,
          pyme_email,
          user_email,
          user_ip,
          has_codeptor,
          docs_id,
          functionality_id,
          msg_id,
          reject_html,
        })
      }
      return Observable.from(App.api.a2censo.resultByStage({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.resultByStageSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              PymesCampaignActions.resultByStageFailed(e.data.attributes)
            )
          }
        })
      );
    })
  );

export const resultInterview = act$ =>
  act$.pipe(
    ofType("SUBMIT_RESULT_INTERVIEW"),
    flatMap((act) => {
      const request_id = act.request_id;
      const action = act.action;
      const result = act.result;
      const comments = act.comments;
      const has_codeptor = act.has_codeptor;
      const user_email = act.user_email;
      const user_ip = act.user_ip;
      const data = {
        body: resultInterviewSerializer().serialize({ request_id, action, result, comments, has_codeptor, user_email, user_ip })
      }
      return Observable.from(App.api.a2censo.resultInterview({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.resultInterviewSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );


export const deleteInterview = action$ =>
  action$.pipe(
    ofType("SUBMIT_DELETE_INTERVIEW"),
    flatMap((action) => {
      const request_id = action.request_id;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        url: { request_id, user_email, user_ip }
      }
      return Observable.from(App.api.a2censo.deleteInterview({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.deleteInterviewSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const uploadDoc = action$ =>
  action$.pipe(
    ofType(pymesCampaignTypes.SUBMIT_UPLOAD_DOC),
    flatMap(action => {
      const file = action.data.file;
      const file_name = action.data.file.file_name;
      const detail_type = action.data.file.detail_type;
      const document_stage_id = action.data.file.document_stage_id;
      const document_id = action.data.file.document_id;
      const document_name = action.data.file.document_name;
      const request_campaign_id = action.data.file.request_campaign_id;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const withURL = true;
      return Observable.from(
        App.api.a2censo.uploadDoc({
          body: fileChargeSerializer().serialize({ file_name, detail_type, document_stage_id, document_id, document_name, request_campaign_id, user_email, user_ip, withURL })
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(requestFileWithURL(file.file_E, "put", response.url))
          .pipe(
            flatMap((res) => {
            return Observable.of(PymesCampaignActions.uploadDocSuccess(response));
            })
            )
        }),
        catchError(response => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(push(`/oops`))
            );
          }
        })
      );
    })
  );



export const getAdditionalDocs = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ADDITIONAL_DOCS"),
    flatMap((action) => {
      const url = { stage_id: action.stage_id }
      return Observable.from(App.api.a2censo.getAdditionalDocs({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getAdditionalDocsSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getRiskClassification = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_RISK_CLASSIFICATION"),
    flatMap((action) => {
      return Observable.from(App.api.a2censo.getRiskClassification()
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getRiskClassificationSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getCompanyClassification = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_COMPANY_CLASSIFICATION"),
    flatMap((action) => {
      return Observable.from(App.api.a2censo.getCompanyClassification()
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getCompanyClassificationSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getRequestCampaignInfo = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_REQUEST_CAMPAIGN_INFO"),
    flatMap((action) => {
      const url = { id: action.request_campaign_id }
      return Observable.from(App.api.a2censo.getRequestCampaignInfo({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getRequestCampaignInfoSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );


export const updateRequestCampaignClass = action$ =>
  action$.pipe(
    ofType("SUBMIT_UPDATE_REQUEST_CAMPAIGN_CLASS"),
    flatMap((action) => {
      const request_campaign_id = action.request_campaign_id;
      const company_classification = action.company_classification;
      const project_classification = action.project_classification;
      const update = action.update;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: updateRequestCampaignClassSerializer().serialize({
          request_campaign_id,
          company_classification,
          project_classification,
          update,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.updateRequestCampaignClass({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.updateRequestCampaignClassSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getFngInfo = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_FNG_INFO"),
    flatMap((action) => {
      const url = { company_id: action.company_id }
      return Observable.from(App.api.a2censo.getFngInfo({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getFngInfoSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const addFngInfo = action$ =>
  action$.pipe(
    ofType("SUBMIT_ADD_FNG_INFO"),
    flatMap((action) => {
      const request_campaign_id = action.request_campaign_id;
      const fng_fee_percentage = action.fng_fee_percentage;
      const fng_coverage_percentage = action.fng_coverage_percentage;
      const fng_warranty_line = action.fng_warranty_line;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: addFngInfoSerializer().serialize({
          request_campaign_id,
          fng_fee_percentage,
          fng_coverage_percentage,
          fng_warranty_line,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.addFngInfo({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.addFngInfoSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getComiteCompanyInfo = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_COMITE_COMPANY_INFO"),
    flatMap((action) => {
      const url = { company_id: action.company_id }
      return Observable.from(App.api.a2censo.getComiteCompanyInfo({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getComiteCompanyInfoSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const addComiteCompanyInfo = action$ =>
  action$.pipe(
    ofType("SUBMIT_ADD_COMITE_COMPANY_INFO"),
    flatMap((action) => {
      const company_id = action.company_id;
      const total_assets = action.total_assets;
      const total_liabilities = action.total_liabilities;
      const total_operational_income = action.total_operational_income;
      const total_operational_outcome = action.total_operational_outcome;
      const net_profit = action.net_profit;
      const data = {
        body: addComiteCompanyInfoSerializer().serialize({
          company_id,
          total_assets,
          total_liabilities,
          total_operational_income,
          total_operational_outcome,
          net_profit
        })
      }
      return Observable.from(App.api.a2censo.addComiteCompanyInfo({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.addComiteCompanyInfoSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getComiteFinancialConditions = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_COMITE_FINANCIAL_CONDITIONS"),
    flatMap((action) => {
      const url = { request_campaign_id: action.request_campaign_id }
      return Observable.from(App.api.a2censo.getComiteFinancialConditions({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getComiteFinancialConditionsSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const financialCondTerms = action$ =>
  action$.pipe(
    ofType("SUBMIT_FINANCIAL_COND_TERMS"),
    flatMap((action) => {
      const campaign_id = action.campaign_id;
      const requested_amount = action.requested_amount;
      const funding_rate = action.funding_rate;
      const term_in_months = action.term_in_months;
      const capital_payment_peridicity = action.capital_payment_peridicity;
      const capital_payment_peridicity_text = action.capital_payment_peridicity_text;
      const capital_grace_period = action.capital_grace_period;
      const capital_grace_period_text = action.capital_grace_period_text;
      const interest_payment_periodicity = action.interest_payment_periodicity;
      const interest_payment_periodicity_text = action.interest_payment_periodicity_text;
      const interest_grace_period = action.interest_grace_period;
      const interest_grace_period_text = action.interest_grace_period_text;
      const comments = action.comments;
      const pyme_name = action.pyme_name;
      const change_request = action.change_request;
      const pyme_email = action.pyme_email;
      const user_email = action.user_email;
      const fngWarrantyLine = action.fngWarrantyLine;
      const fngFeePercentage = action.fngFeePercentage;
      const fngCoveragePercentage = action.fngCoveragePercentage;
      const user_ip = action.user_ip;
      const data = {
        body: financialCondTermsSerializer().serialize({
          campaign_id,
          requested_amount,
          funding_rate,
          term_in_months,
          capital_payment_peridicity,
          capital_payment_peridicity_text,
          capital_grace_period,
          capital_grace_period_text,
          interest_payment_periodicity,
          interest_payment_periodicity_text,
          interest_grace_period,
          interest_grace_period_text,
          comments,
          pyme_name,
          change_request,
          pyme_email,
          fngWarrantyLine,
          fngFeePercentage,
          fngCoveragePercentage,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.financialCondTerms({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.financialCondTermsSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getStageListByRequestCampaign = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_STAGE_LIST_BY_REQUEST_CAMPAIGN"),
    flatMap((action) => {
      const url = { request_campaign_id: action.request_campaign_id }
      return Observable.from(App.api.a2censo.getStageListByRequestCampaign({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getStageListByRequestCampaignSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const markDocumentsToPublish = action$ =>
  action$.pipe(
    ofType("SUBMIT_MARK_DOCUMENTS_TO_PUBLISH"),
    flatMap((action) => {
      const request_campaign_id = action.request_campaign_id;
      const document_list = action.document_list;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: markDocumentsToPublishSerializer().serialize({
          request_campaign_id,
          document_list,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.markDocumentsToPublish({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.markDocumentsToPublishSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getDocumentsByStage = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_DOCS_FOR_PUBLISH"),
    flatMap((action) => {
      const url = {
        campaignId: action.campaignId ? action.campaignId : "",
        stage_id: action.stage_id ? action.stage_id : ""
      };
      return Observable.from(App.api.a2censo.getDocumentsByStage({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getDocsForPublishSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getSimulatorFeatureComPercentage = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_SIMULATOR_FEATURE_COM_PERCENTAGE"),
    flatMap((action) => {
      const url = {
        request_campaign_id: action.request_campaign_id ? action.request_campaign_id : ""
      };
      let request = forkJoin([
        Observable.from(App.api.a2censo.getSimulatorFeatureComPercentage({ url })),
        Observable.from(App.api.a2censo.getAvaliableDateAndCloseDate())
      ])
      return request
      .pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getSimulatorFeatureComPercentageSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const publicationResult = action$ =>
  action$.pipe(
    ofType("SUBMIT_PUBLICATION_RESULT"),
    flatMap((action) => {
      const request_id = action.request_id;
      const action_req = action.action_req;
      const simulator = action.simulator;
      const featured = action.featured;
      const com_percentage = action.com_percentage;
      const justification = action.justification;
      const comments = action.comments;
      const bvc_share = action.bvc_share;
      const pyme_name = action.pyme_name;
      const pyme_email = action.pyme_email;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const financial_doc_name = action.financial_doc_name;
      const functionality_id = action.functionality_id;
      const msg_id = action.msg_id;
      const reject_html = action.reject_html;
      const reject_msg = action.reject_msg;
      const close_date = action.close_date;
      const available_investment_date = action.available_investment_date;
      const data = {
        body: publicationResultSerializer().serialize({
          request_id,
          action_req,
          simulator,
          featured,
          com_percentage,
          justification,
          comments,
          bvc_share,
          pyme_name,
          pyme_email,
          user_email,
          user_ip,
          financial_doc_name,
          functionality_id, 
          msg_id,
          reject_html,
          reject_msg,
          close_date,
          available_investment_date
        })
      }
      return Observable.from(App.api.a2censo.publicationResult({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.publicationResultSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              PymesCampaignActions.resultByStageFailed(e.data.attributes)
            )
          }
        })
      );
    })
  );

export const submitGetLogsByReqCampaign = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_LOGS_BY_REQ_CAMPAIGN"),
    flatMap((action) => {
      const url = {
        request_campaign_id: action.request_campaign_id
      };
      return Observable.from(App.api.a2censo.getLogsByReqCampaign({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getLogsByReqCampaignSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const submitGetAllMsgReject = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_ALL_MSG_REJECT"),
    flatMap((action) => {
      const url = {
        stage: action.stage
      };
      return Observable.from(App.api.a2censo.getAllMsgReject({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getAllMsgRejectSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const submitGetCodeptorsByCampaignReq = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_CODEBTORS"),
    flatMap((action) => {
      const url = {
        campaign_Id: action.campaign_Id
      };
      return Observable.from(App.api.a2censo.getCodeptorsByCampaignReq({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.getCodebtorsSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );


export const updateCodebtorState = action$ =>
  action$.pipe(
    ofType("SUBMIT_UPDATE_CODEBTOR_STATE"),
    flatMap((act) => {
      const codebtor_id = act.codebtor_id;
      const action = act.action;
      const user_email = act.user_email;
      const user_ip = act.user_ip;
      const comments = act.comments;
      const data = {
        body: updateCodebtorStateSerializer().serialize({
          codebtor_id,
          action,
          user_email,
          user_ip,
          comments
        })
      }
      return Observable.from(App.api.a2censo.updateCodebtorState({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            PymesCampaignActions.updateCodebtorStateSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const getProductsFNGList = action$ =>
  action$.pipe(
    ofType("GET_PRODUCTS_FNG_LIST"),
    flatMap((action) => {
      const data = {
        url: {
          email:action.user_email,
          ip: action.user_ip
        }
      }
      return Observable.from(App.api.a2censo.getProductsFNGList({...data})
      ).pipe(
        flatMap((response) => {
          let formatResponse = [{valueFee: 0, value: 0, label: "No aplica", coverage_percentage: 0}]
            .concat(response
            .filter((e) => e.is_active && e.id != 0)            
            .map((value) => {
              return {
                valueFee: value.value,
                value: value.id,
                label: value.name,
                coverage_percentage: value.coverage_percentage,
              };
            }));
          return Observable.of(
            PymesCampaignActions.getProductsFNGListSuccess(formatResponse)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export default combineEpics(getPymesCampaign, getCampaignRequest,getCampaignState, submitGetDocsByStage, documentChangeState,
  getAdditionalDocs, getInterviewValidation, getInterviewData, scheduleInterview, submitUpdateOrAddDocs, resultByStage, resultInterview,
  deleteInterview, uploadDoc, getRiskClassification, getCompanyClassification, getRequestCampaignInfo, updateRequestCampaignClass,
  getFngInfo, addFngInfo, getComiteCompanyInfo, addComiteCompanyInfo, getComiteFinancialConditions, financialCondTerms,
  getStageListByRequestCampaign, markDocumentsToPublish, getDocumentsByStage, publicationResult, getSimulatorFeatureComPercentage, submitGetLogsByReqCampaign,
  submitGetAllMsgReject, submitGetCodeptorsByCampaignReq, updateCodebtorState, getProductsFNGList
);