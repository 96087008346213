import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetAnnotationDetail: ["annotation_campaign_id", "campaign_id"],
  submitGetAnnotationDetailFilteredData: ["annotation_campaign_id", "campaign_id", "annotationStateFilter", "fromFilter", "toFilter"],
  getAnnotationDetailSuccess: ["data", "annotation_campaign_id", "campaign_id"],
  submitGetAnnotations: ["dateAnnotationSelected", "dateCampaignSelected", "campaignState", "annotationState","businessLine"],
  getAnnotationsSuccess: ["annotationList"],
  submitGetAnnotationHist: ["hist"],
  getAnnotationHistSuccess: ["hist"],
  submitGetEmissionData: ["emissionData"],
  getEmissionDataSuccess: ["emissionData"],
  getEmissionDataFailed: ["error"],
  saveEmissionDataBatch: ["data"],
  saveEmissionDataBatchSuccess: ["message"],
  actionFailed: ["error"],
  eraseMessage:[""],
  eraseFilePath:[""],
  annotateBatch:["data"],
  annotateBatchSuccess: ["file_path"],
  submitGetStatesForChange: [""],
  getStatesForChangeSuccess: ["statesForChange"],
  saveNewState: ["annotation_detail_id", "data"],
  saveNewStateSuccess: ["message"],
  setManualErrorMessage: ["error"],
  getAnnotationCampaignsSuccess: ["campaigns"],
  getAnnotationBusinessLine: ["businessLine"],
  getAnnotationStatesSuccess: ["states"]
});

export const AnnotationTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data:"",
  error:"",
  annotationList: "",
  hist: "",
  emissionData: "",
  message: "",
  annotation_campaign_id: "",
  campaign_id: "",
  text: "",
  file_path: "",
  statesForChange: "",
  annotation_detail_id: "",
  campaigns: "",
  states: "",
  businessLine: ""

};

const submitGetAnnotations = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const setManualErrorMessage = state =>
  Object.assign({}, state, {
    isFetching: false,
    error: "No se puede anotar porque faltan datos de emisión"
  });

const getAnnotationsSuccess = (state, { annotationList }) =>
  Object.assign({}, state, {
    isFetching: false,
    annotationList: annotationList.response,
  });
  
const submitGetAnnotationDetail = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const submitGetAnnotationDetailFilteredData = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getAnnotationDetailSuccess = (state, {data, annotation_campaign_id, campaign_id}) =>
Object.assign({}, state, {
  isFetching: false,
  data,
  annotation_campaign_id,
  campaign_id
});

const submitGetAnnotationHist = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getAnnotationHistSuccess = (state, {hist}) =>
  Object.assign({}, state, {
    isFetching: false,
    hist
  }); 

const submitGetEmissionData = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getEmissionDataSuccess = (state, {emissionData}) =>
  Object.assign({}, state, {
    isFetching: false,
    emissionData: emissionData[0]
  });

const getEmissionDataFailed = (state, {error}) =>
  Object.assign({}, state, {
    isFetching: false,
    error
  });

const submitGetStatesForChange = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getStatesForChangeSuccess = (state, {statesForChange}) =>
  Object.assign({}, state, {
    isFetching: false,
    statesForChange,
  });

const saveNewState = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const saveNewStateSuccess = (state,  {message}) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message
  });

  const saveEmissionDataBatch = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const saveEmissionDataBatchSuccess = (state,  {message}) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message
  });

const eraseMessage = state =>   
  Object.assign({}, state, {
    message: "",
    error: "",
    text: "",
  });

const eraseFilePath = state =>   
  Object.assign({}, state, {
    file_path: "",
    text: "",
  });

const actionFailed = (state, {error}) => 
  Object.assign({}, state, {
    isFetching: false,
    error
  });
  
const annotateBatch = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const annotateBatchSuccess = (state,  {file_path}) =>
  Object.assign({}, state, {
    isFetching: false,
    file_path: file_path,
    text: file_path.text,
  });

const getAnnotationCampaignsSuccess = (state,  {campaigns}) => {
  return (
    Object.assign({}, state, {
      isFetching: false,
      campaigns
    })
  )
}

const getAnnotationStatesSuccess = (state,  {states}) =>
  Object.assign({}, state, {
    isFetching: false,
    states
  });

  const getAnnotationBusinessLine = (state, { businessLine }) => {
    return (
      Object.assign({}, state, {
        isFetching: false,
        businessLine
      })
    )
  }

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_ANNOTATION_DETAIL]: submitGetAnnotationDetail,
  [Types.SUBMIT_GET_ANNOTATION_DETAIL_FILTERED_DATA]: submitGetAnnotationDetailFilteredData,
  [Types.GET_ANNOTATION_DETAIL_SUCCESS]: getAnnotationDetailSuccess,
  [Types.SUBMIT_GET_ANNOTATIONS]: submitGetAnnotations,
  [Types.GET_ANNOTATIONS_SUCCESS]: getAnnotationsSuccess,
  [Types.SUBMIT_GET_ANNOTATION_HIST]: submitGetAnnotationHist,
  [Types.GET_ANNOTATION_HIST_SUCCESS]: getAnnotationHistSuccess,
  [Types.SUBMIT_GET_EMISSION_DATA]: submitGetEmissionData,
  [Types.GET_EMISSION_DATA_SUCCESS]: getEmissionDataSuccess,
  [Types.GET_EMISSION_DATA_FAILED]: getEmissionDataFailed,
  [Types.SAVE_EMISSION_DATA_BATCH]: saveEmissionDataBatch,
  [Types.SAVE_EMISSION_DATA_BATCH_SUCCESS]: saveEmissionDataBatchSuccess,
  [Types.ANNOTATE_BATCH]: annotateBatch,
  [Types.ANNOTATE_BATCH_SUCCESS]: annotateBatchSuccess,
  [Types.ACTION_FAILED]: actionFailed,
  [Types.ERASE_MESSAGE]: eraseMessage,
  [Types.ERASE_FILE_PATH]: eraseFilePath,
  [Types.SUBMIT_GET_STATES_FOR_CHANGE]: submitGetStatesForChange,
  [Types.GET_STATES_FOR_CHANGE_SUCCESS]: getStatesForChangeSuccess,
  [Types.SAVE_NEW_STATE]: saveNewState,
  [Types.SAVE_NEW_STATE_SUCCESS]: saveNewStateSuccess,
  [Types.SET_MANUAL_ERROR_MESSAGE]: setManualErrorMessage,
  [Types.GET_ANNOTATION_CAMPAIGNS_SUCCESS]: getAnnotationCampaignsSuccess,
  [Types.GET_ANNOTATION_STATES_SUCCESS]: getAnnotationStatesSuccess,
  [Types.GET_ANNOTATION_BUSINESS_LINE]: getAnnotationBusinessLine
});
