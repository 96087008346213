import React from "react";
import "./Button.scss";
import PropTypes from "prop-types";

const Button = ({ className, value, onClick, type, disabled }) => {
  return (
    <button
      onClick={onClick}
      id="button"
      className={`button button--${type} ${className}`}
      disabled={disabled}
    >
      {value}
    </button>
  );
};

Button.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: "regular",
  className: "",
  disabled: false
};

export default Button;
