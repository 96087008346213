const { Serializer } = require("jsonapi-serializer");

module.exports = {
  markDocumentsToPublishSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_campaign_id",
        "document_list",
        "user_email",
        "user_ip"
      ]
    })
};
