import { catchError, flatMap, map } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import CodebtorActions from "../reducers/codebtor.reducer";
import {
  CreatePartnerCoDebtorSerializer,
  CreateExternalCoDebtorSerializer,
  EditExternalCoDebtorSerializer,
} from "../../lib/serializers/pymesCoDebtors.serializer.js";
import { forkJoin } from "rxjs";

export const getListAssociates = (id) => ({
  type: "GET_LIST_ASSOCIATES",
  id,
});

export const getPymeCodebtor = (id, tables, groupId) => ({
  type: "GET_PYME_CODEBTOR",
  id,
  tables,
  groupId,
});

export const pymeCodebtorData = (action$) =>
  action$.pipe(
    ofType("GET_PYME_CODEBTOR"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: action.tables, groupId: action.groupId },
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            push(`/pymes-co-debtors/${action.id}`, {
              response,
            })
          );
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(push(`/oops`));
          }
        })
      );
    })
  );

export const getGetListCodebtors = (action$) =>
  action$.pipe(
    ofType("GET_LIST_CODEBTORS"),
    flatMap((action) => {
      let codebtors;
      if (action.deleted) {
        codebtors = Observable.from(
          App.api.a2censo.listCoDebtorsFilter({
            url: { id: action.requestCampaignId, deleted: action.deleted },
          })
        );
      } else {
        codebtors = Observable.from(
          App.api.a2censo.listCoDebtors({
            url: { id: action.requestCampaignId },
          })
        );
      }
      return codebtors.pipe(
        flatMap((response) => {
          return Observable.of(CodebtorActions.setListCodebtors(response));
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(push(`/oops`));
          }
        })
      );
    })
  );

export const getGetListAssociates = (action$) =>
  action$.pipe(
    ofType("GET_LIST_ASSOCIATES"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.listAssociates({
          url: { id: action.id },
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CodebtorActions.setListAssociates(response));
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(push(`/oops`));
          }
        })
      );
    })
  );

export const partnerCoDebtorSubmit = (action$) =>
  action$.pipe(
    ofType("SUBMIT_PARTNER_CODEBTOR"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.createCoDebtors({
          body: CreatePartnerCoDebtorSerializer().serialize(action.data),
        })
      ).pipe(
        flatMap(() => {
          return Observable.concat(
            Observable.of(
              CodebtorActions.postLoad({
                status: "OK",
                typeAction: "CreateCoDebtor",
              })
            ),
            Observable.of(
              push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
            )
          );
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.concat(
              Observable.of(
                CodebtorActions.postLoad({
                  status: "ERROR",
                  typeAction: "CreateCoDebtor",
                })
              ),
              Observable.of(
                push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
              )
            );
          }
        })
      );
    })
  );

export const externalCoDebtorSubmit = (action$) =>
  action$.pipe(
    ofType("SUBMIT_EXTERNAL_CODEBTOR"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.createCoDebtors({
          body: CreateExternalCoDebtorSerializer().serialize(action.data),
        })
      ).pipe(
        flatMap(() => {
          return Observable.concat(
            Observable.of(
              CodebtorActions.postLoad({
                status: "OK",
                typeAction: "CreateCoDebtor",
              })
            ),
            Observable.of(
              push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
            )
          );
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else if (response.statusCode === 409) {
            return Observable.concat(
              Observable.of(
                CodebtorActions.postLoad({
                  status: "ERROR",
                  typeAction: "CoDebtorExists",
                })
              ),
              Observable.of(
                push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
              )
            );
          } else {
            return Observable.concat(
              Observable.of(
                CodebtorActions.postLoad({
                  status: "ERROR",
                  typeAction: "CreateCoDebtor",
                })
              ),
              Observable.of(
                push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
              )
            );
          }
        })
      );
    })
  );

export const externalCoDebtorUpdate = (action$) =>
  action$.pipe(
    ofType("UPDATE_EXTERNAL_CODEBTOR"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.editCoDebtors({
          body: EditExternalCoDebtorSerializer().serialize(action.data.body),
          url: { id: parseInt(action.data.id) },
        })
      ).pipe(
        flatMap(() => {
          return Observable.concat(
            Observable.of(
              CodebtorActions.postLoad({
                status: "OK",
                typeAction: "EditCoDebtor",
              })
            ),
            Observable.of(
              push(`/pymes-co-debtors/${action.data.body.request_campaign_id}`)
            )
          );
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else if (response.statusCode === 409) {
            return Observable.concat(
              Observable.of(
                CodebtorActions.postLoad({
                  status: "ERROR",
                  typeAction: "CoDebtorExists",
                })
              ),
              Observable.of(
                push(
                  `/pymes-co-debtors/${action.data.body.request_campaign_id}`
                )
              )
            );
          } else {
            return Observable.concat(
              Observable.of(
                CodebtorActions.postLoad({
                  status: "ERROR",
                  typeAction: "EditCoDebtor",
                })
              ),
              Observable.of(
                push(
                  `/pymes-co-debtors/${action.data.body.request_campaign_id}`
                )
              )
            );
          }
        })
      );
    })
  );

export const coDebtorDelete = (action$) =>
  action$.pipe(
    ofType("DELETE_CODEBTOR"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.deleteCoDebtors({
          url: {
            id: parseInt(action.data.id),
            email: action.data.email,
            ip: action.data.ip,
          },
        })
      ).pipe(
        flatMap(() => {
          return Observable.concat(
            Observable.of(
              CodebtorActions.postLoad({
                status: "OK",
                typeAction: "DeleteCoDebtor",
              })
            ),
            Observable.of(
              push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
            )
          );
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.concat(
              Observable.of(
                CodebtorActions.postLoad({
                  status: "ERROR",
                  typeAction: "DeleteCoDebtor",
                })
              ),
              Observable.of(
                push(`/pymes-co-debtors/${action.data.request_campaign_id}`)
              )
            );
          }
        })
      );
    })
  );

export const codebtorConsult = (action$) =>
  action$.pipe(
    ofType("CONSULT_CODEBTOR"),
    flatMap((action) => {
      return Observable.from(
        App.api.a2censo.listCoDebtorsIndividual({
          url: {
            id: action.data.id,
            documentType: action.data.documentType,
            documentNumber: action.data.documentNumber,
          },
        })
      ).pipe(
        flatMap((response) => {
          return Observable.of(CodebtorActions.setCodebtor([...response.info]));
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(CodebtorActions.setErrConsultCodebtor());
          }
        })
      );
    })
  );

export default combineEpics(
  pymeCodebtorData,
  getGetListCodebtors,
  getGetListAssociates,
  partnerCoDebtorSubmit,
  externalCoDebtorSubmit,
  externalCoDebtorUpdate,
  coDebtorDelete,
  codebtorConsult
);
