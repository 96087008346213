const { Serializer } = require("jsonapi-serializer");

module.exports = {
  saveCompanyBankInfoSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "company_id",
        "bank_id",
        "bank_account_type_id",
        "account_number",
        "user_email",
        "user_ip"
      ]
    })
};
