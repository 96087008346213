import { combineReducers } from "redux";
import { reducer as modal } from "./modal.reducer";
import { connectRouter } from "connected-react-router";
import { reducer as register } from "./register-campaign.reducer";
import { reducer as registerDetail } from "./campaign-detail.reducer";
import { reducer as login } from "./login.reducer";
import { reducer as campaign } from "./campaign.reducer";
import { reducer as campaignForm } from "./campaign-form.reducer";
import { reducer as company } from "./company.reducer";
import { reducer as companyForm } from "./company-form.reducer";
import { reducer as legalRepresentative} from "./company-legal-representative.reducer";
import { reducer as associate} from "./company-associate.reducer";
import { reducer as file} from "./file.reducer";
import { reducer as loadMulticash} from "./multicash-load.reducer";
import { reducer as dispersion} from "./dispersion.reducer";
import { reducer as loadDispersion} from "./dispersion-load.reducer";
import { reducer as loadParameter} from "./parameters-load.reducer";
import { reducer as city} from "./city.reducer";
import { reducer as users} from "./users.reducer";
import { reducer as annotation} from "./annotation.reducer";
import { reducer as annotationByFile} from "./annotationByFile.reducer";
import { reducer as patrimonial} from "./patrimonial.reducer";
import { reducer as request } from "./request.reducer";
import { reducer as awardCampaign } from "./award-campaign.reducer";
import { reducer as payment} from "./payment.reducer";
import { reducer as transaction} from './transaction.reducer';
import { reducer as pymesCampaign} from './pymesCampaign.reducer';
import { reducer as alertManagement} from './alertManagement.reducer';
import { reducer as vinculation} from './vinculation.reducer';
import { reducer as docManagement} from './docManagement.reducer';
import { reducer as responseManagement} from './responseManagement.reducer';
import { reducer as certificates} from './certificates.reducer';
import { reducer as investors} from './investors.reducer';
import { reducer as news} from './news.reducer';
import { reducer as acc} from './integration/acc.reducer';
import { reducer as  codebtor} from "./codebtor.reducer";

import resettableReducer from "../../lib/resettableReducer";
const resettable = resettableReducer("RESET_STATE");

export default history =>
  combineReducers({
    router: connectRouter(history),
    register: resettable(register),
    registerDetail: resettable(registerDetail),
    modal: resettable(modal),
    login: resettable(login),
    campaignForm: resettable(campaignForm),
    campaign: resettable(campaign),
    company: resettable(company),
    companyForm: resettable(companyForm),
    legalRepresentative: resettable(legalRepresentative),
    associate: resettable(associate),
    file: resettable(file),
    loadMulticash: resettable(loadMulticash),
    dispersion: resettable(dispersion),
    loadDispersion: resettable(loadDispersion),
    loadParameter: resettable(loadParameter),
    city: resettable(city),
    users: resettable(users),
    annotation: resettable(annotation),
    annotationByFile: resettable(annotationByFile),
    patrimonial: resettable(patrimonial),
    request: resettable(request),
    awardCampaign: resettable(awardCampaign),
    payment: resettable(payment),
    transaction: resettable(transaction),
    pymesCampaign: resettable(pymesCampaign),
    alertManagement: resettable(alertManagement),
    vinculation: resettable(vinculation),
    docManagement: resettable(docManagement),
    responseManagement: resettable(responseManagement),
    certificates: resettable(certificates),
    investors: resettable(investors),
    news: resettable(news),
    acc: resettable(acc),
    codebtor: resettable(codebtor),
  });
