import { catchError, flatMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import MulticashLoadActions, { MulticashLoadTypes } from "../reducers/multicash-load.reducer";
import { MulticashSerializer } from "../../lib/serializers/multicash.serializer";
import LoginActions from "../reducers/login.reducer";

export const startMulticashShowcase = () => ({
    type: "START_MULTICASH_SHOWCASE"
  });

export const multicashShowcase = action$ =>
    action$.pipe(
        ofType("START_MULTICASH_SHOWCASE"),
        flatMap(() => {
            return Observable.from(App.api.a2censo.getMulticashList()
            ).pipe(
                flatMap((response) => {
                    return Observable.of(
                        push(`/multicash`, {response})
                    )
                }),
                catchError((e) => {
                    if(e.statusCode === 401){   
                      return Observable.of(LoginActions.submitLogout())
                    }else {
                      return Observable.of(
                        push(`/oops`)
                      )
                    }
                })
            );
        })
    );

export const multicashLoadSubmit = action$ =>
  action$.pipe(
    ofType(MulticashLoadTypes.SUBMIT_MULTICASH_LOAD),
    flatMap(({ data: { files, email } }) => {
        const json = JSON.stringify({email: email});
        const blob = new Blob([json], {type: 'application/json'});
        files.set('user', blob)
        return Observable.from(
            App.api.a2censo.readMulticash({
            body: files
        }))
        .pipe(
            flatMap((response) => {
                return Observable.of(
                    MulticashLoadActions.multicashLoadSuccess(response),
                );
            }),
            catchError(response => {
                if(response.data){
                    return Observable.concat(
                        Observable.of(
                            MulticashLoadActions.multicashLoadFail(response.data.attributes.message)
                        )
                    );
                }else if(response.statusCode === 401){   
                    return Observable.of(LoginActions.submitLogout())
                  }else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
  );

export const updateMulticashSubmit = action$ =>
  action$.pipe(
    ofType(MulticashLoadTypes.SUBMIT_UPDATE_MULTICASH),
    flatMap(({ data: { date } }) => {
        return Observable.from(
            App.api.a2censo.updateMulticash({
            body: MulticashSerializer().serialize({ date })
        }))
        .pipe(
            flatMap((response) => {
                return Observable.of(
                    MulticashLoadActions.multicashUpdateSuccess(response.message),
                    push(`multicash`)
                );
            }),
            catchError(response => {
                if(response.data){
                    return Observable.concat(
                        Observable.of(
                            MulticashLoadActions.multicashLoadFail(response.data.attributes.message)
                        )
                    );
                }else if(response.statusCode === 401){   
                    return Observable.of(LoginActions.submitLogout())
                }else {
                    return Observable.concat(
                        Observable.of(
                            push(`oops`)
                        )
                    );
                }
            })
        );
    })
  );

export default combineEpics (multicashShowcase, multicashLoadSubmit, updateMulticashSubmit);