const { Serializer } = require("jsonapi-serializer");

module.exports = {
  RegisterSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name",
        "campaign_simulator",
        "image",
        "description",
        "detail_image",
        "video_path",
        "close_date",
        "guaranteed_investment_percentage",
        "risk_classification_id",
        "interest_rate",
        "term_in_months",
        "capital_grace_period",
        "capital_payment",
        "requested_amount",
        "interest_grace_period",
        "interest_payment",
        "sector_id",
        "featured",
        "campaign_serie",
        "origination_fee",
        "fng_fee",
        "_campaign_state_id",
        "minimal_amount",
        "company_id",
        "files",
        "state",
        "rate_type_id",
        "expedition_date",
        "due_date",
        "commission_percentage",
        "email",
        "detail_selected",
        "user_email",
        "user_ip",
        "available_investment_date",
        "fng_warranty_line",
        "document_number",
        "national_guarantee_fund_commission"
      ]
    })
};
