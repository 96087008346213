import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Input from "../Input/Input";
import { useTranslation } from "react-i18next";
import "./LoadDocument.scss";
import { useSelector } from "react-redux";
import SignupSchema from "./LoadDocument.schema";
import { fileToBase64 } from "../../lib/convertBase64";
import { Alert } from "react-bootstrap";
import Button from "../../components/Button/Button";
import axios from "axios";
import { fileToBinary } from "../../lib/convertBase64";

export const LoadDocument = (props) => {
  const {
    allowFiles,
    otherFormat = false,
    id = "none",
    className = null,
    value = null,
    disabled = false,
    sizeAllowed = [],
  } = props;

  const { t } = useTranslation();
  const isFetching = useSelector((state) => state.pymesCampaign.isFetching);
  const error = useSelector((state) => state.pymesCampaign.error);
  const [msg, setMsg] = useState();
  const [submit, setSubmit] = useState();
  const loadDispersion = { file: "" };

  if (submit) props.close();

  //Alerta
  const [showAlert, setShowAlert] = useState(false);

  const [file, setFile] = useState();
  const [name, setName] = useState();
  const [extension, setExtension] = useState();
  const [fileE, setFileE] = useState();

  function validateSize(sizeAllowed, imagen) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = imagen;

      img.onload = () => {
        if (sizeAllowed[0].length && sizeAllowed[1].length) {
          if (
            !(
              sizeAllowed[0][0] === img.naturalWidth &&
              img.naturalWidth === sizeAllowed[0][1]
            ) &&
            !(
              sizeAllowed[1][0] === img.naturalHeight &&
              img.naturalHeight === sizeAllowed[1][1]
            )
          ) {
            setFile(null);
            setName(null);
            setExtension(null);
            setShowAlert(true);
            setMsg(
              `Imagen no cargada. El ancho debe ser de : ${sizeAllowed[0][0]} y ${sizeAllowed[0][1]} px, y el alto entre: ${sizeAllowed[1][0]} y ${sizeAllowed[1][1]} px`
            );
            resolve(false);
          } else resolve(true);
        } else {
          if (
            !(
              img.naturalWidth === sizeAllowed[0] &&
              img.naturalHeight === sizeAllowed[1]
            )
          ) {
            setFile(null);
            setName(null);
            setExtension(null);
            setShowAlert(true);
            setMsg(
              `Imagen no cargada, debe ser de ${sizeAllowed[0]} x ${sizeAllowed[1]} px`
            );
            resolve(false);
          } else resolve(true);
        }
      };
    });
  }

  const loadDoc = async (file, name, extension, e) => {
    setName(name);
    setExtension(extension);
    setFile(file);
  };

  const handleLoadFile = async (e) => {
    if (e.target.files[0]?.size >= 25000000) {
      alert("El archivo excede el limite de 25MB");
      props.close();
      return;
    }
    setFileE(e.target.files[0]);
    let extension = e.target.value
      .toString()
      .split(/(\\|\/)/g)
      .pop()
      .split(".")
      .pop();
    let name = e.target.value
      .toString()
      .split(/(\\|\/)/g)
      .pop();
    let file = null,
      file64 = null;

    fileToBase64(e)
      .then((base64) => {
        file64 = base64;
        file = base64.split("base64").reverse()[0].replace(",", "");
      })
      .then(async () => {
        if (allowFiles) {
          if (allowFiles.includes(extension)) {
            let flag = true;
            if (sizeAllowed.length) {
              flag = await validateSize(sizeAllowed, file64);
            }
            if (flag) loadDoc(file, name, extension, e);
          } else {
            setName(null);
            setExtension(null);
            setFile(null);
            setShowAlert(true);
            setMsg(
              `Archivo ${extension} no permitido. Solo se admiten los formatos: ${allowFiles.join(
                ", "
              )}`
            );
          }
        } else {
          await loadDoc(file, name, extension, e);
        }
      });
  };

  const generateRequest = () => {
    let request = {
      file: file,
      file_name: name,
      detail_type: extension,
      file_E: fileE,
    };
    if (props.rowSelected) {
      request = {
        ...request,
        document_stage_id: props.rowSelected[0]?.document_stage_id || null,
        document_name: props.rowSelected[0]?.document || null,
        document_id: props.rowSelected[0]?.id || null,
        request_campaign_id: props.req_campaign_id || null,
      };
    }

    return request;
  };

  if (showAlert) {
    setTimeout(function () {
      setShowAlert(false);
    }, 4000);
  }

  useEffect(() => {
    if (otherFormat) props.action(generateRequest());
  }, [name, file, extension]);

  return (
    <div>
      {otherFormat ? (
        <div id={id}>
          {error && showAlert ? (
            <Alert
              variant="danger"
              dismissible
              onClose={() => setShowAlert(false)}
            >
              {error}
            </Alert>
          ) : null}
          {msg && showAlert ? (
            <Alert
              variant="danger"
              dismissible
              onClose={() => {
                setShowAlert(false);
                setMsg("");
              }}
              className="alert-div"
            >
              {msg}
            </Alert>
          ) : null}
          <input
            type="file"
            id={`getFile${id}`}
            style={{ display: "none" }}
            onChange={(e) => {
              handleLoadFile(e);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <Button
            id={id}
            className={className ? className : "confirm-button"}
            type="transparent-blue"
            value={value ? value : name && extension ? "Cargado" : "Cargar"}
            onClick={() => {
              document.getElementById(`getFile${id}`).click();
            }}
            disabled={disabled}
          />
        </div>
      ) : (
        <Formik
          initialValues={{
            ...loadDispersion,
          }}
          validationSchema={SignupSchema}
          onSubmit={() => {
            props.action(generateRequest());
            setShowAlert(true);
            setSubmit(true);
            props.close();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            /* and other goodies */
          }) => {
            const errorKeys = Object.keys(errors);
            return (
              <form onSubmit={handleSubmit} className="register__container">
                {error && showAlert ? (
                  <Alert
                    variant="danger"
                    dismissible
                    onClose={() => setShowAlert(false)}
                  >
                    {error}
                  </Alert>
                ) : null}
                {msg && showAlert ? (
                  <Alert
                    variant="danger"
                    dismissible
                    onClose={() => {
                      setShowAlert(false);
                      setMsg("");
                    }}
                    className="alert-div"
                  >
                    {msg}
                  </Alert>
                ) : null}
                <div className="container">
                  <div className="form__container" onSubmit={handleSubmit}>
                    <div className="row">
                      <div
                        className={`col-md-12 large_input ${
                          errors.file && touched.file
                            ? "b-error"
                            : "b-bottom b-bottom-small"
                        }`}
                      >
                        <Input
                          type="file"
                          name="file"
                          id="file"
                          placeholder="File"
                          onChange={(e) => {
                            handleChange(e);
                            handleLoadFile(e);
                          }}
                          onBlur={handleBlur}
                          error={touched.file && errors.file}
                        />
                      </div>
                    </div>
                    <div className="continue__container">
                      <button
                        className="continue__button"
                        id="send_button"
                        type="submit"
                        disabled={errorKeys.length}
                        value={t("register.save")}
                      >
                        {isFetching
                          ? t("register.loading")
                          : t("register.save")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export const requestFile = (file, method, path) => {
  return new Promise(async (resolve, reject) => {
    if(file){
      let fileBinary = await fileToBinary(file);
      await axios[method](path, fileBinary, {
        headers: { "Content-Type": file.type },
      })
      .then((res) => {
        resolve(res) })
      .catch((error) => { console.log("Error:", error);reject(error) });
    }
    resolve(true)
  });
};

export const requestFileWithURL = (file, method, path) => {
  return new Promise(async (resolve, reject) => {
    if(file){
      axios[method](path, file, {
        headers: { "Content-Type": file.type },
      })
      .then((res) => {resolve(res) })
      .catch((error) => { console.log("Error:", error);reject(error) });
    }
    resolve(true)
  });
};