import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import TransactionActions from "../reducers/transaction.reducer";
import LoginActions from "../reducers/login.reducer";
import { TransactionSerializer } from "../../lib/serializers/transaction.serializer";
import { forkJoin } from "rxjs";

export const startTransactionShowcase = () => ({
  type: "START_TRANSACTION_SHOWCASE"
});

export const transactionShowcase = action$ =>
  action$.pipe(
      ofType("START_TRANSACTION_SHOWCASE"),
      flatMap((action) => {
        const url = {
          currentState: action.currentState ? action.currentState: "",
          txType: action.txType ? action.txType : "",};
        let request = forkJoin([
          Observable.from(App.api.a2censo.getTransactionStates({url})),
          Observable.from(App.api.a2censo.getParametersDispersionType())
        ])
        return request.pipe(
              flatMap((response) => {
                  return Observable.of(
                      push(`/transaction`, {listTransactionStates: response[0], dispersionTypeList: response[1]._dispersion_type})
                  )
              }),
              catchError((e) => {
                if(e.statusCode === 401){   
                  return Observable.of(LoginActions.submitLogout())
                }else {
                  return Observable.of(
                    push(`/oops`)
                  )
                }
              })
          );
      })
  );

export const getTransaction = action$ =>
action$.pipe(
    ofType("SUBMIT_GET_TRANSACTION"),
    flatMap((action) => {
        const url = {
        stateSelected: action.stateSelected?action.stateSelected:"",
        typeSelected: action.typeSelected?action.typeSelected:"",
        dateSelected: action.dateSelected?action.dateSelected:"",};
        return Observable.from(App.api.a2censo.getTransactionList({ url })
        ).pipe(
            flatMap((response) => {
                return Observable.of(
                  TransactionActions.getTransactionSuccess({ response })
                )
            }),
            catchError((e) => {
              if(e.statusCode === 401){   
                return Observable.of(LoginActions.submitLogout())
              }else {
                return Observable.of(
                  push(`/oops`)
                )
              }
            })
        );
    })
);

export const getTransactionDetail = action$ =>
action$.pipe(
    ofType("SUBMIT_GET_TRANSACTION_DETAIL"),
    flatMap((action) => {
        const e = action.e;
        const listTransactionStates = action.listTransactionStates;
        const url = { id: action.e.id};
        return Observable.from(App.api.a2censo.getTransactionDetail({ url })
        ).pipe(
            flatMap((response) => {
                console.log("response", response)
                return Observable.of(
                  TransactionActions.getTransactionDetailSuccess({ response }),
                  push(`transaction-detail`, {e, listTransactionStates})
                )
            }),
            catchError((e) => {
              if(e.statusCode === 401){   
                return Observable.of(LoginActions.submitLogout())
              }else {
                return Observable.of(
                  push(`/oops`)
                )
              }
            })
        );
    })
);

export const getTransactionStates = action$ =>
action$.pipe(
    ofType("SUBMIT_GET_TRANSACTION_STATES"),
    flatMap((action) => {
        const url = {
          currentState: action.currentState ? action.currentState: "",
          txType: action.txType ? action.txType : "",};
        return Observable.from(App.api.a2censo.getTransactionStates({url})
        ).pipe(
            flatMap((statesData) => {
                return Observable.of(
                  TransactionActions.getTransactionStatesSuccess({ statesData }),
                )
            }),
            catchError((e) => {
              if(e.statusCode === 401){   
                return Observable.of(LoginActions.submitLogout())
              }else {
                return Observable.of(
                  console.info("Error getting tx states", e)
                )
              }
            })
        );
    })
);

export const changeState = action$ =>
  action$.pipe(
    ofType("SUBMIT_CHANGE_STATE"),
    flatMap((action) => {
      const state = action.state;
      const description = action.description
      const dispersion_id = action.dispersion_id;
      const email = action.email;
      const data = {
        body: TransactionSerializer().serialize({dispersion_id, state, description, email})
      }
      // console.log("data", data)
      return Observable.from(App.api.a2censo.dispersionChangeState({ ...data })
      ).pipe(
        flatMap((stateChangeMessage) => {
          return Observable.of(
            TransactionActions.changeStateSuccess(stateChangeMessage.message)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              TransactionActions.changeStateFailed( e.data.attributes )
            )
          }
        })
      );
    })
  );

  export const getDetailGmf = action$ =>
  action$.pipe(
    ofType("GET_DETAIL_GMF"),
    flatMap(action => {
      const url = {id: action.id};
      const referenceNumber = {reference_number: action.reference_number};
      return Observable.from(
        App.api.a2censo.getTransactionDetail({ url })
      ).pipe(
        flatMap((listTransactionDetail) => {
          return Observable.of(
            TransactionActions.getTransactionDetailSuccess(listTransactionDetail,referenceNumber),
            push(`/transaction-detail`,{referenceNumber})
          )
        }),
        catchError((response) => {
          if(response.statusCode === 401){   
            return Observable.of(LoginActions.submitLogout())
          }else {
              return Observable.concat(
                  Observable.of(
                      push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

export default combineEpics(transactionShowcase, getTransaction, getTransactionDetail, changeState, getDetailGmf,getTransactionStates);
