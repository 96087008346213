const { Serializer } = require("jsonapi-serializer");

module.exports = {
  updateOrAddDocsSerializer: (meta = {}) =>
    new Serializer("updateOrAddDocsSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["action_req", "stage", "request_id", "docs_id", "comments", "pyme_name", "pyme_email", "user_email", "user_ip", "checkCondFinancial"]
    })
};
