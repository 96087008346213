const { Serializer } = require("jsonapi-serializer");

module.exports = {
  AnnotateSerializer: (meta = {}) =>
    new Serializer("annotateSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["campaign_annotation_id", "email", "user_ids"]
    })
};
