const { Serializer } = require("jsonapi-serializer");

module.exports = {
  createDocumentSerializer: (meta = {}) =>
    new Serializer("createDocumentSerializer", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name",
        "description",
        "stage_id",
        "document_origin_id",
        "type_document_id",
        "classification_id",
        "file",
        "file_name",
        "user_email",
        "user_ip"
      ]
    })
};
