export default {
  characters: "caracteres",
  maximum: "Máximo",
  minimum: "Mínimo 2 caracteres",
  campaign: {
    titleCampaign: "Campañas",
    team: "Equipo",
    partners: "Socios",
    knowUs: "Conoce nuestra campaña",
    ourCompany: "Nuestra empresa",
    simulate: "Simular",
  },
  anonymous: {
    connectWithA2censo: "Conéctate con a2censo",
    knowAll: "De esta forma podrás conocer todas las",
    campaingAndContent: "campañas y contenido que tenemos para ti.",
  },
  welcome: "Hola, Bienvenido a",
  grability: "Grability",
  not_found: "Oops!, Seguro que quieres ingresar aqui?",
  footer: {
    termAndConditions: "Términos y condiciones",
    dataProcessingPolicy: "Política de tratamiento de datos",
    frequencyQuestions: "Preguntas frecuentes",
    resources: "Recursos",
    contact: "Contáctenos",
    address: "Cra 7 No 71-21 Torre B Piso 12 Bogotá D.C.",
    phone: "(+57 1) 313 9000",
    email: "a2censo@bvc.com.co",
    aProduct: "Un producto",
    support: "Apoyan",
    poweredBy: "Powered by",
  },
  oops: {
    sorry: "Lo sentimos, no eres tú ",
    itsUs: "somos nosotros.",
    tryAgain:
      "Estamos presentando algunos inconvenientes, por favor inténtalo más tarde.",
    goHome: "Regresar al inicio",
  },
  checkEmail: {
    checkYourEmail: "Revisa tu correo",
    completedSuccessfully: "Has completado tus datos satisfactoriamente.",
    inYourEmail: "En tu correo encontrarás un mensaje de confirmación",
    youMustUse: "que debes revisar para continuar",
  },
  successView: {
    processFinishedSuccessfully: "Proceso finalizado Exitosamente",
  },
  forgotPassword: {
    title: "¿Olvidaste tu contraseña?",
    subtitle:
      "Ingresa el correo que registraste en a2censo para poder continuar.",
    continue: "Continuar",
    newPassword: "Nueva contraseña",
    email: "Correo electrónico",
    cancel: "Cancelar",
    error: "Este correo no está registrado en nuestra plataforma",
    newPasswordConfirmation: "Confirmar nueva contraseña",
    successTitle: "¡Contraseña reestablecida!",
    successDescription:
      "Ingresa con tus nuevos datos y explora todas nuestras campañas en a2censo.",
    validations: {
      password: {
        min: "Muy Corto!",
        max: "Muy largo!",
        matches:
          "Debe tener minimo 8 caracteres, 1 mayuscula, 1 minuscula, 1 número, 1 caracter especial",
        required: "Debes ingresar tu contraseña",
      },
      confirmPassword: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes confirmar tu contraseña",
        oneOf: "Las contraseñas debe coincidir",
      },
    },
  },
  enrollment: {
    challengeQuestionnaireAnswers: {
      title: "Confírmanos tu identidad",
      subtitle:
        "Ayúdanos respondiendo las siguientes preguntas para confirmar que eres tú,  y de esta manera puedas continuar",
      sendButton: "Enviar",
      modal: {
        title: "¡Lo sentimos!",
        subtitle1: "Tus respuestas ",
        subtitle2: " no coinciden.",
        subtitle3: "Haz gastado todos los intentos.",
        subtitle4: "Vuelve más tarde.",
        button: "Intentar de nuevo",
      },
    },
    financialInfo: {
      title: "Informacion financiera",
      tooltipInfo: "Informacion financiera",
      subtitle:
        "Recuerda que juntos creamos confianza, por esa razón tus datos financieros deben ser reales para poderte guiar.",
      assets: "Activos",
      monthly_income: "Ingresos mensuales",
      equity: "Patrimonio",
      monthly_expenses: "Egresos mensuales",
      profit: "Utilidad Neta",
      saveAndContinue: "Guardar y continuar",
      saving: "Guardando...",
    },
  },
  generalInformation: {
    title: "Información general",
    subTitle:
      "cuentanos más de ti! con tus datos personales y de contactos podemos asegurarte una mejor experiencia.",
    generalData: "Datos generales",
    name: "Nombre",
    lastName: "Apellido",
    documentType: "Tipo documento",
    documentNumber: "Número de identificación",
    email: "Correo electrónico",
    countryCode: "Cód. país",
    phoneNumber: "Número de celular",
    documentCity: "Lugar de expedición",
    documentDate: "Fecha de expedición",
    birthDayData: "Datos de nacimiento",
    birthDay: "Fecha de nacimiento",
    selectCountry: "Selecciona el país",
    selectCity: "Selecciona la ciudad",
    birthDayDate: "Fecha de nacimiento (aaa-mm-dd)",
    country: "País",
    countryPlaceholder: "Selecciona el país",
    city: "Ciudad",
    cityPlaceholder: "Selecciona la ciudad",
    state: "Departamento",
    statePlaceholder: "Selecciona el departamento",
    address: "Dirección",
    emailPlaceholder: "example@domain.com",
    contactData: "Datos de contacto",
    cityCode: "Indic. ciudad",
    homePhone: "Número de telefono",
    publicResourcesManager: "¿Administras recursos públicos?",
    USMoneyTransfer: "¿Haces giros de recursos a Estados Unidos?",
    publicExposed: "¿Te consideras una persona publicamente expuesta?",
    yes: "Si",
    no: "No",
  },
  summary: {
    title: "Tu resumen",
    title2: "Información financiera",
    title3: "¿Cuál es tu proposito?",
    textTitle:
      "asegúrate que todos tus datos estén correctos antes de finalizar.",
    generalData: "Datos generales",
    bornData: "Datos de nacimiento",
    contactData: "Datos de Contacto",
    name: "Nombre",
    lastname: "Apellido",
    documentType: "Tipo documento",
    documentNumber: "Número de identificación",
    address: "Escribe tu dirección",
    email: "Correo electrónico",
    country: "Selecciona el país",
    country2: "País de residencia",
    city1: "Selecciona la ciudad",
    city2: "Selecciona la ciudad",
    city3: "Ciudad",
    date1: "Fecha",
    date2: "Fecha de nacimiento",
    countryCode: "Cod país",
    cityCode: "Indic. ciudad",
    phoneNumber: "Número de celular",
    resources1: "¿Administras Recursos Públicos?",
    resourcesUnitedState: "¿Haces giros de Recursos a Estados Unidos?",
    resourcesUS1: "¿Estas apoderado permanentemente en Estados Unidos?",
    resourcesUS2: "¿Tienes direccion permanente en Estados Unidos?",
    resourcesUS3: "¿Tienes teléfono permanente en Estados Unidos?",
    resourcesUS4:
      "¿Posees participación accionaria en una empresa en Estados Unidos?",
    resourcesUS5: "¿Realizas giros de recursos en Estados Unidos?",
    publicPerson: "¿Te consideras una Persona Públicamente Expuesta?",
    yes: "Si",
    no: "No",
    publicPersonType: "¿Qué tipo de PEP es?",
    pep1: "PEP Tipo 1",
    pep2: "PEP Tipo 2",
    pep3: "PEP Tipo 3",
    familyPublicPersonType:
      "¿Tienes familiares considerados Persona Públicamente Expuesta (PEP)?",
    familyTitle: "Familiar #1",
    familyNames: "Nombres",
    familyLastnames: "Apellidos",
    familyPosition: "Cargo que desempeña actualmente",
    familyEntity: "Entidad o empresa actual",
    familyNew: "Agregar otro familiar",
    financialInfo1: "Activos",
    financialInfo2: "Ingresos mensuales",
    financialInfo3: "Patrimonio",
    financialInfo4: "Egresos mensuales",
    pourpose: "Selecciona tu próposito",
    continue: "Guardar y continuar",
    loading: "Cargando...",
    validations: {
      number: "Debe ser un valor numerico",
      name: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu nombre",
      },
      lastname: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu apellido",
      },
      typeDoc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el tipo de documento",
      },
      doc: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu documento",
      },
      email: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu email",
      },
      phoneCode: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el codigo de pais",
      },
      phone: {
        min: "Muy Corto!",
        max: "Muy largo!",
        type: "Solo se permiten numeros.",
        required: "Debes ingresar tu telefono",
      },
      country: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el país",
      },
      city1: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar la ciudad",
      },
      city2: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar la ciudad",
      },
      country2: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar el país",
      },
      city3: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar la ciudad",
      },
    },
  },
  summarySuccess: {
    title: "¡Estamos listos!",
    paragraph1:
      "Desde ya puedes hacer crecer tu dinero invertido en proyectos de",
    paragraph2: "empresas colombianas y ser parte de esta gran comunidad de",
    paragraph3: "confianza y colaboracion.",
    paragraph4: "Desde ya puedes hacer crecer tu dinero",
    paragraph5: "invertido en proyectos de empresas",
    paragraph6: "colombianas y ser parte de esta gran",
    paragraph7: "comunidad de confianza y colaboracion.",
    know: "¡Conocelas!",
  },
  confirmData: {
    titleSection: "Confírmanos tus datos",
    sectionDescription:
      ", antes de continuar verifica que todos tus datos estén correctos. ¡Ten en cuenta que después no podrás editarlos!",
    titleForm: "Datos Generales",
    name: "Nombre",
    lastname: "Apellido",
    documentType: "ID",
    documentNumber: "Número de identificación",
    confirmDocument: "Confirmar número de identificación",
    email: "Correo electrónico",
    countryCode: "Cod país",
    phoneNumber: "Número de celular",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    expeditionDate: "Fecha de expedición",
    expeditionPlace: "Lugar de expedición",
    saveAndContinue: "Guardar y continuar",
  },
  purpose: {
    titleSection: "Cuál es tu propósito?",
    titleList: "Selecciona tu principal propósito ",
    saveAndContinue: "Guardar y continuar",
  },
  login: {
    title: "Inicio de sesión",
    firstTime: "¿Primera vez en a2censo?",
    login: "Iniciar sesión",
    forgotPassword: "¿Olvidaste tu contraseña?",
    register: "Registrate",
    invalidCredentials:
      "El correo o la contraseña no son validos en nuestra plataforma",
  },
  vinculationExpired: {
    linkExpired: "¡Este link ha expirado!",
    linkExpiredDescription:
      "Este link ha superado los 30 días desde que fue enviado. Registrate de nuevo y continua con la vinculación en a2censo.",
  },
  register: {
    companyName: "Razón social",
    companyImage: "Imagen",
    companyDescription: "Descripción",
    companyLogo: "Logo",
    companyVideo: "Url de video",
    companyTitle: "Titulo",
    companyContent: "Contenido",
    companySecondContent: "Segundo contenido",
    companyPrivate: "Privado",
    companyStatus: "Estado",
    campaignSerie: "Serie de emisión",
    originationFee: "Tasa de originación",
    fngFee: "Comisión Fng",
    minimalAmount: "Monto minimo",
    companyPyme: "Pyme",
    detailType: "Tipo detalle",
    closeDate: "Fecha de cierre",
    investmentPercentage: "Porcentaje de garantia FNG",
    riskClassification: "Clasificación de riesgo",
    interestRate: "Tasa de interés",
    termMonths: "Plazo en meses",
    requestedAmount: "Monto requerido",
    paymentFrequency: "Frecuencia de pago en días",
    sector: "Id del sector",
    featured: "Destacado",
    generalData: "Datos de la campaña",
    generalDataDetail: "Datos de detalle de la campaña",
    documentType: "ID",
    documentNumber: "Número de identificación",
    confirmDocument: "Confirmar número de identificación",
    save: "Guardar",
    loading: "Cargando...",
    email: "Correo electrónico",
    password: "Contraseña",
    validations: {
      email: {
        min: "Muy Corto!",
        max: "Muy largo!",
        required: "Debes ingresar tu email",
      },
      password: {
        min: "Muy Corto!",
        max: "Muy largo!",
        matches:
          "Debe tener minimo 8 caracteres, 1 mayuscula, 1 minuscula, 1 número, 1 caracter especial",
        required: "Debes ingresar tu contraseña",
      },
    },
  },
  pyme: {
    titlePyme: "Pymes",
  },
  editCampaign: {
    titleBusinessLine: "LÍNEA DE NEGOCIO:",
  },
  trackingACC: {
    titleTrackingACC: "Seguimiento del crédito",
    campaign: "Campaña:",
    descriptionI:
      "Aquí podrás iniciar el proceso de creación del crédito (campaña) en el sistema ACC y realizar su seguimiento, una vez cerrada la campaña y luego de adjudicar.",
    descriptionII:
      "Recuerda que el envío de información de la Pyme, su Campaña e Inversionistas es manual. No puedes enviar la información de los inversionistas sin primero completar el proceso exitoso de envío de Pyme y su campaña, y además que ya se haya adjudicado.",
  },
  campaignInformation: {
    infoCampaign: "Información Pyme y campaña",
    btnSendCampaign: "Enviar información",
    btnRetry: "Reintentar envío",
    btnDownloadTA: "Descargar T.A",
    titleAccordion: "Detalle del proceso",
  },
  investorInformation: {
    infoInvestor: "Información Inversionistas y Datos Complementarios",
    btnSend: "Enviar información",
    btnRetry: "Reintentar envio",
    titleAccordion: "Detalle del proceso",
  },
  confirmTrackingACC: {
    copySend:
      "Éstas a punto de enviar la información de la Pyme y su campaña al ACC para que se inicie el proceso de creación del crédito (campaña)",
    copyRetry:
      "Éstas a punto de reenviar la información de la Pyme y su campaña al ACC para que se inicie el proceso de creación del crédito (campaña). Por favor valida que se hayan ajustado todos los puntos de error.",
  },
  pymesCoDebtors: {
    title: "Gestión de codeudores",
    backButton: "Volver",
    add: "Agregar",
    update: "Editar",
    delete: "Eliminar",
    search: "Buscar...",
    subtitle1: "Solicitud de campaña:",
    subtitle2: "Pyme:",
    subtitle3: "NIT-DV:",
    description:
      "Aquí podrás consultar, agregar, modificar y eliminar los codeudores de una solicitud de campaña creada en a2censo.",
    observation:
      "Recuerda que si se rechaza el codeudor en “Gestión de alertas”, este queda eliminado inmediatamente. Para consultar los codeudores eliminados debes usar el filtro.",
  },
  codebtorList: {
    hasAnotherCodebtor: "¿Tienes otro codeudor?",
    addAnotherCodebtor: "Agregar un nuevo codeudor",
    edit: "Editar",
    form: {
      saving: "Guardando",
      documentTypes: {
        1: "Cédula de Ciudadanía",
        2: "Cédula de Extranjería",
        3: "NIT",
      },
      select: "Seleccionar",
      delete: "Eliminar",
      add: "Agregar",
      addCodebtorTitle: "Agregar un nuevo codeudor",
      documentNumber: "Número del documento *",
      documentType: "Tipo de documento *",
      name: "Primer nombre *",
      nameLegal: "Razón Social *",
      lastName: "Primer apellido *",
      secondLastname: "Segundo apellido",
      secondName: "Otros nombres",
      expeditionDate: "Fecha de expedición *",
      expeditionPlace: "Lugar de expedición *",
      nationality: "Nacionalidad *",
      expeditionCountry: "País de expedición *",
      cityExpeditionPlace: "Ciudad de expedición *",
      deptoExpeditionPlace: "Departamento de expedición *",
      dv: "DV",
      validations: {
        max: "Máximo 100 caracteres",
        number: "Deben ser numeros",
        name: {
          type: "Solo se permiten letras y la Ñ",
          typeNit: "Solo se permiten letras, números, la Ñ y el &",
          required: "Campo obligatorio",
          required_nit: "Campo obligatorio",
        },
        lastName: {
          type: "Solo se permiten letras y la Ñ",
          required: "Campo obligatorio",
        },
        secondName: {
          type: "Solo se permiten letras y la Ñ",
        },
        secondLastname: {
          type: "Solo se permiten letras y la Ñ",
        },
        documentType: {
          required: "Campo obligatorio",
        },
        documentNumber: {
          required: "Campo obligatorio",
          number:
            "Ingresa únicamente números, sin caracteres especiales ni espacios.",
          codebtor_exists: "Este documento ya se encuentra registrado como codeudor para esta solicitud",
        },
        documentNumberCe: {
          required: "Campo obligatorio",
          ceMax:
            "Ingresa únicamente números, sin caracteres especiales ni espacios.",
        },
        dv: {
          required: "Campo obligatorio",
          number: "Debe ser un valor numérico",
          min: "Muy Corto!",
          max: "Muy largo!",
          invalid: "Código de verificación inválido",
        },
        expeditionDate: {
          required: "Campo obligatorio",
          invalid: "Fecha ingresada no es válida",
        },
        expeditionCountry: {
          required: "Campo obligatorio",
          required_nit: "Campo obligatorio",
        },
        expeditionState: {
          required_nit: "Campo obligatorio",
        },
        expeditionPlace: {
          required: "Campo obligatorio",
          required_nit: "Campo obligatorio",
        },
      },
    },
    confirmationWindow: {
      create: {
        title:
          "Vas a agregar uno o más codeudores a la solicitud de la campaña seleccionada",
        description:
          "Los nuevos codeudores serán enviados al módulo “Gestión de alertas” para la validación en listas restrictivas. Podrás consultar el resultado en el módulo “Gestión de codeudores”.",
        accept_button: "Aceptar",
        cancel_button: "Cancelar",
      },
      edit: {
        title:
          "Vas a modificar un codeudor de la solicitud de campaña seleccionada",
        description:
          "Los cambios del codeudor serán enviados al módulo “Gestión de alertas” para la validación en listas restrictivas. Podrás consultar el resultado en el módulo “Gestión de codeudores”.",
        accept_button: "Aceptar",
        cancel_button: "Cancelar",
      },
      delete: {
        title:
          "Vas a eliminar un codeudor de la solicitud de campaña seleccionada",
        description:
          "El codeudor será desvinculado de la solicitud de campaña.",
        accept_button: "Aceptar",
        cancel_button: "Cancelar",
      },
    },
  },
};
