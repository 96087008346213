import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitLoadDispersion: ["data"],
  registerLoadSuccess: ["response"],
  registerLoadFail: ["error"],
  cleanRegister: [""]
});

export const LoadDispersionTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  messasge: "",
  error: "",
  showMessage: false,
};

const cleanRegister = state =>
Object.assign({}, state, {
  isFetching: false,
  messasge: "",
  error: "",
});

const submitLoadDispersion = state =>
  Object.assign({}, state, {
    isFetching: true,
    error: "",
    message: ""
  });

const registerLoadSuccess  = (state, {response: message}) =>
  Object.assign({}, state, {
    isFetching: false,
    message: message,
    error: "",
    showMessage: true,
  });
  
const registerLoadFail= (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    error: responseError,
    message: ""
  });


export const reducer = createReducer(INITIAL_STATE, {
    [Types.SUBMIT_LOAD_DISPERSION]: submitLoadDispersion,
    [Types.REGISTER_LOAD_SUCCESS]: registerLoadSuccess,
    [Types.REGISTER_LOAD_FAIL]: registerLoadFail,
    [Types.CLEAN_REGISTER]: cleanRegister
});