import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    submitMulticashLoad: ["data"],
    submitUpdateMulticash: ["data"],
    multicashLoadSuccess: ["response"],
    multicashUpdateSuccess: ["response"],
    multicashLoadFail: ["error"],
    eraseMulticashData: [],
    eraseMulticashMessage: []
  });
  
export const MulticashLoadTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  isFetchingUpdate: false,
  showMessage: false,
  messasge: "",
  data: "",
  error: ""
};

const submitMulticashLoad = state =>
  Object.assign({}, state, {
    isFetching: true,
    error: "",
    message: ""
  });

const submitUpdateMulticash = state =>
  Object.assign({}, state, {
    isFetchingUpdate: true,
    error: "",
    message: ""
  });

const multicashLoadSuccess = (state, {response: data}) =>
  Object.assign({}, state, {
    isFetching: false,
    data: data,
    error: ""
  });

const multicashUpdateSuccess = (state, {response: data}) =>
  Object.assign({}, state, {
    isFetchingUpdate: false,
    message: data,
    showMessage: true,
    error: ""
  });

const multicashLoadFail = (state, {error: responseError}) =>
  Object.assign({}, state, {
    isFetching: false,
    isFetchingUpdate: false,
    error: responseError,
    message: ""
  });

const eraseMulticashData = state =>
  Object.assign({}, state, {
    data: "",
  });

const eraseMulticashMessage = state => 
  Object.assign({}, state, {
    error: "",
    message: ""
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_MULTICASH_LOAD]: submitMulticashLoad,
  [Types.MULTICASH_LOAD_SUCCESS]: multicashLoadSuccess,
  [Types.MULTICASH_UPDATE_SUCCESS]: multicashUpdateSuccess,
  [Types.MULTICASH_LOAD_FAIL]: multicashLoadFail,
  [Types.ERASE_MULTICASH_DATA]: eraseMulticashData,
  [Types.ERASE_MULTICASH_MESSAGE]: eraseMulticashMessage,
  [Types.SUBMIT_UPDATE_MULTICASH]: submitUpdateMulticash
});