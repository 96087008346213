import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    eraseData: "",
    submitGetInvestorById: ["investor_id"],
    getInvestorByIdSuccess: ["investorData"],
    submitUpdateInfoInvestors: [
        "file",
        "file_name",
        "investor_id",
        "deposit_account",
        "city_id",
        "city_name",
        "observation",
        "user_email",
        "user_ip"
    ],
    updateInfoInvestorsSuccess: ["message"],
    updateInfoInvestorsError: ["error"],
    submitGetAllCitiesInTheWorld: [""],
    getAllCitiesInTheWorldSuccess: ["countryList"],
});

export const AnnotationByFilesTypes = Types;
export default Creators;

export const INITIAL_STATE = {
    isFetching: false,
    investorData: "",
    message: "",
    error: "",
    countryList: ""
};
    
const eraseData = (state) => 
    Object.assign({}, state, {
        isFetching: false,
        message: "",
        error: ""
    });

const submitGetInvestorById = state =>
    Object.assign({}, state, {
        // isFetching: true
    });

const getInvestorByIdSuccess = (state, { investorData }) =>
    Object.assign({}, state, {
        isFetching: false,
        investorData
    });

const submitUpdateInfoInvestors = state =>
    Object.assign({}, state, {
        isFetching: true
    });

const updateInfoInvestorsSuccess = (state, { message }) =>
    Object.assign({}, state, {
        isFetching: false,
        message
    });

const updateInfoInvestorsError = (state, { error }) =>
    Object.assign({}, state, {
        isFetching: false,
        error
    });

const submitGetAllCitiesInTheWorld = state =>
    Object.assign({}, state, {
        // isFetching: true
    });

const getAllCitiesInTheWorldSuccess = (state, { countryList }) =>
    Object.assign({}, state, {
        isFetching: false,
        countryList
    });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ERASE_DATA]: eraseData,
    [Types.SUBMIT_GET_INVESTOR_BY_ID]: submitGetInvestorById,
    [Types.GET_INVESTOR_BY_ID_SUCCESS]: getInvestorByIdSuccess,
    [Types.SUBMIT_UPDATE_INFO_INVESTORS]: submitUpdateInfoInvestors,
    [Types.UPDATE_INFO_INVESTORS_SUCCESS]: updateInfoInvestorsSuccess,
    [Types.UPDATE_INFO_INVESTORS_ERROR]: updateInfoInvestorsError,
    [Types.SUBMIT_GET_ALL_CITIES_IN_THE_WORLD]: submitGetAllCitiesInTheWorld,
    [Types.GET_ALL_CITIES_IN_THE_WORLD_SUCCESS]: getAllCitiesInTheWorldSuccess,
});