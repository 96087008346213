import axios from "axios";
import RequestCotext from "./request-context";
//import App from "./app.js";
/* eslint no-param-reassign: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint global-require: 0 */
//const aws4 = require('aws4');

const getURI = (uri, params) => {
  params = params || {};
  const matches = uri.match(/\{params.([a-zA-Z0-9_]+)}/g);
  if (matches) {
    matches.forEach(match => {
      const name = match.replace("{params.", "").replace("}", "");
      uri = uri.replace(match, params[name]);
    });
  }
  return uri;
};

const visitEndpoints = (endpoints, callback) => {
  Object.keys(endpoints).forEach(name => {
    if (endpoints[name]) {
      const endpoint = endpoints[name];
      callback(name, endpoint.method, endpoint.uri);
    }
  });
};

const createAxios = opts => {
  const axiosInstance = axios.create(opts);
  const requestContext = new RequestCotext(axiosInstance);
  requestContext.responseInterceptor();
  requestContext.requestInterceptor();
  return {
    axiosInstance
  };
};

export default class API {
  constructor(opts) {
    const endpoints = opts.endpoints || {};
    const { axiosInstance } = createAxios(opts);
    this.axios = axiosInstance;
    //const result = opts.baseURL.replace(/(^\w+:|^)\/\//, '');

    visitEndpoints(endpoints, (name, method, uri) => {
      this[name] = (params = {}) => {
        /*let request = {
          host: result,
          method: method.toUpperCase(),
          url: opts.baseURL+uri,
          path: uri
        }
        let signedRequest = aws4.sign(request,
          {
            secretAccessKey: App.redux.store.getState().login.secretKey,
            accessKeyId: App.redux.store.getState().login.accessKeyId,
            sessionToken: App.redux.store.getState().login.sessionToken
          });

        axiosInstance.interceptors.request.use(
          config => {
            config.headers.Authorization = signedRequest.headers.Authorization;
            config.headers['X-Amz-Date'] = signedRequest.headers['X-Amz-Date'];
            config.headers['X-Amz-Security-Token'] = signedRequest.headers['X-Amz-Security-Token'];
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );*/

        if (["get", "delete", "head", "options"].includes(method)) {
          return axiosInstance[method](getURI(uri, params.url), {
            headers: params.headers
          }).then(r => r.data);
        }
        return axiosInstance[method](getURI(uri, params.url), params.body, {
          headers: params.headers
        }).then(r => r.data);
      };
    });
  }
}
