const { Serializer } = require("jsonapi-serializer");

module.exports = {
  updateRequestCampaignClassSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "request_campaign_id",
        "company_classification",
        "project_classification",
        "update",
        "user_email",
        "user_ip"
      ]
    })
};
