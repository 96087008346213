/* eslint-disable no-console */
import axios from "axios";
import getPackageMetaData from "../getPackageMetaData";

export default async () => {
  const cachePackageVersion = (
    await axios.get("/meta.json", {
      "Cache-Control": "no-cache"
    })
  ).data.version;
  const versionFromLocal = localStorage.getItem("admin-version");
  const lastVersion = versionFromLocal || cachePackageVersion;
  const [packageVersion] = getPackageMetaData();

  if (packageVersion !== lastVersion) {
    console.info("Versions differ. Clearing cache and hard reloading...");
    await localStorage.clear();
    await sessionStorage.clear();
    await localStorage.setItem("admin-version", packageVersion);
    window.location.reload(true);
    return Promise.resolve({
      isValidVersion: false
    });
  } else {
    await localStorage.setItem("admin-version", packageVersion);
  }

  return Promise.resolve({
    isValidVersion: true
  });
};
