import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import RegisterActions, { RegisterTypes } from "../reducers/register-campaign.reducer";
import RegisterFormActions from "../reducers/campaign-form.reducer";
import { RegisterSerializer } from "../../lib/serializers/register.serializer";
import LoginActions from "../reducers/login.reducer";
import CampaignActions from "../reducers/campaign.reducer";

export const startRegisterFlow = () => ({
  type: "SUBMIT_REGISTER"
});

export const registerSubmit = action$ =>
  action$.pipe(
    ofType(RegisterTypes.SUBMIT_REGISTER),
    flatMap(action => {
      return Observable.from(
        App.api.a2censo.register({
          body: RegisterSerializer().serialize(action.campaign)
        })
      ).pipe(
        flatMap((response) => {
          action.campaign["id"] = response.details.id;
          action.campaign["image"] = response.details.image
          return Observable.concat(
            Observable.of(RegisterActions.registerSuccess()),
            Observable.of(
              RegisterFormActions.updateAction({ action: 'edit', campaignId: response.details.id, details: [], item: action.campaign }),
              push('campaignForm')
            )
          )
        }),
        catchError(e => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(RegisterActions.registerFail(e)),
              Observable.of(
                push(`/oops`)
              )
            );
          }
        })
      );
    })
  );

export const updateSubmit = action$ =>
  action$.pipe(
    ofType(RegisterTypes.UPDATE_REGISTER),
    flatMap(action => {
      const idCampaign = parseInt(action.campaign.id);
      const data = {
        body: RegisterSerializer().serialize(action.campaign),
        url: { id: idCampaign }
      }
      return Observable.from(
        App.api.a2censo.update({
          ...data
        })
      ).pipe(
        flatMap((response) => {
          return Observable.concat(
            Observable.of(RegisterActions.registerSuccess()),
            Observable.of(RegisterFormActions.updateCampaign(action.campaign)),
            Observable.of(CampaignActions.postLoad({
              status: "OK",
              update: true
            })),
            Observable.of(push(`/CampaignEditForm/${idCampaign}`))
          )
        }),
        catchError(e => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.concat(
              Observable.of(CampaignActions.postLoad({
                status: "ERROR",
                update: true
              })),
              Observable.of(RegisterActions.registerFail(e)),
              Observable.of(push(`/CampaignEditForm/${idCampaign}`))
            );
          }
        })
      );
    })
  );

export default combineEpics(registerSubmit, updateSubmit);
