import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import AwardCampaignActions, { AwardCampaignTypes } from "../reducers/award-campaign.reducer";
import CampaignActions from "../../redux/reducers/campaign.reducer";
import LoginActions from "../reducers/login.reducer";
import { AwardCampaignSerializer } from "../../lib/serializers/campaign.serializer";


export const validateCampaignAction = action$ =>
  action$.pipe(
    ofType(AwardCampaignTypes.START_VALIDATE_CAMPAIGN),
    flatMap(action => {
      const url = {id: action.data}
      return Observable.from(
        App.api.a2censo.validateCampaign({
          url: url
        })
      ).pipe(
        flatMap((response) => {
          response["campaignId"] = action.data
          return Observable.of(
            AwardCampaignActions.validateCampaignSuccess(response)
          )
        }),
        catchError(response => {
          if(response.data){
            return Observable.concat(
                Observable.of(
                  AwardCampaignActions.validateCampaignFailed(response.data.attributes.message)
                )
            );
          }else if(response.statusCode === 401){   
            return Observable.concat(
              Observable.of(
                AwardCampaignActions.eraseData(),
                LoginActions.submitLogout()
              )
            )
          }else {
              return Observable.concat(
                  Observable.of(
                    AwardCampaignActions.eraseData(),
                    push(`oops`)
                  )
              );
          }
        })
      );
    })
  );

  export const processCampaignAction = action$ =>
    action$.pipe(
      ofType(AwardCampaignTypes.START_PROCESS_CAMPAIGN),
      flatMap(action => {
        const data = {
          body: AwardCampaignSerializer().serialize(action.data),
          url: {id: action.data.campaignId}
        } 
        return Observable.from(
          App.api.a2censo.processCampaign({
            ...data
          })
        ).pipe(
          flatMap((response) => {
            return Observable.of(
              AwardCampaignActions.processCampaignSuccess(response.message),
              AwardCampaignActions.eraseTransactionsData(),
              CampaignActions.getCampaigns()
              // push(`campaigns`)
            )
          }),
          catchError(response => {
            if(response.data){
              return Observable.concat(
                  Observable.of(
                    AwardCampaignActions.processCampaignFailed(response.data.attributes.message)
                  )
              );
            }else if(response.statusCode === 401){   
              return Observable.concat(
                Observable.of(
                  AwardCampaignActions.eraseData(),
                  LoginActions.submitLogout()
                )
              )
            }else {
                return Observable.concat(
                    Observable.of(
                      AwardCampaignActions.eraseData(),
                      push(`oops`)
                    )
                );
            }
          })
        );
      })
    );

export default combineEpics(validateCampaignAction, processCampaignAction);
