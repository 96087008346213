import { createBrowserHistory } from "history";
import { createStore, compose, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../../redux/reducers";
import epics from "../../redux/epics";
import App from "../app.js";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "register",
    "modal",
    "request"
  ]
};
export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const resetTimeoutMiddleware = () => next => action => {
  if (App.sessionTimeoutManager) {
    App.sessionTimeoutManager.setTimer();
  }
  next(action);
};

export default async function loadRedux() {

  const epicMiddleware = createEpicMiddleware();
  const initialState = {};

  const store = createStore(
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history), 
        epicMiddleware,
        resetTimeoutMiddleware
      )
    )
  );

  epicMiddleware.run(epics);
  const persistor = persistStore(store);

  return Promise.resolve({ store, persistor, history });
}
