import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions from "../reducers/login.reducer";
import DocManagementActions, {docManagementTypes} from "../../redux/reducers/docManagement.reducer";
import { setDocumentActiveSerializer } from "../../lib/serializers/setDocumentActive.serializer";
import { createDocumentSerializer } from "../../lib/serializers/createDocument.serializer";
import { fileChargeSerializer } from "../../lib/serializers/fileCharge.serializer";
import { updateDocumentSerializer } from "../../lib/serializers/updateDocument.serializer";

export const StartDocManagementShowcase = () => ({
  type: "START_DOC_MANAGEMENT_SHOWCASE"
});

export const docManagementShowcase = action$ => action$.pipe(
  ofType("START_DOC_MANAGEMENT_SHOWCASE"),
  flatMap(action => {
    const url = {
      stage: action.stage ? action.stage : ""
    };
    return Observable.from(
      App.api.a2censo.getAllDocuments({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          push(`/doc-management`, { response })
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitGetDocManagementList = action$ => action$.pipe(
  ofType("SUBMIT_GET_DOC_MANAGEMENT_LIST"),
  flatMap(action => {
    const url = {
      stage: action.stage ? action.stage : "",
    };
    return Observable.from(
      App.api.a2censo.getAllDocuments({ url })
    ).pipe(
      flatMap((response) => {
        return Observable.of(
          DocManagementActions.getDocManagementListSuccess(response)
        )
      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout())
        } else {
          return Observable.concat(
            Observable.of(
              push(`oops`)
            )
          );
        }
      })
    );
  })
);

export const submitSetDocumentActive = action$ =>
  action$.pipe(
    ofType("SUBMIT_SET_DOCUMENT_ACTIVE"),
    flatMap((action) => {
      const document_id = action.document_id;
      const active = action.active;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: setDocumentActiveSerializer().serialize({
          document_id,
          active,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.setDocumentActive({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            DocManagementActions.setDocumentActiveSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const submitGetStages = action$ =>
  action$.pipe(
    ofType("SUBMIT_GET_STAGES"),
    flatMap((action) => {
      const url = {
        flag: action.flag ? action.flag : 0,
      };
      return Observable.from(App.api.a2censo.getStages({ url })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            DocManagementActions.getStagesSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export const submitCreateDocument = action$ =>
  action$.pipe(
    ofType("SUBMIT_CREATE_DOCUMENT"),
    flatMap((action) => {
      const name = action.name;
      const description = action.description;
      const stage_id = action.stage_id;
      const document_origin_id = action.document_origin_id;
      const type_document_id = action.type_document_id;
      const classification_id = action.classification_id;
      const file = action.file;
      const file_name = action.file_name;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: createDocumentSerializer().serialize({
          name,
          description,
          stage_id,
          document_origin_id,
          type_document_id,
          classification_id,
          file,
          file_name,
          user_email,
          user_ip
        })
      }
      return Observable.from(App.api.a2censo.createDocument({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            DocManagementActions.createDocumentSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

  export const updateDocument = action$ =>
  action$.pipe(
    ofType("SUBMIT_UPDATE_DOCUMENT"),
    flatMap((action) => {
      const document_id = action.document_id;
      const name = action.name;
      const description = action.description;
      const old_path = action.old_path;
      const file = action.file;
      const file_name = action.file_name;
      const user_email = action.user_email;
      const user_ip = action.user_ip;
      const data = {
        body: updateDocumentSerializer().serialize({
          document_id,
          name,
          description,
          old_path,
          file,
          file_name,
          user_email,
          user_ip,
        })
      }
      return Observable.from(App.api.a2censo.updateDocument({ ...data })
      ).pipe(
        flatMap((response) => {
          return Observable.of(
            DocManagementActions.updateDocumentSuccess(response)
          )
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout())
          } else {
            return Observable.of(
              push(`/oops`)
            )
          }
        })
      );
    })
  );

export default combineEpics(docManagementShowcase, submitGetDocManagementList, submitSetDocumentActive, 
  submitGetStages, submitCreateDocument, updateDocument
  );