import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitGetAnnotationByFile: ["dateGenSelected", "dateUploadSelected", "nameWrote"],
  getAnnotationByFileSuccess: ["annotationByFileList"],
  submitGetAnnotationByFileDetail: ["data"],
  getAnnotationByFileDetailSuccess: ["data"],
});

export const AnnotationByFilesTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data:"",
  error:"",
  annotationByFileList: "",
  hist: "",
};

const submitGetAnnotationByFile = state =>
  Object.assign({}, state, {
    isFetching: true
  });

const getAnnotationByFileSuccess = (state, { annotationByFileList }) =>
  Object.assign({}, state, {
    isFetching: false,
    annotationByFileList: annotationByFileList[0]
  });

const submitGetAnnotationByFileDetail = state =>
    Object.assign({}, state, {
      isFetching: true
    });

const getAnnotationByFileDetailSuccess = (state, { data }) =>   
  Object.assign({}, state, {
    isFetching: false,
    data
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_GET_ANNOTATION_BY_FILE]: submitGetAnnotationByFile,
  [Types.GET_ANNOTATION_BY_FILE_SUCCESS]: getAnnotationByFileSuccess,
  [Types.SUBMIT_GET_ANNOTATION_BY_FILE_DETAIL]: submitGetAnnotationByFileDetail,
  [Types.GET_ANNOTATION_BY_FILE_DETAIL_SUCCESS]: getAnnotationByFileDetailSuccess,
});
